export const toFixedNumber = (number, n = 3) => {
  if (!number) {
    return 0;
  }
  const data = parseFloat(number);
  return data.toFixed(n);
};

const COUNT_ABBRS = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"];

export const abbreviateNumber = (count, withAbbr = false, decimals = 2) => {
  const nCount = parseFloat(count);
  if (!nCount) {
    return count;
  }
  const i =
    0 === nCount ? nCount : Math.floor(Math.log(nCount) / Math.log(1000));
  let result = parseFloat((nCount / Math.pow(1000, i)).toFixed(decimals));
  if (withAbbr) {
    result += `${COUNT_ABBRS[i]}`;
  }
  return result;
};
