import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import bookingReducer from "./slices/bookings";
import unitTypeReducer from "./slices/unitTypes";

export const store = configureStore({
  reducer: {
    booking: bookingReducer,
    unitType: unitTypeReducer,
    auth: authReducer,
  },
});
