import { Button, Form, Input } from "antd";
import queryString from "query-string";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.png";
import { userInstance } from "../../axios/axiosConfig";
import Meta from "../meta";
import Toaster from "../toaster/toaster";
import "./reset.css";

const Reset = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [notifyType, setNotifyType] = useState();
  const [notifyMsg, setNotifyMsg] = useState();
  const history = useHistory();

  const onFinish = async (values) => {
    const parsed = queryString.parse(window.location.search);
    values.hex = parsed.hh;
    const response = await userInstance.post("/forget-password", values);
    const statusCode = response.data.code;
    const { msg } = response.data;
    if (statusCode === 200) {
      setNotifyType("success");
      setNotifyMsg(msg);
      toast.success("Change password successfully!", {
        containerId: "B",
        toastId: "B",
      });
      history.push("/");
    } else {
      setNotifyType("error");
      setNotifyMsg(msg);
    }
    form.resetFields();
  };

  const close = () => {
    setNotifyType("");
  };

  return (
    <>
      <Meta />

      <div className="forget">
        <div className="forget-section">
          <Toaster
            notifyType={notifyType}
            notifyMsg={notifyMsg}
            close={close}
          />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="forget-logo">
                  <img src={logo} alt="Logo" />
                </div>
                <div className="forget-form">
                  <h1>{t("reset.heading1")}</h1>
                  {/* <p>{t('reset.label2')}</p> */}
                  <div className="forget-box">
                    <div>
                      <Form
                        form={form}
                        name="basic"
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={onFinish}
                      >
                        <Form.Item
                          label={t("reset.label7")}
                          name="newPassword"
                          rules={[
                            {
                              required: true,
                              message: t("reset.label8"),
                            },
                          ]}
                        >
                          <Input.Password placeholder={t("reset.label7")} />
                        </Form.Item>

                        <Form.Item
                          label={t("reset.label9")}
                          name="confirmPassword"
                          dependencies={["newPassword"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: t("reset.label10"),
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  !value ||
                                  getFieldValue("newPassword") === value
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  new Error(
                                    "The two passwords that you entered do not match!"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password placeholder={t("reset.label9")} />
                        </Form.Item>

                        <Form.Item>
                          <Button className="forget-btn" htmlType="submit">
                            {t("reset.label11")}
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="q-links">
                  <p>
                    Go to Login! <Link to="/">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
