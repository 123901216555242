import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { companyInstance } from "../../axios/axiosConfig";
import "./guests.css";

const AddCompany = (props) => {
  const { t } = useTranslation();
  const { visible, handleOk, handleCancel, getData } = props;

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    values.organizationId = props.organizationId;
    const response = await companyInstance.post("/add", values);
    if (response.data.code === 200) {
      handleCancel();
      getData();
      toast.success("Company created successfully!", { containerId: "B" });
    } else {
      toast.error("Some error occurred!", { containerId: "B" });
    }
    form.resetFields();
  };

  return (
    <Modal
      title={t("company.add_btn", "Add Company")}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName="right-modal-container"
      className="add-companymodal"
    >
      <Helmet>
        <body className={visible ? "ant-scrolling-effect" : ""} />
      </Helmet>
      <div className="cross-btn">
        <CloseOutlined onClick={handleCancel} />
      </div>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        style={{ padding: "0px 20px" }}
      >
        <Row style={{ alignItems: "center", paddingTop: 10 }}>
          <Col span={24}>
            <Form.Item
              label={t("company.name_lb", "Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t(
                    "company.name_message_error",
                    "Please input company name!"
                  ),
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t("company.vat_lb", "VAT ID")}
              name="vat"
              rules={[
                {
                  required: true,
                  message: t(
                    "company.vat_error_message",
                    "Please input vat Id!"
                  ),
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: t(
                    "company.email_error",
                    "The input is not valid E-mail!"
                  ),
                },
                {
                  required: true,
                  message: t(
                    "company.email_error1",
                    "Please input your E-mail!"
                  ),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t("company.address", "Address")}
              name="address"
              rules={[
                {
                  required: true,
                  message: t("company.address_lb", "Please input address!"),
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center", textAlign: "right" }}>
          <Col span={24}>
            <Form.Item>
              <div className="clear-btn">
                <Button
                  className="border-btn"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  {t("strings.cancel", "Clear")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("strings.save", "Apply")}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

AddCompany.propTypes = {
  visible: PropTypes.string,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  getData: PropTypes.func,
  organizationId: PropTypes.string,
};
AddCompany.defaultProps = {
  visible: false,
  handleOk: () => {},
  handleCancel: () => {},
  getData: () => {},
  organizationId: "",
};

export default AddCompany;
