import { FormOutlined, PlusOutlined } from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMemberPermissions } from "../../hooks";
import { useMutateBooking } from "../../hooks/useMutateBooking";
import { useQueryBooking } from "../../hooks/useQueryBooking";
import { setViewingBookingId } from "../../slices/bookings";
import Loading from "../Loading";

export const BookingDetail = ({ organizationId }) => {
  const selectedBookingId = useSelector(
    (state) => state.booking.selectedBookingId
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { useBooking } = useQueryBooking();
  const { data, isFetching } = useBooking(selectedBookingId, 5 * 60 * 1000);

  const { getPermissions } = useMemberPermissions(organizationId);
  const { updateStatusBookingMutation } = useMutateBooking();
  const {
    bookingRead: canRead,
    bookingWrite: canWrite,
    bookingDelete: canDelete,
  } = getPermissions();

  const onChangeBookingStatus = async (value) => {
    const payload = {
      status: value,
      bookingId: data.booking.id,
    };
    const response = await updateStatusBookingMutation.mutateAsync(payload);
    if (response.code === 200) {
      toast.success("successfully updated status", {
        containerId: "B",
        toastId: "B",
      });
    } else {
      toast.error("some error occurred!", { containerId: "B", toastId: "B" });
    }
  };

  const enableEditBookingButton = (
    <FormOutlined
      onClick={() => {
        dispatch(setViewingBookingId(data.booking.id));
      }}
    />
  );

  const disableEditBookingButton = (
    <Tooltip title={t("booking.tooltip1")} color="gold">
      <FormOutlined disabled={true} />
    </Tooltip>
  );

  const editBookingButton =
    canWrite === 1 ? enableEditBookingButton : disableEditBookingButton;

  if (isFetching) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  const booking = data.booking;

  return (
    <div className="booking-details" hidden={!Boolean(selectedBookingId)}>
      <h3>{booking.guest}</h3>
      <ul>
        <li>
          {booking.night} {t("booking.label1", "Nights - 1 Room")}
        </li>
        <li>
          {booking.noOfGuest || 0} {t("booking.label2", "Guests")}
        </li>
        <li>
          {t("booking.label3")}
          {booking.id}
        </li>
      </ul>

      <div className="booking-box">
        <div className="booking-head">
          <div className="box-heading">
            <h3>{t("booking.heading3")}</h3>
          </div>

          <div className="box-editing" role="presentation">
            {editBookingButton}

            <Select
              value={booking.status}
              className="filter-menu"
              popupClassName="color-dropdown"
              onSelect={onChangeBookingStatus}
            >
              <Option value="booked">Booked</Option>
              <Option value="open">Open</Option>
              <Option value="inquiry">Set as inquiry</Option>
              <Option value="decline">Decline</Option>
            </Select>
          </div>
        </div>

        <div className="booking-item">
          <div className="prorety-box">
            <span>{t("strings.property")}</span>
            <p>{booking.propertyName}</p>
          </div>

          <div className="prorety-box">
            <span>{t("strings.unit")}</span>
            <p>{booking.unitName}</p>
          </div>
        </div>

        <div className="booking-item-one">
          <div className="prorety-box">
            <span>{t("booking.heading6")}</span>
            <p>
              {booking.channel} {" - "}
              {`${booking.commission}%`}
            </p>
          </div>
          <div className="prorety-box">
            <span>{t("booking.total_booking", "Total")}</span>
            <p className="text-bold">
              {booking.currency}
              {booking.totalAmount}
            </p>
          </div>
        </div>

        <div className="booking-item">
          <div className="prorety-box">
            <span>{t("strings.guests")}</span>
            <p>
              {booking.adult} {t("strings.adults")}{" "}
            </p>
            <p>
              {booking.children1} {t("booking.label4")}{" "}
            </p>
          </div>

          <div className="prorety-box">
            <span> {t("strings.date")}</span>
            <p>
              {moment(new Date(booking.startDate)).format("DD MMM YY")}-
              {moment(new Date(booking.endDate)).format("DD MMM YY")}
            </p>
            <p>
              {booking.night} {t("booking.label5")}{" "}
            </p>
          </div>
        </div>
      </div>

      {(booking.guests || []).map((el) => (
        <div className="booking-box" key={el.id}>
          <div className="booking-head">
            <div className="box-heading">
              <h3> {t("strings.guests")}</h3>
            </div>
          </div>

          <div className="booking-item">
            <div className="prorety-box width-70">
              <span>{t("strings.full")}</span>
              <p>{el.fullName}</p>
            </div>

            <div className="prorety-box width-30">
              <span> {t("strings.country")}</span>
              <p>{el.country || "NA"} </p>
            </div>
          </div>

          <div className="booking-item">
            <div className="prorety-box width-70">
              <span>{t("strings.email")}</span>
              <p>
                <a href={el.email ? `mailto:${el.email}` : "#"}>
                  {el.email || "NA"}
                </a>
              </p>
            </div>

            <div className="prorety-box width-30">
              <span>{t("strings.phone")}</span>
              <p>{el.phone || "NA"}</p>
            </div>
          </div>

          <div className="booking-item-one">
            <div className="prorety-box">
              <span>{t("strings.note")}</span>
              <p>{el.notes}</p>
            </div>
          </div>
        </div>
      ))}

      <div className="additionl-link" onClick={() => {}} role="presentation">
        <PlusOutlined />
        {t("booking.label6")}
      </div>
    </div>
  );
};

export default BookingDetail;
