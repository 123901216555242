import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Tooltip } from "antd";
import React, { useState } from "react";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getMembers, removeMember } from "../../api/organizations";
import actionIcon from "../../assets/images/action-icon.png";
import deleteImg from "../../assets/images/delete.svg";
import editImg from "../../assets/images/edit.svg";
import teamIcon from "../../assets/images/menu/team-icon.png";
import subUserIcon from "../../assets/images/subuser.jpg";
import { userInstance } from "../../axios/axiosConfig";
import { useMemberPermissions, useOrganizationSubscription } from "../../hooks";
import { useOrganizationPermissions } from "../../hooks/useOrganizationPermissions";
import { StoreKeys } from "../../storeKeys";
import Loading from "../Loading";
import Meta from "../meta";
import UserLock from "../userlock/userlock";
import UserNotAuthorize from "../userlock/UserNotAuthorize";
import Wrapper from "../wrapper";
import DeletePopup from "./deletepopup";
import EditSubUserPopup from "./editsubuserpopup";
import SubUserPopup from "./subuserpopup";
import "./team.css";

const TeamListing = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();

  const [visible, setVisible] = useState(false);
  const [visibleSubUser, setVisibleSubUser] = useState(false);
  const [currentSubUser, setCurrentSubUser] = useState(false);

  const { hasAccess } = useOrganizationSubscription(organizationId);
  const { getOrganizationPermissions } =
    useOrganizationPermissions(organizationId);
  const { getPermissions } = useMemberPermissions(organizationId);
  const organizationPermission = getOrganizationPermissions();
  const userPermissions = getPermissions();

  const [visibleDeletePopup, setVisibleDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const {
    teamWrite: canWrite,
    teamRead: canRead,
    teamDelete: canDelete,
  } = userPermissions;

  const {
    data: membersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [StoreKeys.GET_ORGANIZATION_MEMBERS, organizationId],
    queryFn: () => getMembers(organizationId),
  });

  const deleteMemberMutation = useMutation(
    (memberId) => removeMember(organizationId, memberId),
    {
      onSuccess: (data) => {
        if (data.code === 200) {
          refetch();
          toast.success("Successfully Deleted", { containerId: "B" });
        } else {
          toast.error(data.msg, { containerId: "B" });
        }
        setVisibleDeletePopup(false);
      },
      onError: (err) => {
        console.log(err);
        toast.error(t("strings.server_error", "Something went wrong"), {
          containerId: "B",
        });
      },
    }
  );

  const show = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    setVisibleDeletePopup(true);
  };

  const handleDeleteCancel = () => {
    setVisibleDeletePopup(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setVisibleSubUser(false);
  };

  const closeSubUser = () => {
    setVisible(false);
  };

  const showEditSubUser = (value) => {
    setCurrentSubUser(value);
    setVisibleSubUser(true);
  };

  const closeEditSubUser = () => {
    setVisibleSubUser(false);
  };

  const handleDeleteSubUser = async () => {
    deleteMemberMutation.mutate(deleteId);
  };

  // Resend Mail
  const resendMail = async (email, id) => {
    const res = await userInstance.post("/resend-email", { email, id });

    if (res.data.code === 200) {
      toast.success(
        t("team.sent_invite_message", "Invitation Link Sent Successfully"),
        { containerId: "B" }
      );
    } else {
      toast.error(t("strings.server_error", "Server error please try again"), {
        containerId: "B",
      });
    }
  };

  const showDeletePopup = (value) => {
    setVisibleDeletePopup(true);
    setDeleteId(value.id);
  };

  const enableEditButton = (el, i) => (
    <img
      className="editimg"
      src={editImg}
      alt=""
      onClick={() => showEditSubUser(el, i)}
    />
  );

  const disableEditButton = () => (
    <Tooltip
      title={t("team.not_authored", "You dont have permission")}
      color="gold"
    >
      <img
        className="editimg"
        src={editImg}
        alt="Edit Member"
        style={{ cursor: "not-allowed" }}
      />
    </Tooltip>
  );

  const enableDeleteButton = (el, i) => (
    <img
      src={deleteImg}
      alt="Remove a member"
      onClick={() => showDeletePopup(el, i)}
    />
  );

  const disableDeleteButton = () => (
    <Tooltip
      title={t("team.not_authored", "You dont have permission")}
      color="gold"
    >
      <img
        src={deleteImg}
        alt="Remove a member"
        style={{ cursor: "not-allowed" }}
      />
    </Tooltip>
  );

  const enableCreateButton = (
    <Button
      type="primary"
      className="add-member"
      icon={<PlusOutlined />}
      onClick={show}
    >
      {t("team.add_lb", "Add member")}
    </Button>
  );

  const disabledCreateButton = (
    <Tooltip title={t("team.tooltip")} color="gold">
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={show}
        disabled="true"
      >
        {t("team.add_lb", "Add member")}
      </Button>
    </Tooltip>
  );

  const isAccess = hasAccess();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAccess) {
    return (
      <Wrapper organizationId={organizationId}>
        <UserLock />
      </Wrapper>
    );
  }

  if (canRead !== 1) {
    return <UserNotAuthorize />;
  }

  const pageContent = (
    <>
      {membersData && membersData.members.length > 0 ? (
        <Wrapper organizationId={organizationId}>
          <Meta>
            <html className="team-page-view" />
            <body className="team-page-view" />
          </Meta>

          <div className="team-page">
            <div className="page-header teampage">
              <h1>
                <img src={teamIcon} alt="team" /> {t("team.label2")}
              </h1>

              {/* <Button type="primary" icon={<PlusOutlined />} onClick={show}>
                {t("team.label1")}
              </Button> */}
              {canWrite === 1 ? enableCreateButton : disabledCreateButton}
            </div>

            <div className="team-list">
              <div className="custom-table">
                <table>
                  <thead>
                    <tr>
                      <th> {t("team.name_lb", "Name")}</th>
                      <th> {t("strings.email", "Email")}</th>
                      <th> {t("team.role_lb", "Role")}</th>
                      <th> {t("team.status")}</th>

                      <th className="action">
                        {t("team.actions_lb", "Actions")}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {(membersData.members || []).map((el, i) => (
                      <tr key={el.id}>
                        <td role="presentation">
                          <div className="team-info">
                            <div className="team-pic">
                              <Avatar
                                color="#FF884B"
                                name={el.email}
                                round="50px"
                                size="50px"
                              />
                            </div>
                            <div className="team-title">
                              <h5>{el.fullName ? el.fullName : "-"}</h5>
                            </div>
                          </div>
                        </td>

                        <td className="email">{el.email}</td>
                        <td className="readdata">
                          {el.role === "fullaccess"
                            ? t("full_access_lb", "Full Access")
                            : el.role === "read"
                            ? "Read"
                            : el.role === "write"
                            ? "Write"
                            : "Custom"}
                        </td>
                        <td className="status">
                          {el.status === 0 ? (
                            <Button
                              type="primary"
                              onClick={() => resendMail(el.email, el.id)}
                            >
                              Resend
                            </Button>
                          ) : (
                            "Accepted"
                          )}
                        </td>

                        <td>
                          <div className="action-icon1 ">
                            <img
                              className="action-icon"
                              src={actionIcon}
                              alt=""
                            />
                          </div>
                          <div className="team-action">
                            {canWrite === 1
                              ? enableEditButton(el, i)
                              : disableEditButton()}
                            {canDelete === 1
                              ? enableDeleteButton(el, i)
                              : disableDeleteButton()}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <SubUserPopup
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            close={closeSubUser}
            getData={refetch}
            permission={organizationPermission}
            organizationId={organizationId}
          />

          <DeletePopup
            visible={visibleDeletePopup}
            dataObject={handleDeleteSubUser}
            cancel={handleDeleteCancel}
          />

          <EditSubUserPopup
            visible={visibleSubUser}
            onOk={handleOk}
            onCancel={handleCancel}
            close={closeEditSubUser}
            subUserData={currentSubUser}
            getData={refetch}
            permission={organizationPermission}
            organizationId={organizationId}
          />
        </Wrapper>
      ) : (
        <Wrapper organizationId={organizationId}>
          <Meta>
            <html className="team-page-view" />
            <body className="team-page-view" />
          </Meta>

          <div className="add-team-page">
            <div className="add-subuser">
              <img src={subUserIcon} alt="subuser" />
              <h4>{t("team.label3")}</h4>
              <p>{t("team.label6")}</p>
              {canWrite === 1 ? enableCreateButton : disabledCreateButton}
            </div>
          </div>
          <SubUserPopup
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            close={closeSubUser}
            getData={refetch}
            permission={organizationPermission}
            organizationId={organizationId}
          />
        </Wrapper>
      )}
    </>
  );
  return (
    <>
      <Meta>
        <html className="team-page-view" />
        <body className="team-page-view" />
      </Meta>
      {pageContent}
    </>
  );
};

export default TeamListing;
