import { Button, Col, Modal, Row, Table, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { propertyInstance } from "../../axios/axiosConfig";
import Meta from "../meta";
import Wrapper from "../wrapper";
import AddSeason from "./addseason";
import DeletePopup from "./deletepopup";
import "./rates.css";

const { Text } = Typography;

const SeasonRates = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { organizationId, unitTypeId } = useParams();

  const columns = [
    {
      title: t("seasonrates.season_label", "Season"),
      dataIndex: "seasonRateName",
      key: "seasonRateName",
    },

    {
      title: t("seasonrates.prices_label", "Prices"),
      dataIndex: "pricePerNight",
      key: "pricePerNight",
      render: (text, record) => {
        return record.currency ? `${record.currency}${text}` : "__";
      },
    },
    {
      title: t("seasonrates.min_stay", "Min. stay"),
      dataIndex: "minimumStay",
      key: "minimumStay",
    },
    {
      title: "",
      key: "action",
      // render: () => <DeleteOutlined />,
      render: (text, record) => (
        <div className="service-margin">
          <div onClick={() => edit(record.id)} role="presentation">
            {t("strings.edit", "Edit")}
          </div>
          <div onClick={() => delRow(record.id)} role="presentation">
            <Text type="danger">{t("strings.delete", "Delete")}</Text>
          </div>
        </div>
      ),
    },
  ];

  const delRow = (id) => {
    setVisibilityOFDelete(true);
    setSeasonRateId(id);
  };

  const edit = (id) => {
    history.push(
      `/organizations/${organizationId}/properties/${unitTypeId}/create-season-rates?seasonRateId=${id}`
    );
  };

  const [visible, setVisible] = useState(false);
  const [visibilityOFDelete, setVisibilityOFDelete] = useState(false);
  const [seasonRateId, setSeasonRateId] = useState(0);
  const [seasonRatesData, setSeasonRatesData] = useState([]);
  const [showTable, setShowTable] = useState(true);

  const show = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisibilityOFDelete(false);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisibilityOFDelete(false);
    setVisible(false);
  };

  const getData = useCallback(async () => {
    const values = {
      unitTypeId,
    };
    const response = await propertyInstance.post("/getSeasonRates", values);
    if (response.data.code === 200) {
      if (response.data.seasonRatesData.length > 0) {
        setShowTable(false);
        setSeasonRatesData(response.data.seasonRatesData);
      } else {
        setShowTable(true);
      }
    }
  }, []);

  const remove = async () => {
    const values = {
      id: seasonRateId,
    };
    const response = await propertyInstance.post("/deleteSeasonRate", values);
    if (response.data.code === 200) {
      getData();
      setVisibilityOFDelete(false);
      toast.success(
        t("seasonrates.delete_success", "Successfully deleted Season"),
        {
          containerId: "B",
          toastId: "B",
        }
      );
    } else {
      toast.error(t("strings.server_error", "Server error please try again"), {
        containerId: "B",
        toastId: "B",
      });
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Wrapper organizationId={organizationId}>
      <Meta>
        <body className="season-page-view" />
      </Meta>

      <div className="season-rates">
        <Row>
          <Col span={24}>
            <div className="season-rates-content">
              <div className="season-first-section">
                <div className="season-header">
                  <h3>{t("seasonrates.heading1")}</h3>
                  <div className="add-season">
                    <Button onClick={show}>{t("seasonrates.button1")}</Button>
                  </div>
                </div>
                <p>
                  {t(
                    "seasonrates.season_desc",
                    "Set different rates for specific date periods (up to 3 years in advance). Your season rates will overwrite your DefaultRate for those periods."
                  )}
                </p>

                <div className="season-table" hidden={showTable}>
                  <Table columns={columns} dataSource={seasonRatesData} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        title={t("seasonrates.title_modal", "Add Season")}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        wrapClassName="add-season-popup"
      >
        <AddSeason
          unitTypeId={unitTypeId}
          organizationId={organizationId}
          close={handleCancel}
          getData={getData}
        />
      </Modal>
      <Modal
        open={visibilityOFDelete}
        onOk={handleOk}
        onCancel={handleCancel}
        wrapClassName="delete-modal"
      >
        <DeletePopup
          dataObject={() => remove()}
          cancel={() => handleCancel()}
        />
      </Modal>
    </Wrapper>
  );
};

export default SeasonRates;
