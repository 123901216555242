export const channelsList = [
  {
    name: "Airbnb",
    value: "airbnb.com",
  },
  {
    name: "Booking",
    value: "booking.com",
  },
  {
    name: "Phone",
    value: "phone",
  },
  {
    name: "Other",
    value: "other",
  },
];
