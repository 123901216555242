import { organizationInstance } from "../axios/axiosConfig";

export const getTrialDays = async (organizationId) => {
  const res = await organizationInstance.post("/trial-days", {
    organizationId,
  });

  return res.data;
};

export const getOrganizations = async (limit, offset) => {
  const res = await organizationInstance.get("/", {
    params: { limit, offset },
  });
  return res.data;
};

export const getOrganization = async (organizationId) => {
  const res = await organizationInstance.get(`/${organizationId}`);
  return res.data;
};

export const deleteOrganization = async (organizationId) => {
  const res = await organizationInstance.delete(`/${organizationId}/delete`);
  return res.data;
};

export const updateOrganization = async (organizationId, data) => {
  const res = await organizationInstance.put(`/${organizationId}`, data);
  return res.data;
};

export const getUserPermissions = async (organizationId) => {
  const res = await organizationInstance.post("/permissions", {
    organizationId,
  });
  return res.data;
};

export const getSubscriptionStatus = async (organizationId) => {
  const res = await organizationInstance.post("/status", { organizationId });
  return res.data;
};

export const getMembers = async (organizationId) => {
  const res = await organizationInstance.get(`/${organizationId}/members`);
  return res.data;
};

export const getInvoices = async (organizationId, limit, offset) => {
  const res = await organizationInstance.get(`/${organizationId}/invoices`, {
    params: {
      organizationId,
      limit,
      offset,
    },
  });
  return res.data;
};

export const removeMember = async (organizationId, memberId) => {
  const res = await organizationInstance.post(
    `/${organizationId}/remove-member`,
    { deleteId: memberId }
  );
  return res.data;
};

export const addMember = async (organizationId, data) => {
  const res = await organizationInstance.post(
    `/${organizationId}/add-member`,
    data
  );
  return res.data;
};

export const updateMember = async (organizationId, data) => {
  const res = await organizationInstance.post(
    `/${organizationId}/update-member`,
    data
  );
  return res.data;
};

export const getOrganizationPermissions = async (organizationId) => {
  const res = await organizationInstance.post("/get-feature", {
    organizationId,
  });
  return res.data;
};
