import getSymbolFromCurrency from "currency-symbol-map";

const currencyCodes = [
  "USD",
  "VND",
  "EUR",
  "AMD",
  "AZN",
  "BYN",
  "BAM",
  "BGN",
  "HRK",
  "CZK",
  "DKK",
  "GEL",
  "HUF",
  "ISK",
  "CHF",
  "MDL",
  "MKD",
  "NOK",
  "PLN",
  "RON",
  "RUB",
  "RSD",
  "SEK",
  "TRY",
  "UAH",
  "GBP",
];

const currenciesList = currencyCodes.map((currency, i) => {
  const symbol = getSymbolFromCurrency(currency);
  const currencyWithSymbol = `${symbol} ${currency}`;
  return { value: currency, name: currencyWithSymbol };
});

export default currenciesList;
