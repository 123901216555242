import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getBooking } from "../api/bookings";
import { bookingsReceived } from "../slices/bookings";
import { StoreKeys } from "../storeKeys";

export const useQueryBooking = () => {
  const dispatch = useDispatch();

  const useBooking = (bookingId, staleTime = 60000) =>
    useQuery({
      queryKey: [StoreKeys.GET_BOOKING, bookingId],
      queryFn: () => getBooking(bookingId),
      enabled: Boolean(bookingId),
      staleTime,
      refetchOnWindowFocus: true,
      onSuccess: (data) => {
        if (data.code == 200 && data.booking) {
          dispatch(bookingsReceived({ bookings: [data.booking] }));
        }
      },
    });

  return { useBooking };
};
