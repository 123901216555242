import React from "react";
import loader from "../assets/images/cliploader.gif";

const Loading = () => {
  return (
    <div className="loader">
      <div className="loader-box">
        <img src={loader} alt="loader" />
      </div>
    </div>
  );
};

export default Loading;
