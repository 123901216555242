import { Button, Col, DatePicker, Form, Modal, Row, Select } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import "./booking.css";

const { RangePicker } = DatePicker;

const BookingFilter = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { visible, handleOk, handleCancel, setFilterValues } = props;

  const onFinish = async (values) => {
    const copyValues = values;
    if (copyValues.groupname && copyValues.groupname.length > 0) {
      copyValues.startDate = copyValues.groupname[0].format("YYYY-MM-DD");
      copyValues.endDate = copyValues.groupname[1].format("YYYY-MM-DD");
    }
    setFilterValues(copyValues);
    handleCancel();
    // form.resetFields();
  };

  const onCancel = () => {
    form.resetFields();
    handleCancel();
    setFilterValues({});
  };

  const onOk = () => {
    // form.resetFields();
    handleOk();
  };

  const { Option } = Select;

  return (
    <Modal
      title={t("filter.heading1")}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      wrapClassName="filter-modal"
    >
      <div className="booking-filter">
        <div className="container">
          <Row>
            <Col span={24}>
              <div className="filter-box">
                <h2>{t("filter.heading2")}</h2>

                <Form name="basic" form={form} onFinish={onFinish}>
                  <Row style={{ alignItems: "center" }}>
                    <Col span={24}>
                      <Form.Item label={t("filter.label5")} name="groupname">
                        <RangePicker />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item label={t("strings.status")} name="status">
                        <Select placeholder="Select" allowClear>
                          <Option value="booked">Booked</Option>
                          <Option value="open">Open</Option>
                          <Option value="inquiry">Set as inquiry</Option>
                          <Option value="decline">Decline</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item>
                        <Button
                          className="border-btn"
                          style={{ marginRight: 10 }}
                          onClick={onCancel}
                        >
                          {t("strings.clear", "Clear")}
                        </Button>
                        <Button type="primary" htmlType="submit">
                          {t("strings.ok", "OK")}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
BookingFilter.propTypes = {
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  setFilterValues: PropTypes.func,
};
BookingFilter.defaultProps = {
  visible: false,
  handleCancel: () => {},
  handleOk: () => {},
  setFilterValues: () => {},
};

export default BookingFilter;
