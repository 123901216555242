import { Button, Result } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import Wrapper from "../wrapper";

const UserNotAuthorize = () => {
  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };
  return (
    <Wrapper>
      <div className="result-404">
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page. Please contact owner of the organization"
          extra={
            <Button type="primary" onClick={handleClick}>
              Return
            </Button>
          }
        />
      </div>
    </Wrapper>
  );
};
export default UserNotAuthorize;
