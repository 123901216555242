import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getSubscriptionStatus } from "../api/organizations";

export const useOrganizationSubscription = (organizationId) => {
  const [subscribed, setSubscribed] = useState(true);
  const [onTrial, setOnTrial] = useState(true);
  const [daysLeft, setDaysLeft] = useState();
  const [isFree, setIsFree] = useState(false);

  const getSubscriptionMutation = useMutation(
    () => getSubscriptionStatus(organizationId),
    {
      onSuccess: (data, variables) => {
        if (data && data.code === 200) {
          const { days, isOnTrial, isSubscribed, isFree } = data.status;
          setDaysLeft(parseInt(days, 10));
          setSubscribed(JSON.parse(isSubscribed));
          setOnTrial(JSON.parse(isOnTrial));
          setIsFree(JSON.parse(isFree));
        }
      },
    }
  );

  const hasAccess = () => {
    return (onTrial && daysLeft !== 0) || isFree === 1 ? true : subscribed;
  };

  useEffect(() => {
    if (organizationId) {
      getSubscriptionMutation.mutate();
    }
  }, [organizationId]);

  return { hasAccess };
};
