import { bookingInstance } from "../axios/axiosConfig";

export const getBookings = async (
  organizationId,
  limit = 30,
  offset = 0,
  params = {}
) => {
  let nParams = { organizationId, limit, offset };
  if (params.q) {
    nParams = { ...nParams, q: params.q };
  }

  if (params.startDate) {
    nParams = { ...nParams, startDate: params.startDate };
  }

  if (params.endDate) {
    nParams = { ...nParams, endDate: params.endDate };
  }

  if (params.status) {
    nParams = { ...nParams, status: params.status };
  }

  const res = await bookingInstance.get("/", {
    params: nParams,
  });
  return res.data;
};

export const getTimelineCalendar = async (
  organizationId,
  startTime,
  endTime
) => {
  const res = await bookingInstance.get("/timeline-calendar", {
    params: { organizationId, startTime, endTime },
  });
  return res.data;
};

export const upsertGuest = async (bookingId, data) => {
  const res = await bookingInstance.post(`/${bookingId}/addGuest`, data);
  return res.data;
};

export const getBooking = async (bookingId) => {
  const res = await bookingInstance.get(`/${bookingId}`);
  return res.data;
};

export const updateBooking = async (data) => {
  const res = await bookingInstance.post("/changeBooking", data);
  return res.data;
};

export const changeStatusBooking = async (data) => {
  const res = await bookingInstance.post("/setStatus", data);
  return res.data;
};

export const addBooking = async (data) => {
  const res = await bookingInstance.post("/addBooking", data);
  return res.data;
};
