import * as React from "react";

import { CommentOutlined } from "@ant-design/icons";
import { Button } from "antd";

export const showCrispChat = () => {
  window.$crisp.push(["do", "chat:show"]);
  window.$crisp.push(["do", "chat:open"]);
};

export const ChatButton = ({ text }) => {
  return (
    <Button type="primary" icon={<CommentOutlined />} onClick={showCrispChat}>
      {text}
    </Button>
  );
};

const CrispChat = () => {
  const crispWebsiteId =
    process.env.REACT_APP_CRISP_WEBSITE_ID ||
    "81060ddf-c8c4-4c90-b392-228cdda2f053";

  React.useEffect(() => {
    if (!crispWebsiteId) {
      return;
    }

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = crispWebsiteId;
    (() => {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, [crispWebsiteId]);

  return null;
};

export default CrispChat;
