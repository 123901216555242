import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const unitTypesAdapter = createEntityAdapter({
  selectId: (unitType) => unitType.id,
});

const initialState = {};

export const unitTypesSlice = createSlice({
  name: "unitType",
  initialState: unitTypesAdapter.getInitialState({ ...initialState }),
  reducers: {
    unitTypesReceived(state, action) {
      unitTypesAdapter.addMany(state, action.payload.unitTypes);
    },
  },
});

export const { unitTypesReceived } = unitTypesSlice.actions;

export default unitTypesSlice.reducer;
