import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addBooking,
  changeStatusBooking,
  updateBooking,
} from "../api/bookings";
import { StoreKeys } from "../storeKeys";

export const useMutateBooking = () => {
  const queryClient = useQueryClient();

  const updateBookingMutation = useMutation((data) => updateBooking(data), {
    onSuccess: (_res, variables) => {
      queryClient.invalidateQueries({
        queryKey: [StoreKeys.GET_BOOKING, variables.id],
      });
    },
  });

  const updateStatusBookingMutation = useMutation(
    (data) => changeStatusBooking(data),
    {
      onSuccess: (_res, variables) => {
        queryClient.invalidateQueries({
          queryKey: [StoreKeys.GET_BOOKING, variables.bookingId],
        });
      },
    }
  );

  const createBookingMutation = useMutation((data) => addBooking(data), {
    onSuccess: (_data) => {
      queryClient.invalidateQueries(StoreKeys.GET_BOOKINGS);
    },
  });

  return {
    updateBookingMutation,
    updateStatusBookingMutation,
    createBookingMutation,
  };
};
