import "./organization.css";

import { Button, Col, Form, Input, Modal, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

const CreateOrganizationPopup = (props) => {
  const { close, visible, handleOk, handleCancel } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const hidePopUp = () => {
    close();
    form.resetFields();
  };

  return (
    <Modal
      title={t("organizations.create_btn_modal", "Create new organization")}
      name="modal1"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName="right-modal-container"
    >
      <Helmet>
        <body className={visible ? "ant-scrolling-effect" : ""} />
      </Helmet>
      <Form
        form={form}
        name="basic"
        onFinish={handleOk}
        style={{ marginTop: 20 }}
      >
        <Row style={{ alignItems: "center", padding: "0px 20px" }}>
          <Col span={24}>
            <Form.Item
              label={t("organizations.name", "Organization Name")}
              name="name"
              required={true}
              rules={[
                {
                  required: true,
                  min: 1,
                  max: 250,
                  message: t(
                    "organizations.error_name",
                    "Please enter name of the organization. 1 - 250 characters"
                  ),
                },
              ]}
            >
              <Input
                placeholder={t(
                  "organizations.name_placeholder",
                  "Enter organization name"
                )}
                name="name"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ alignItems: "center", padding: "0px 20px" }}>
          <Col span={24}>
            <Form.Item
              label={t("organizations.description", "Organization Description")}
              name="description"
              required={true}
              rules={[
                {
                  required: true,
                  min: 1,
                  max: 250,
                  message: t(
                    "organizations.error_description",
                    "Please enter description of the organization. 1 - 250 characters"
                  ),
                },
              ]}
            >
              <Input.TextArea
                placeholder={t(
                  "organizations.description_placeholder",
                  "Enter organization description"
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            alignItems: "center",
            background: "#fbfbfc",
            padding: "0px 20px",
            paddingTop: "20px",
          }}
        >
          <Col span={24}>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => {
                  hidePopUp();
                }}
              >
                {t("strings.cancel")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("strings.save")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

CreateOrganizationPopup.propTypes = {
  close: PropTypes.func,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  getData: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};

CreateOrganizationPopup.defaultProps = {
  close: () => {},
  handleCancel: () => {},
  handleOk: () => {},
  getData: () => {},
  visible: false,
  setVisible: () => {},
};

export default CreateOrganizationPopup;
