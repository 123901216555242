import { useQuery } from "@tanstack/react-query";
import { DatePicker, Modal, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import actionIcon from "../../assets/images/action-icon.png";
import guestIcon from "../../assets/images/guest-icon.png";
import night from "../../assets/images/night.png";
import Meta from "../meta";
import UserLock from "../userlock/userlock";
import Wrapper from "../wrapper";
import "./guests.css";

import { userInstance } from "../../axios/axiosConfig";
import DeletePopup from "../property/deletepopup";
import NoGuest from "./noguests";
import UpdateGuestPopup from "./updateGuest";

import { useTranslation } from "react-i18next";
import { getGuests } from "../../api/guests";
import deleteImg from "../../assets/images/delete.svg";
import editImg from "../../assets/images/edit.svg";
import { useMemberPermissions, useOrganizationSubscription } from "../../hooks";
import { StoreKeys } from "../../storeKeys";
import Loading from "../Loading";
import UserNotAuthorize from "../userlock/UserNotAuthorize";

const GuestList = () => {
  const { organizationId } = useParams();
  const { t } = useTranslation();

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const [offset, setOffset] = useState(0);

  const [currId, setCurrId] = useState(0);
  const [visibleGuest, setVisibleGuest] = useState(false);
  const [visibilityOFDelete, setVisibilityOFDelete] = useState(false);
  const [editValues, setEditValues] = useState({});

  const {
    data: guestsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      StoreKeys.GET_GUESTS,
      organizationId,
      tableParams.pagination.pageSize,
      offset,
    ],
    queryFn: () =>
      getGuests(organizationId, tableParams.pagination.pageSize, offset),
    keepPreviousData: true,
  });

  const { hasAccess } = useOrganizationSubscription(organizationId);
  const { getPermissions } = useMemberPermissions(organizationId);
  const {
    guestsRead: canRead,
    guestsWrite: canWrite,
    guestsDelete: canDelete,
  } = getPermissions();

  const close = () => {
    setVisibleGuest(false);
  };

  const onOk = () => {
    setVisibleGuest(false);
  };
  const handleCancel = () => {
    setVisibleGuest(false);
    setVisibilityOFDelete(false);
  };

  const enableEditGuestButton = (record) => {
    return (
      <img
        className="editimg"
        src={editImg}
        alt="Edit guest"
        onClick={() => edit(record)}
        role="presentation"
      />
    );
  };

  const disableEditGuestButton = () => {
    return (
      <Tooltip
        title={t("guests.not_permission", "You dont have permission")}
        color="gold"
      >
        <img className="editimg" src={editImg} alt="" role="presentation" />
      </Tooltip>
    );
  };

  const enableDeleteGuestButton = (recordId) => {
    return (
      <img
        src={deleteImg}
        alt=""
        onClick={() => delRow(recordId)}
        role="presentation"
      />
    );
  };

  const disableDeleteGuestButton = () => {
    return (
      <Tooltip
        title={t("guests.not_permission", "You dont have permission")}
        color="gold"
      >
        return <img src={deleteImg} alt="" role="presentation" />
      </Tooltip>
    );
  };

  const columns = [
    {
      title: t("guests.guest_lb", "Guest"),
      dataIndex: "fullName",

      render: (fullName, record) => (
        <div className="guest-info-table">
          <div className="guest-info-box">
            <h3>{fullName}</h3>
            <span>{record.country}</span>
            <p>
              {moment(new Date(record.createdAt)).format("DD MMM, YYYY")} <i />{" "}
              {record.nights} <img src={night} alt="" />
            </p>
          </div>
        </div>
      ),
    },
    {
      title: t("guests.contact_lb", "Contact"),
      dataIndex: "email",

      render: (email, record) => (
        <div className="guest-contact-table">
          <p>{email}</p>
          <span>{record.phone}</span>
        </div>
      ),
    },
    {
      title: t("guests.spent_lb", "Spent"),
      dataIndex: "spent",

      render: (spent, record) => (
        <div className="guest-spent-table">
          <p>
            {record.currency}
            {spent}
          </p>
        </div>
      ),
    },
    {
      title: t("guests.notes_lb", "Notes"),
      dataIndex: "notes",
    },
    {
      title: t("guests.action_lb", "Action"),
      key: "action",
      render: (record) => (
        <div className="guest-action">
          <img className="action-icon" src={actionIcon} alt="" />
          <div className="edit-delete">
            {canWrite === 1
              ? enableEditGuestButton(record)
              : disableEditGuestButton()}
            {canDelete === 1
              ? enableDeleteGuestButton(record.id)
              : disableDeleteGuestButton()}
          </div>
        </div>
      ),
    },
  ];

  const edit = (data) => {
    setEditValues(data);
    setVisibleGuest(true);
  };

  const delRow = (id) => {
    setVisibilityOFDelete(true);
    setCurrId(id);
  };

  const remove = async () => {
    const values = {
      id: currId,
    };

    const response = await userInstance.post("/deleteGuest", values);
    if (response.data.code === 200) {
      setVisibilityOFDelete(false);
      getData();
      toast.success("Successfully deleted company", { containerId: "B" });
    } else {
      toast.error("Server error please try again", { containerId: "B" });
    }
  };

  const { RangePicker } = DatePicker;

  const onChange = (pagination, filters, sorter) => {
    setOffset(pagination.current * pagination.pageSize);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  useEffect(() => {
    refetch();
  }, [JSON.stringify(tableParams)]);

  const [menuToggle, setMenuToggle] = useState(false);
  // const handleMenuSide = (e) => {
  //   if (e === "open") {
  //     setMenuToggle(true);
  //   } else if (e === "close") {
  //     setMenuToggle(false);
  //   } else if (e === "toggle") {
  //     setMenuToggle(!menuToggle);
  //   }
  // };

  if (isLoading) {
    return <Loading />;
  }

  if (!hasAccess()) {
    return (
      <Wrapper organizationId={organizationId}>
        <UserLock />
      </Wrapper>
    );
  }

  if (canRead !== 1) {
    return <UserNotAuthorize />;
  }

  if (guestsData && guestsData.guests.length < 1) {
    return (
      <Wrapper organizationId={organizationId}>
        <NoGuest />
      </Wrapper>
    );
  }

  return (
    <Wrapper organizationId={organizationId}>
      <Meta>
        <body className="guest-page-view" />
      </Meta>

      <div
        className={`guest-wrapper ${menuToggle ? "guest-wrapper-expand" : ""}`}
      >
        <div className="guest-list">
          <div className="page-header">
            <h1>
              <img src={guestIcon} alt="unit" />{" "}
              {t("guests.heading_lb", "Guests")}
            </h1>
          </div>

          <div className="guest-table">
            <div className="custom-table">
              <Table
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={guestsData.guests}
                pagination={tableParams.pagination}
                onChange={onChange}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <UpdateGuestPopup
        visible={visibleGuest}
        handleCancel={handleCancel}
        onOk={onOk}
        close={close}
        editValues={editValues}
        getData={refetch}
      />
      <Modal open={visibilityOFDelete} wrapClassName="delete-modal">
        <DeletePopup
          dataObject={() => remove()}
          cancel={() => handleCancel()}
        />
      </Modal>
    </Wrapper>
  );
};

export default GuestList;
