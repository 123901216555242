import "./userProfile.css";

import {
  PoweroffOutlined,
  ProjectOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";

import property1 from "../../../assets/images/placeholder.svg";
import { propertyInstance } from "../../../axios/axiosConfig";
import { useMutateAuth } from "../../../hooks/useMutateAuth";
import { useQueryAuth } from "../../../hooks/useQueryAuth";

const UserProfile = ({ userName, imgState, propertyImg }) => {
  const { t } = useTranslation();
  const { unitTypeId } = useParams();
  const [img, setImg] = useState("");
  const [name, setName] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [propertyImage, setPropertyImage] = useState("");
  const history = useHistory();

  const { logoutMutation } = useMutateAuth();
  const { useQueryMe } = useQueryAuth();

  const { data: userData } = useQueryMe();

  const exit = async () => {
    await logoutMutation.mutateAsync();
    history.push("/");
  };

  useEffect(() => {
    if (userData) {
      const statusCode = userData.code;
      if (statusCode === 200) {
        const body = userData.user;
        if (body) {
          if (body.image !== null) {
            setImg(body.image);
          }
          if (body.fullName !== null) {
            setName(`${body.fullName}`);
          } else {
            setName(`${body.email}`);
          }
        } else {
          localStorage.clear();
          history.push("/");
        }
      } else if (statusCode === 422) {
        // token expired
        localStorage.clear();
        history.push("/");
      }
    }
  }, [userData]);

  useEffect(() => {
    const { pathname } = window.location;
    if (
      pathname.includes("overview") ||
      pathname.includes("location") ||
      pathname.includes("photos") ||
      pathname.includes("rates") ||
      pathname.includes("season-rates") ||
      pathname.includes("channelmanager") ||
      pathname.includes("services")
    ) {
      const getPropertyData = async () => {
        const response = await propertyInstance.post("/getProperty", {
          unitTypeId,
        });
        if (response.data.code === 200) {
          const { name, image } = response.data;
          if (name[0]) setPropertyName(name[0].name);
          if (image) setPropertyImage(image);
        }
      };
      getPropertyData();
    }
  }, [propertyImg]);

  const menu = (
    <Menu className="setting-dropdown">
      <Menu.Item>
        <Link to="/profile">
          <UserOutlined /> {t("userprofile.label1", "Profile")}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/organizations">
          <ProjectOutlined /> {t("userprofile.organizations", "Organizations")}
        </Link>
      </Menu.Item>
      <Menu.Item onClick={() => exit()} danger>
        <span>
          <PoweroffOutlined /> {t("sidebar.menu10")}
        </span>
      </Menu.Item>
    </Menu>
  );

  const { pathname } = window.location;
  if (
    pathname.includes("overview") ||
    pathname.includes("location") ||
    pathname.includes("photos") ||
    pathname.includes("rates") ||
    pathname.includes("season-rates") ||
    pathname.includes("channelmanager") ||
    pathname.includes("/services")
  ) {
    return (
      <div className="user-profile">
        <div className="user-img">
          {propertyImage ? (
            <img src={propertyImage} alt="User" />
          ) : (
            <img src={property1} alt="property" />
          )}
        </div>
        <h3>{propertyName}</h3>
      </div>
    );
  }

  return (
    <div className="user-profile">
      <div className="user-img">
        {img ? (
          <img src={img} alt={name} />
        ) : (
          <Avatar size="60px" color="#FF884B" name={name} round="50px" />
        )}

        <Dropdown overlay={menu}>
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            role="button"
            aria-hidden="true"
          >
            <SettingOutlined />
          </div>
        </Dropdown>
      </div>
      <h3>{name}</h3>
      {/* <span>{isSubUser ? "Sub User" : "Owner"}</span> */}
    </div>
  );
};

UserProfile.propTypes = {
  imgState: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  propertyImg: PropTypes.string,
  userName: PropTypes.string,
};
UserProfile.defaultProps = {
  imgState: "",
  propertyImg: "",
  userName: "",
};

export default UserProfile;
