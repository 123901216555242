export const rentalTypeOptions = (t) => {
  return [
    {
      key: "Holiday House",
      value: t("overview.option1"),
    },
    {
      key: "Holiday Apartment",
      value: t("overview.option2"),
    },
    {
      key: "Bed and Breakfast",
      value: t("overview.option3"),
    },
    {
      key: "Boat House",
      value: t("overview.option4"),
    },
    {
      key: "Bungalow",
      value: t("overview.option5"),
    },
    {
      key: "Cabin",
      value: t("overview.option6"),
    },
    {
      key: "Agritourism",
      value: t("overview.option7"),
    },
    {
      key: " Mobile House",
      value: t("overview.option8"),
    },
    {
      key: "Villa",
      value: t("overview.option9"),
    },
    {
      key: "Room",
      value: t("overview.option10"),
    },
    {
      key: "Hotel",
      value: t("overview.option11"),
    },
    {
      key: "Camping",
      value: t("overview.option12"),
    },
    {
      key: "Student Housing",
      value: t("overview.option13"),
    },
    {
      key: "Resort",
      value: t("overview.option14"),
    },
    {
      key: "Inn",
      value: t("overview.option15"),
    },
    {
      key: "Hostel",
      value: t("overview.option16"),
    },
    {
      key: "Motel",
      value: t("overview.option17"),
    },
    {
      key: "Hospital",
      value: t("overview.option18"),
    },
    {
      key: "Pousada",
      value: t("overview.option19"),
    },
  ];
};
