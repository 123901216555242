import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Modal, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteCompany, getCompanies } from "../../api/companies";
import actionIcon from "../../assets/images/action-icon.png";
import companyIcon from "../../assets/images/company-icon.png";
import noCompany from "../../assets/images/company-placeholder.png";
import deleteImg from "../../assets/images/delete.svg";
import editImg from "../../assets/images/edit.svg";
import { useMemberPermissions, useOrganizationSubscription } from "../../hooks";
import { StoreKeys } from "../../storeKeys";
import Loading from "../Loading";
import Meta from "../meta";
import DeletePopup from "../property/deletepopup";
import UserLock from "../userlock/userlock";
import UserNotAuthorize from "../userlock/UserNotAuthorize";
import Wrapper from "../wrapper";
import AddCompany from "./addcompanypopup";
import EditCompany from "./editcompanypop";
import "./guests.css";

const CompanyList = () => {
  const { organizationId } = useParams();
  const { t } = useTranslation();

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const [offset, setOffset] = useState(0);
  const [editData, showEditData] = useState({});
  const [currId, setCurrId] = useState(0);
  const [visibilityOFDelete, setVisibilityOFDelete] = useState(false);

  const { hasAccess } = useOrganizationSubscription(organizationId);
  const { getPermissions } = useMemberPermissions(organizationId);
  const {
    guestsRead: canRead,
    guestsWrite: canWrite,
    guestsDelete: canDelete,
  } = getPermissions();

  const {
    data: companiesData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      StoreKeys.GET_COMPANIES,
      organizationId,
      tableParams.pagination.pageSize,
      offset,
    ],
    queryFn: () =>
      getCompanies(organizationId, tableParams.pagination.pageSize, offset),
    keepPreviousData: true,
  });

  const deleteCompanyMutation = useMutation(
    (companyId) => deleteCompany(companyId),
    {
      onSuccess: (data) => {
        if (data.code === 200) {
          setVisibilityOFDelete(false);
          refetch();
          toast.success("Successfully deleted company", {
            containerId: "B",
            toastId: "unique",
          });
        } else {
          toast.error("Server error please try again", { containerId: "B" });
        }
      },
    }
  );

  const delRow = (id) => {
    setVisibilityOFDelete(true);
    setCurrId(id);
  };

  const remove = async () => {
    deleteCompanyMutation.mutate(currId);
  };

  const enableEditCompanyButton = (record) => {
    return (
      <img
        className="editimg"
        src={editImg}
        alt="Edit company"
        onClick={() => edit(record)}
        role="presentation"
      />
    );
  };

  const disableEditCompanyButton = () => {
    return (
      <Tooltip
        title={t("company.not_authorized", "You dont have permission")}
        color="gold"
      >
        <img className="editimg" src={editImg} alt="" role="presentation" />
      </Tooltip>
    );
  };

  const enableDeleteCompanyButton = (recordId) => {
    return (
      <img
        src={deleteImg}
        alt=""
        onClick={() => delRow(recordId)}
        role="presentation"
      />
    );
  };

  const disableDeleteCompanyButton = () => {
    return (
      <Tooltip
        title={t("company.not_authorized", "You dont have permission")}
        color="gold"
      >
        <img src={deleteImg} alt="" role="presentation" />
      </Tooltip>
    );
  };

  const enableCreateCompanyButton = () => {
    return (
      <Button type="primary" icon={<PlusOutlined />} onClick={() => show()}>
        {t("company.add_btn", "Add Company")}
      </Button>
    );
  };

  const disableCreateCompanyButton = () => {
    return (
      <Tooltip
        title={t("company.not_authorized", "You dont have permission")}
        color="gold"
      >
        <Button type="primary" disabled={true} icon={<PlusOutlined />}>
          {t("company.add_btn", "Add Company")}
        </Button>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: t("company.name_lb", "Name"),
      dataIndex: "name",
    },
    {
      title: t("company.vat_lb", "Vat ID"),
      dataIndex: "vatId",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: t("company.address_lb", "Address"),
      dataIndex: "address",
    },
    {
      title: t("company.action_lb", "Action"),
      key: "action",
      render: (record) => (
        <div className="guest-action">
          <img className="action-icon" src={actionIcon} alt="" />
          <div className="edit-delete">
            {canWrite === 1
              ? enableEditCompanyButton(record)
              : disableEditCompanyButton()}
            {canDelete === 1
              ? enableDeleteCompanyButton(record.id)
              : disableDeleteCompanyButton()}
          </div>
        </div>
      ),
    },
  ];

  function onChange() {
    setOffset(pagination.current * pagination.pageSize);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  }

  const [visible, setVisible] = useState(false);
  const [visibleTwo, setVisibleTwo] = useState(false);
  const show = () => {
    setVisible(true);
    showEditData({});
  };
  const edit = (record) => {
    showEditData(record);
    setVisibleTwo(true);
  };
  const handleOk = () => {
    setVisible(false);
    setVisibleTwo(false);
    setVisibilityOFDelete(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setVisibleTwo(false);
    setVisibilityOFDelete(false);
  };

  const isAccess = hasAccess();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAccess) {
    return (
      <Wrapper organizationId={organizationId}>
        <UserLock />
      </Wrapper>
    );
  }

  if (canRead !== 1) {
    return <UserNotAuthorize />;
  }

  if (companiesData && companiesData.companies.length < 1) {
    return (
      <Wrapper organizationId={organizationId}>
        <Meta>
          <body className="company-page-view" />
        </Meta>

        <div className="add-team-page">
          <div className="add-subuser">
            <img src={noCompany} alt="noguest" />
            <h4>{t("company.title", "Companies")}</h4>
            <p>
              {t("company.no_desc", "Currently there are no companies created")}
            </p>
            {canWrite === 1
              ? enableCreateCompanyButton()
              : disableCreateCompanyButton()}
          </div>
        </div>
        <AddCompany
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          getData={refetch}
          organizationId={organizationId}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper organizationId={organizationId}>
      <Meta>
        <body className="company-page-view" />
      </Meta>

      <div className="guest-list company-list">
        <div className="page-header">
          <h1>
            <img src={companyIcon} alt="unit" />{" "}
            {t("company.title", "Companies")}
          </h1>
          {canWrite === 1
            ? enableCreateCompanyButton()
            : disableCreateCompanyButton()}
        </div>

        <div className="guest-table company-table">
          <div className="custom-table">
            <Table
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={companiesData.companies}
              onChange={onChange}
              pagination={tableParams.pagination}
              loading={isLoading}
            />
          </div>
        </div>
      </div>

      <EditCompany
        visible={visibleTwo}
        handleOk={handleOk}
        handleCancel={handleCancel}
        getData={refetch}
        editData={editData}
      />
      <AddCompany
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        getData={refetch}
        organizationId={organizationId}
      />
      <Modal
        open={visibilityOFDelete}
        handleOk={handleOk}
        handleCancel={handleCancel}
        wrapClassName="delete-modal"
      >
        <DeletePopup
          dataObject={() => remove()}
          cancel={() => handleCancel()}
        />
      </Modal>
    </Wrapper>
  );
};

export default CompanyList;
