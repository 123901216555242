import { DeleteOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import loader from "../../assets/images/cliploader.gif";
import propertyPlace from "../../assets/images/property-placeholder.png";
import { useMemberPermissions, useOrganizationSubscription } from "../../hooks";
import { useMutateProperty } from "../../hooks/useMutateProperty";
import { useQueryProperty } from "../../hooks/useQueryProperty";
import Meta from "../meta";
import UserLock from "../userlock/userlock";
import Wrapper from "../wrapper";
import DeletePopup from "./deletepopup";
import "./property.css";

const Services = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { organizationId, unitTypeId } = useParams();

  const { getPermissions } = useMemberPermissions(organizationId);
  const { hasAccess } = useOrganizationSubscription(organizationId);

  const {
    serviceRead: canRead,
    serviceWrite: canWrite,
    serviceDelete: canDelete,
  } = getPermissions();

  const { useGetServices } = useQueryProperty();
  const { data, isFetching } = useGetServices(unitTypeId);
  const { addServiceMutation, deleteServiceMutation } = useMutateProperty();

  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [curRowId, setCurRowId] = useState(0);
  const [editOpen, setEditOpen] = useState(false);

  const delRow = (id) => {
    setVisible1(true);
    setCurRowId(id);
  };

  const show = () => {
    form.resetFields();
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    setVisible1(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setVisible1(false);
  };

  const edit = async (data) => {
    setEditOpen(true);
    setVisible(true);
    form.setFieldsValue({
      serviceId: data.id,
      serviceName: data.serviceName,
      servicePrice: data.servicePrice,
      serviceQuantity: data.quantity,
    });
    // setEditOpen(true);
  };

  const remove = async () => {
    const values = {
      id: curRowId,
    };
    deleteServiceMutation.mutate(values);
    if (!deleteServiceMutation.isError) {
      setVisible1(false);
      toast.success("successfully deleted service", { containerId: "B" });
    } else {
      toast.error("server error please try again", { containerId: "B" });
    }
  };

  const onFinish = async (values) => {
    values.unitTypeId = unitTypeId;
    addServiceMutation.mutate(values);

    if (!addServiceMutation.isError) {
      toast.success("successfully added service", { containerId: "B" });
      setVisible(false);
    } else {
      toast.error("server error please try again", { containerId: "B" });
    }
    form.resetFields();
  };

  const enableButton = (
    <Button type="primary" icon={<PlusOutlined />} onClick={show}>
      <Link
        to={`/organizations/${organizationId}/properties/${unitTypeId}/services`}
      >
        {t("services.add_services_lb", "Add Services")}
      </Link>
    </Button>
  );

  const disableButton = (
    <Tooltip
      title={t(
        "services.user_not_authorize_lb",
        "You are not authorize to create new service"
      )}
      color="gold"
    >
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={show}
        disabled="true"
      >
        <Link
          to={`/organizations/${organizationId}/properties/${unitTypeId}/services`}
        >
          {t("services.add_services_lb", "Add Services")}
        </Link>
      </Button>
    </Tooltip>
  );
  const createServiceButton = canWrite === 1 ? enableButton : disableButton;

  const title = t("services.add_services_lb", "Add Services");
  const isAccess = hasAccess();

  const pageContent = (
    <>
      {(data ? data.services : []).length > 0 ? (
        <Wrapper organizationId={organizationId}>
          <div className="owner-page">
            <div className="page-header">
              <h1>{t("services.heading3", "Services")}</h1>
              {createServiceButton}
            </div>

            <div className="owner-list">
              <div className="custom-table">
                <table>
                  <thead>
                    <tr>
                      <th>{t("services.th1")}</th>
                      <th>{t("services.th2")}</th>
                      <th>{t("services.th3")}</th>
                      <th> </th>
                    </tr>
                  </thead>

                  <tbody>
                    {(data ? data.services : []).map((el) => (
                      <tr key={el.id}>
                        <td>
                          <div
                            className="owner-info"
                            onClick={() => edit(el)}
                            role="presentation"
                          >
                            <div className="owner-title">
                              <h5>{`${el.serviceName}`}</h5>
                            </div>
                          </div>
                        </td>

                        <td>{el.servicePrice}</td>

                        <td>{el.quantity}</td>

                        <td>
                          <div className="owner-action">
                            <FormOutlined onClick={() => edit(el)} />
                            <DeleteOutlined
                              hidden={canDelete !== 1 ? true : false}
                              onClick={() => delRow(el.id)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Modal
            title={
              editOpen ? t("services.edit_service_lb", "Edit Service") : title
            }
            open={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            wrapClassName="right-modal-container"
          >
            <Helmet>
              <body className="service-page-view" />
            </Helmet>
            <Form form={form} name="basic" onFinish={onFinish}>
              <Form.Item name="serviceId">
                <Input hidden />
              </Form.Item>
              <Form.Item
                label={t("services.heading4")}
                name="serviceName"
                style={{ padding: "0px 10px" }}
                rules={[
                  {
                    required: true,
                    message: t("services.heading5"),
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("services.heading6")}
                name="servicePrice"
                style={{ padding: "0px 10px" }}
                rules={[
                  {
                    required: true,
                    message: t("services.heading8"),
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                label={t("services.heading9")}
                name="serviceQuantity"
                style={{ padding: "0px 10px" }}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item className="text-center">
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setVisible(false);
                  }}
                  className="border-btn"
                >
                  {t("strings.cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("strings.save")}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            open={visible1}
            onOk={handleOk}
            onCancel={handleCancel}
            wrapClassName="delete-modal"
          >
            <DeletePopup
              dataObject={() => remove()}
              cancel={() => handleCancel()}
            />
          </Modal>
        </Wrapper>
      ) : (
        <Wrapper organizationId={organizationId}>
          <div className="add-team-page">
            <div className="add-subuser">
              <img alt="subuser" />
              <h4>{t("services.add_services_lb", "Add Services")}</h4>
              <p>{t("services.add_services_lb", "Add Services")}</p>
              {createServiceButton}
            </div>
          </div>
          <Modal
            title={t("services.label19")}
            open={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            wrapClassName="right-modal-container"
          >
            <Helmet>
              <body className={visible ? "ant-scrolling-effect" : ""} />
            </Helmet>
            <Form form={form} name="basic" onFinish={onFinish}>
              <Form.Item name="serviceId">
                <Input hidden />
              </Form.Item>
              <Form.Item
                label={t("services.heading4")}
                name="serviceName"
                style={{ padding: "0px 10px" }}
                rules={[
                  {
                    required: true,
                    message: t("services.heading5"),
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("services.heading6")}
                name="servicePrice"
                style={{ padding: "0px 10px" }}
                rules={[
                  {
                    required: true,
                    message: t("services.heading8"),
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                label={t("services.heading9")}
                name="serviceQuantity"
                style={{ padding: "0px 10px" }}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item className="text-center">
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setVisible(false);
                  }}
                  className="border-btn"
                >
                  {t("strings.cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("strings.save")}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </Wrapper>
      )}
    </>
  );

  if (isFetching) {
    return (
      <Wrapper organizationId={organizationId}>
        <Meta>
          <body className="service-page-view" />
        </Meta>

        <div className="loader">
          <div className="loader-box">
            <img src={loader} alt="loader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  if ((data ? data.services : []).length < 1) {
    return (
      <Wrapper organizationId={organizationId}>
        <div className="add-team-page">
          <div className="add-subuser">
            <img src={propertyPlace} alt="subuser" />
            <h4>{t("services.add_services_lb", "Add Services")}</h4>
            <p>
              Currently you don
              <span>&apos;</span>t have any services
            </p>
            {createServiceButton}
          </div>
        </div>

        <Modal
          title={title}
          open={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          wrapClassName="right-modal-container"
        >
          <Helmet>
            <body className={visible ? "ant-scrolling-effect" : ""} />
          </Helmet>
          <Form form={form} name="basic" onFinish={onFinish}>
            <Form.Item name="serviceId">
              <Input hidden />
            </Form.Item>
            <Form.Item
              label={t("services.heading4")}
              name="serviceName"
              style={{ padding: "0px 10px" }}
              rules={[
                {
                  required: true,
                  message: t("services.heading5"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("services.heading6")}
              name="servicePrice"
              style={{ padding: "0px 10px" }}
              rules={[
                {
                  required: true,
                  message: t("services.heading8"),
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label={t("services.heading9")}
              name="serviceQuantity"
              style={{ padding: "0px 10px" }}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item className="text-center">
              <Button
                style={{ marginRight: 10 }}
                onClick={() => {
                  setVisible(false);
                }}
                className="border-btn"
              >
                {t("strings.cancel")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("strings.save")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Wrapper>
    );
  }
  return (
    <>
      <Meta>
        <body className="service-page-view" />
      </Meta>

      {isAccess ? (
        pageContent
      ) : (
        <Wrapper organizationId={organizationId}>
          <UserLock />
        </Wrapper>
      )}
    </>
  );
};

export default Services;
