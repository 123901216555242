import { CloseOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateCompany } from "../../api/companies";

const EditCompany = (props) => {
  const { t } = useTranslation();
  const { visible, handleOk, handleCancel, getData, editData } = props;

  const [form] = Form.useForm();

  form.setFieldsValue({
    name: editData.name,
    vat: editData.vatId,
    email: editData.email,
    address: editData.address,
  });

  const editCompanyMutation = useMutation((values) => updateCompany(values), {
    onSuccess: (data) => {
      if (data.code === 200) {
        handleCancel();
        getData();
        toast.success("Company updated successfully!", {
          containerId: "B",
          toastId: "unique",
        });
      } else {
        toast.error("Some error occurred!", { containerId: "B" });
      }
      form.resetFields();
    },
  });
  const onFinish = async (values) => {
    values.id = editData.id;
    values.organizationId = editData.organizationId;
    editCompanyMutation.mutate(values);
  };

  return (
    <Modal
      title={t("company.edit_company_lb", "Edit Company")}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName="right-modal-container"
    >
      <Helmet>
        <body className={visible ? "ant-scrolling-effect" : ""} />
      </Helmet>
      <div className="cross-btn">
        <CloseOutlined onClick={handleCancel} />
      </div>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        style={{ padding: "0px 20px" }}
      >
        <Row style={{ alignItems: "center", paddingTop: 10 }}>
          <Col span={24}>
            <Form.Item
              label={t("company.name_lb", "Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t(
                    "company.name_message_error",
                    "Please input company name!"
                  ),
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t("company.vat_lb", "VAT ID")}
              name="vat"
              rules={[
                {
                  required: true,
                  message: t(
                    "company.vat_error_message",
                    "Please input vat Id!"
                  ),
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: t(
                    "company.email_error",
                    "The input is not valid E-mail!"
                  ),
                },
                {
                  required: true,
                  message: t(
                    "company.email_error1",
                    "Please input your E-mail!"
                  ),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t("company.address_lb", "Address")}
              name="address"
              rules={[
                {
                  required: true,
                  message: t(
                    "company.address_message",
                    "Please input address!"
                  ),
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center", textAlign: "right" }}>
          <Col span={24}>
            <Form.Item>
              <Button
                className="border-btn"
                style={{ marginRight: 10 }}
                onClick={() => {
                  handleCancel();
                }}
              >
                {t("strings.cancel", "Clear")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("strings.save", "Apply")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

EditCompany.propTypes = {
  visible: PropTypes.string,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  getData: PropTypes.func,
  editData: PropTypes.objectOf(PropTypes.object),
};
EditCompany.defaultProps = {
  visible: false,
  handleOk: () => {},
  handleCancel: () => {},
  getData: () => {},
  editData: {},
};

export default EditCompany;
