import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SENTRY_DSN } from "./config/keys";
import "./ga";
import "./i18n";
import "./index.css";

const env = process.env.REACT_APP_ENV;
Sentry.init({
  dsn: SENTRY_DSN,
  beforeSend(event) {
    if (env === "development") {
      return null;
    }
    return event;
  },
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
