import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import propertyPlace from "../../assets/images/property-placeholder.png";
import Meta from "../meta";
import CreateProperty from "../property/createProperty";
import Wrapper from "../wrapper";

const NoProperty = ({ organizationId }) => {
  const [showCreateProperty, setShowCreateProperty] = useState(false);
  const { t } = useTranslation();

  return (
    <Wrapper organizationId={organizationId}>
      <Meta>
        <body className="booking-page-view" />
      </Meta>
      <div className="add-team-page">
        <div className="add-subuser">
          <img src={propertyPlace} alt="subuser" />
          <h4>{t("strings.property", "Property")}</h4>
          <p>
            {t("nolist.heading1", "Currently you don't have any properties")}
          </p>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowCreateProperty(true)}
          >
            {t("nolist.button1", "Add Property")}
          </Button>
        </div>
      </div>
      <CreateProperty
        visible={showCreateProperty}
        onCancel={() => setShowCreateProperty(false)}
        organizationId={organizationId}
      />
    </Wrapper>
  );
};

NoProperty.propTypes = {
  organizationId: PropTypes.string,
};

NoProperty.defaultProps = {
  organizationId: "",
};

export default NoProperty;
