import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import "./property.css";

const DeletePopup = ({ dataObject, cancel }) => {
  const { t } = useTranslation();
  return (
    <div className="delete-popup-box">
      <WarningOutlined />
      <h5>{t("deletepopup.heading1")}</h5>

      <p>{t("deletepopup.heading2")}</p>

      <Row style={{ padding: 5 }}>
        <Col span={8}>
          <Button onClick={cancel}>{t("strings.cancel")} </Button>
        </Col>
        <Col span={8} offset={7}>
          <Button icon={<DeleteOutlined />} type="primary" onClick={dataObject}>
            {t("strings.delete_item")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

DeletePopup.propTypes = {
  dataObject: PropTypes.func,
  cancel: PropTypes.func,
};
DeletePopup.defaultProps = {
  dataObject: () => {},
  cancel: () => {},
};

export default DeletePopup;
