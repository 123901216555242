import "./property.css";

import { Button, Form, Input, Modal, Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import property1 from "../../assets/images/placeholder.svg";
import { propertyInstance } from "../../axios/axiosConfig";
import { useMemberPermissions } from "../../hooks";

const CreateProperty = ({ visible, onCancel, organizationId }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { getPermissions } = useMemberPermissions(organizationId);
  const { propertiesWrite: canWrite } = getPermissions();

  const onFinish = async (values) => {
    if (!organizationId) {
      toast.error("Missing organization", { containerId: "B", toastId: "B" });
      return;
    }
    const copyValues = values;
    copyValues.name = values.name.trim();
    copyValues.organizationId = organizationId;
    const response = await propertyInstance.post("/add-property", copyValues);
    if (response.data.code === 200) {
      localStorage.setItem("unitTypeId", response.data.unitTypeId);
      history.push(
        `/organizations/${organizationId}/properties/${response.data.unitTypeId}/overview`
      );
    }
  };

  const CreateButton = () => {
    if (canWrite === 1) {
      return (
        <Button type="primary" htmlType="submit">
          {t("strings.save", "Save")}
        </Button>
      );
    } else {
      return (
        <Tooltip
          title={t(
            "create_property.not_authorized",
            "You dont have permission"
          )}
          color="gold"
        >
          <Button type="primary" htmlType="submit" disabled={true}>
            {t("strings.save", "Save")}
          </Button>
        </Tooltip>
      );
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      wrapClassName="add-property-popup"
    >
      <div className="add-property-popup-content">
        <Form form={form} onFinish={onFinish}>
          <img src={property1} alt="property" />
          <h3>{t("create_property.header", "Add a new property")}</h3>
          <p>
            {t(
              "create_property.desc",
              "You are starting the process to create a new property"
            )}
          </p>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t(
                  "create_property.name_error",
                  "Please enter property name!"
                ),
                whitespace: true,
              },
            ]}
          >
            <Input
              placeholder={t(
                "create_property.name_placeholder",
                "Property Name"
              )}
            />
          </Form.Item>
          <div className="property-btns">
            <Button onClick={onCancel}>{t("strings.cancel", "Cancel")}</Button>
            <CreateButton />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

CreateProperty.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  organizationId: PropTypes.string,
};
CreateProperty.defaultProps = {
  onCancel: () => {},
  visible: false,
  organizationId: "",
};

export default CreateProperty;
