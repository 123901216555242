import getSymbolCurrency from "currency-symbol-map";
import * as moment from "moment";
import { abbreviateNumber } from "../../utils/numberUtils";

export const getValueByWeekDay = (weekday, data) => {
  let pricePerNight = "_";
  let minimumStay = "_";

  switch (weekday) {
    case 0:
      // Sunday
      pricePerNight = data.sunPrice || data.pricePerNight || "_";

      minimumStay = data.sunStay || data.minStay || "_";
      break;
    case 1:
      // Monday
      pricePerNight = data.monPrice || data.pricePerNight || "_";

      minimumStay = data.monStay || data.minStay || "_";
      break;
    case 2:
      // Tuesday
      pricePerNight = data.tuePrice || data.pricePerNight || "_";
      minimumStay = data.tueStay || data.minStay || "_";
      break;
    case 3:
      // Wednesday
      pricePerNight = data.wedPrice || data.pricePerNight || "_";
      minimumStay = data.wedStay || data.minStay || "_";
      break;
    case 4:
      // Thursday
      pricePerNight = data.thuPrice || data.pricePerNight || "_";
      minimumStay = data.thuStay || data.minStay || "_";
      break;
    case 5:
      // Friday
      pricePerNight = data.friPrice || data.pricePerNight || "_";
      minimumStay = data.friStay || data.minStay || "_";
      break;
    case 6:
      // Saturday
      pricePerNight = data.satPrice || data.pricePerNight || "_";
      minimumStay = data.satStay || data.minStay || "_";
      break;
  }
  return { pricePerNight, minimumStay };
};

export const getPerNightPriceAndMinStay = (
  beginTime,
  endTime,
  normalRate,
  rate
) => {
  let pricePerNight = "_";
  let currencyCode = null;
  let minimumStay = "_";
  let abbreviatePricePerNight = "_";

  const middleDate = moment(beginTime + (endTime - beginTime) / 2);
  const middleDateNumber = middleDate.weekday();
  const middleDateInMiniSeconds = middleDate.valueOf();

  console.log("NormalRate ===> ", normalRate);
  console.log("Middle: ", middleDateNumber);

  // Calculate on normal rates
  if (normalRate && normalRate.length > 0) {
    const firstNormalRate = normalRate[0];
    currencyCode = firstNormalRate.currencyCode;

    const dataByNormalRate = getValueByWeekDay(
      middleDateNumber,
      firstNormalRate
    );
    pricePerNight = dataByNormalRate.pricePerNight;
    minimumStay = dataByNormalRate.minimumStay;
  }

  // Calculate on season rates
  if (rate && rate.length > 0) {
    const rateData = rate[0];
    const startSeasonRate = moment(rateData.startDate).valueOf();
    const endSeasonRate = moment(rateData.endDate).add(1, "day").valueOf();
    if (
      middleDateInMiniSeconds <= endSeasonRate &&
      middleDateInMiniSeconds >= startSeasonRate
    ) {
      const dataBySeasonRate = getValueByWeekDay(middleDateNumber, rateData);
      pricePerNight = dataBySeasonRate.pricePerNight;
      minimumStay = dataBySeasonRate.minimumStay;
      currencyCode = rateData.currencyCode;
    }
  }

  if (currencyCode) {
    abbreviatePricePerNight = `${getSymbolCurrency(
      currencyCode
    )}${abbreviateNumber(pricePerNight, true, 2)}`;
    pricePerNight = `${getSymbolCurrency(currencyCode)}${pricePerNight}`;
  }

  return { pricePerNight, minimumStay, abbreviatePricePerNight };
};
