import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.png";
import { userInstance } from "../../axios/axiosConfig";
import Meta from "../meta";
import Toaster from "../toaster/toaster";
import "./register.css";

const Register = () => {
  const [form] = Form.useForm();
  const [notifyType, setNotifyType] = useState();
  const [notifyMsg, setNotifyMsg] = useState();
  const history = useHistory();
  const { t } = useTranslation();

  const onFinish = async (values) => {
    const response = await userInstance.post("/signup", values);
    const statusCode = response.data.code;
    const { msg } = response.data;
    if (statusCode === 200) {
      setNotifyType("success");
      setNotifyMsg(msg);
      toast.success(
        t(
          "register.signup_success",
          "Register successfully. Please check your mailbox to confirm your account"
        ),
        {
          containerId: "B",
          toastId: "B",
          autoClose: false,
        }
      );
      history.push("/");
    } else {
      setNotifyType("error");
      setNotifyMsg(msg);
    }
    form.resetFields();
  };

  const close = () => {
    setNotifyType("");
  };

  return (
    <>
      <Meta />
      <div className="register">
        <Toaster notifyType={notifyType} notifyMsg={notifyMsg} close={close} />
        <div className="register-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="register-logo">
                  <img src={logo} alt="Logo" />
                </div>
                <div className="register-form">
                  <h1>{t("register.title", "Register")}</h1>
                  <p>
                    {t("register.description", "We're happy to have you here!")}
                  </p>
                  <div className="register-box">
                    <Form
                      form={form}
                      name="register"
                      onFinish={onFinish}
                      scrollToFirstError
                    >
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                              {
                                type: "email",
                                message: t(
                                  "register.email_error_1",
                                  "The input is not valid E-mail!"
                                ),
                              },
                              {
                                required: true,
                                message: t(
                                  "register.email_error_2",
                                  "Please input your E-mail!"
                                ),
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            name="name"
                            label={t("register.fullname_label", "Full name")}
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "register.fullname_error",
                                  "Please input your full name!"
                                ),
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item
                            name="password"
                            label={t("register.password_label", "Password")}
                            rules={[
                              {
                                required: true,
                                min: 6,
                                message: t(
                                  "register.password_error",
                                  "Password should be at least 6 characters long!"
                                ),
                              },
                            ]}
                            hasFeedback
                          >
                            <Input.Password />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            name="confirm"
                            label={t(
                              "register.confirm_password_btn",
                              "Confirm Password"
                            )}
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                min: 6,
                                message: t(
                                  "register.confirm_password_error",
                                  "Please confirm your password!"
                                ),
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(
                                    new Error(
                                      t(
                                        "register.password_miss_match",
                                        "The two passwords that you entered do not match!"
                                      )
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="agreement"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          t(
                                            "register.agreement_error",
                                            "Should accept agreement"
                                          )
                                        )
                                      ),
                              },
                            ]}
                          >
                            <Checkbox>
                              {t(
                                "register.agreement_label_1",
                                "I have read the"
                              )}{" "}
                              <Link to="/register">
                                {t("register.agreement_label_2", "agreement")}
                              </Link>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24}>
                          <Form.Item>
                            <Button
                              type="primary"
                              className="register-btn"
                              htmlType="submit"
                            >
                              {t("register.register_btn", "Register")}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>

                <div className="q-links">
                  <p>
                    {t("register.already_account", "Already have an account?")}{" "}
                    <Link to="/">{t("register.login_btn", "Login now")}</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
