import "./property.css";

import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Dropdown, Menu, Modal, Row, Tooltip } from "antd";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { deleteProperty, getProperties } from "../../api/properties";
import propertyIcon from "../../assets/images/menu/property-icon.png";
import property1 from "../../assets/images/placeholder.svg";
import homeIcon from "../../assets/images/property-home-icon.png";
import unitIcon from "../../assets/images/property-unit-icon.png";
import { useMemberPermissions, useOrganizationSubscription } from "../../hooks";
import { StoreKeys } from "../../storeKeys";
import { formatHumanDate } from "../../utils/dateUtils";
import Loading from "../Loading";
import UserLock from "../userlock/userlock";
import UserNotAuthorize from "../userlock/UserNotAuthorize";
import Wrapper from "../wrapper";
import CreateProperty from "./createProperty";
import DeletePopup from "./deletepopup";
import NoList from "./nolist";

const PropertyList = () => {
  const queryClient = useQueryClient();
  const [visible, setVisible] = useState(false);
  const { organizationId } = useParams();
  const show = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancelDelete = () => {
    setVisibleOFDelete(false);
  };

  const { t } = useTranslation();
  const [propertyData, setPropertyData] = useState([]);
  const [limit, setLimit] = useState(30);
  const [offset, setOffset] = useState(0);
  const { hasAccess } = useOrganizationSubscription(organizationId);
  const { getPermissions, isLoading: isCheckPermissionsLoading } =
    useMemberPermissions(organizationId);
  const {
    propertiesRead: canRead,
    propertiesWrite: canWrite,
    propertiesDelete: canDelete,
  } = getPermissions();

  const [visibleOFDelete, setVisibleOFDelete] = useState(false);
  const [currProperty, setCurrProperty] = useState(0);
  const [currUnitTypeId, setCurrUnitTypeId] = useState(0);
  const history = useHistory();

  const {
    data: propertiesData,
    loading: propertiesLoading,
    error,
    isFetching,
    isPreviousData,
  } = useQuery({
    queryKey: [StoreKeys.GET_PROPERTIES, organizationId, limit, offset],
    queryFn: () => getProperties(organizationId, limit, offset),
    keepPreviousData: true,
  });

  const deletePropertyMutation = useMutation(
    (propertyId) => deleteProperty(propertyId),
    {
      onSuccess: (data) => {
        if (data.code === 200) {
          setVisibleOFDelete(false);
        }
      },
    }
  );

  const handleUnitTypeClick = (id) => {
    history.push(`/organizations/${organizationId}/properties/${id}/overview`);
  };

  const enableCreatePropertyButton = (
    <Button type="primary" icon={<PlusOutlined />} onClick={() => show()}>
      {t("propertylist.addbtn")}
    </Button>
  );
  const disableCreatePropertyButton = (
    <Tooltip title={t("propertylist.heading1")} color="gold">
      <Button
        disabled="true"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => history.push("/addproperty")}
      >
        {t("propertylist.addbtn")}
      </Button>
    </Tooltip>
  );

  const createPropertyButton =
    canWrite === 1 ? enableCreatePropertyButton : disableCreatePropertyButton;

  const isAccess = hasAccess();

  if (propertiesLoading || isCheckPermissionsLoading) {
    return <Loading />;
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <div
          role="presentation"
          onClick={() => handleUnitTypeClick(currUnitTypeId)}
        >
          {t("strings.edit", "Edit")}
        </div>
      </Menu.Item>
      <Menu.Item key="1" className="delete-property-btn">
        {canDelete === 1 ? (
          <div role="presentation" onClick={() => setVisibleOFDelete(true)}>
            {t("strings.delete", "Delete")}
          </div>
        ) : (
          <Tooltip title="You dont have permission" color="gold">
            <div role="presentation">{t("strings.delete", "Delete")}</div>
          </Tooltip>
        )}
      </Menu.Item>
    </Menu>
  );

  const EmptyList = () => {
    return (
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col span={24}>
          <NoList canWrite={canWrite} show={show} />
        </Col>
      </Row>
    );
  };

  if (!isAccess) {
    return <UserLock />;
  }

  if (canRead !== 1) {
    return <UserNotAuthorize />;
  }

  return (
    <Wrapper organizationId={organizationId}>
      <Helmet>
        <body className="property-page-view" />
      </Helmet>
      <div className="property-listing">
        <div
          className="page-header"
          hidden={!propertiesData || propertiesData.properties.length === 0}
        >
          <h1>
            <img src={propertyIcon} alt="Property" />{" "}
            {t("propertylist.heading")}
          </h1>
          {createPropertyButton}
        </div>

        <div className="property-list">
          {propertiesData && propertiesData.properties.length > 0 ? (
            propertiesData.properties.map((property) => (
              <div className="property" key={property.id}>
                <div className="property-edit-delete">
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <div className="ant-dropdown-link">
                      <EllipsisOutlined
                        onClick={() => {
                          setCurrProperty(property.propertyId);
                          setCurrUnitTypeId(property.id);
                        }}
                      />
                    </div>
                  </Dropdown>
                </div>
                <div
                  className="property-img-box"
                  onClick={() => {
                    setCurrProperty(property.propertyId);
                    handleUnitTypeClick(property.id);
                  }}
                  role="presentation"
                >
                  <img src={property.image || property1} alt="property" />
                </div>
                <div
                  className="property-info"
                  onClick={() => {
                    setCurrProperty(property.propertyId);
                    handleUnitTypeClick(property.id);
                  }}
                  role="presentation"
                >
                  {/* <h3>{el.unitTypeName[0].name}</h3> */}
                  {property.unitTypeName &&
                    property.unitTypeName
                      .filter((e) => e.lang === "en")
                      .map((name) => <h3 key={property}>{name.name}</h3>)}

                  <span>{formatHumanDate(property.createdAt)}</span>
                  <ul>
                    <li hidden>
                      <img src={unitIcon} alt="Unit" />{" "}
                      {property.unitData.length} {t("strings.unit_t")}
                    </li>
                    <li className="unit">
                      <img src={homeIcon} alt="Unit" /> {property.units}{" "}
                      {t("strings.unit")}
                    </li>
                  </ul>

                  <button type="submit" value="complete" className="complete">
                    {property.isCompleted
                      ? t("propertylist.details", "Details")
                      : t("propertylist.complete_btn", "Complete Property")}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <EmptyList />
          )}
        </div>
        <Modal
          open={visibleOFDelete}
          onOk={handleCancelDelete}
          onCancel={handleCancelDelete}
          wrapClassName="delete-modal"
        >
          <DeletePopup
            dataObject={() => deletePropertyMutation.mutate(currProperty)}
            cancel={() => handleCancelDelete()}
          />
        </Modal>
      </div>

      <CreateProperty
        visible={visible}
        onCancel={handleCancel}
        organizationId={organizationId}
      />
    </Wrapper>
  );
};

export default PropertyList;
