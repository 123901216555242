import * as moment from "moment";

export const formatHumanDate = (dateStr) => {
  return moment(dateStr).format("MMM D, YYYY");
};

export const isSameDate = (dateStr) => {
  return moment(dateStr).isSame(new Date(), "day");
};

export const getFirstOfMonth = (dateStr) => {
  return moment(dateStr).startOf("month").valueOf();
};

export const getEndOfMonth = (dateStr) => {
  return moment(dateStr).endOf("month").valueOf();
};

export const addMonth = (dateStr, number) => {
  return moment(dateStr).add(number, "months").valueOf();
};
