import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { userInstance } from "../../axios/axiosConfig";
import Toaster from "../toaster/toaster";
import "./forget.css";

const Forget = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [notifyType, setNotifyType] = useState();
  const [notifyMsg, setNotifyMsg] = useState();

  const onFinish = async (values) => {
    const response = await userInstance.post("/reset-password", values);
    const statusCode = response.data.code;
    const { msg } = response.data;
    if (statusCode === 200) {
      setNotifyType("success");
      setNotifyMsg(msg);
    } else {
      setNotifyType("error");
      setNotifyMsg(msg);
    }
    form.resetFields();
  };

  const close = () => {
    setNotifyType("");
  };

  return (
    <div className="forget">
      <div className="forget-section">
        <Toaster notifyType={notifyType} notifyMsg={notifyMsg} close={close} />
        <div className="container">
          <div classNmae="row">
            <div className="col-md-12">
              <div className="forget-logo">
                <img src={logo} alt="Logo" />
              </div>
              <div className="forget-form">
                <h1> {t("forget.heading1")}</h1>
                <p>{t("forget.label2")}</p>
                <div className="forget-box">
                  <div>
                    <Form
                      form={form}
                      name="basic"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                    >
                      <Form.Item
                        label={t("forget.label3")}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: t("forget.label5"),
                          },
                          {
                            required: true,
                            message: t("forget.label4"),
                          },
                        ]}
                      >
                        <Input placeholder="me@gmail.com" />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          //   onClick={() => handleMenu(`toggle`)}
                          className="forget-btn"
                          htmlType="submit"
                        >
                          {t("forget.label6")}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="q-links">
                <p>
                  {t("forget.login_label", "Go to Login!")}{" "}
                  <Link to="/">{t("forget.login_btn", "Login")}</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget;
