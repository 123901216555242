import { LockOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./userlock.css";

const UserLock = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleClick = () => {
    history.push("/billing-information");
  };
  return (
    <div className="user-lock">
      <div className="user-lock-content">
        <LockOutlined />
        <p>{t("userlock.label1")}</p>
        <Button onClick={handleClick}>{t("userlock.label2")}</Button>
      </div>
    </div>
  );
};

export default UserLock;
