import { Form, Radio } from "antd";
import PropTypes from "prop-types";
import React from "react";

const GenderSelect = ({ name, label, ...props }) => {
  return (
    <Form.Item name={name} label={label} {...props}>
      <Radio.Group name="radiogroup" defaultValue={1}>
        <Radio value={1}>M</Radio>
        <Radio value={2}>F</Radio>
        <Radio value={3}>Other</Radio>
      </Radio.Group>
    </Form.Item>
  );
};

GenderSelect.propTypes = {
  name: PropTypes.array,
  label: PropTypes.string,
};

GenderSelect.defaultProps = {
  name: "",
  label: "",
};

export default GenderSelect;
