import { userInstance } from "../axios/axiosConfig";

export const verifyHexString = async (hexString) => {
  const res = await userInstance.get(`/verify/${hexString}`);
  return res.data;
};

export const login = async (values) => {
  const res = await userInstance.post("/login", values);
  return res.data;
};

export const logout = async () => {
  const res = await userInstance.post("/logout");
  return res.data;
};

export const getUserData = async () => {
  const res = await userInstance.post("/getUserData");
  return res.data;
};

export const updateBasicInfo = async (data) => {
  const res = await userInstance.post("/updatePersonalInfo", data);
  return res.data;
};

export const updateTimezone = async (data) => {
  const res = await userInstance.post("/updateTimeZone", data);
  return res.data;
};

export const changePassword = async (data) => {
  const res = await userInstance.post("/changePassword", data);
  return res.data;
};
