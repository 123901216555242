import { propertyInstance } from "../axios/axiosConfig";

export const getProperties = async (
  organizationId,
  limit = 20,
  offset = 0,
  q = ""
) => {
  let params = { organizationId, limit, offset };
  if (q && q !== "") {
    params.q = q;
  }

  const res = await propertyInstance.get("/", {
    params,
  });

  return res.data;
};

export const deleteProperty = async (propertyId) => {
  const res = await propertyInstance.post("/deleteProperty", {
    id: propertyId,
  });
  return res.data;
};

export const getServices = async (data) => {
  const res = await propertyInstance.post("/getServices", data);
  return res.data;
};

export const getUnitType = async (data) => {
  const res = await propertyInstance.post("/getUnitType", data);
  return res.data;
};

export const addService = async (data) => {
  const res = await propertyInstance.post("/addService", data);
  return res.data;
};

export const deleteService = async (data) => {
  const res = await propertyInstance.post("/deleteService", data);
  return res.data;
};
