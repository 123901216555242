import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const CreateOrganizationButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Button type="primary" icon={<PlusOutlined />} onClick={onClick}>
      {t("organizations.create_btn", "Create Organization")}
    </Button>
  );
};

export default CreateOrganizationButton;
