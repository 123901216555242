export const StoreKeys = {
  GET_ORGANIZATIONS: "getOrganizations",
  GET_USER_PERMISSIONS: "getUserPermissions",
  GET_PROPERTIES: "getProperties",
  GET_ORGANIZATION: "getOrganization",
  SEARCH_PROPERTIES: "searchProperties",
  GET_BOOKINGS: "getBookings",
  GET_BOOKING: "getBooking",
  GET_GUESTS: "getGuests",
  GET_ME: "getMe",
  GET_SERVICES: "getServices",
  GET_UNIT_TYPE: "getUnitType",
  GET_ORGANIZATION_STATUS: "getOrganizationStatus",
  GET_COMPANIES: "getCompanies",
  GET_INVOICES: "getInvoices",
  GET_ORGANIZATION_MEMBERS: "getOrganizationMembers",
  GET_ORGANIZATION_PERMISSIONS: "getOrganizationPermissions",
};
