import { useQuery } from "@tanstack/react-query";
import { getOrganizationPermissions as getPermissions } from "../api/organizations";
import { StoreKeys } from "../storeKeys";

export const useOrganizationPermissions = (organizationId) => {
  const { data } = useQuery({
    queryKey: [StoreKeys.GET_ORGANIZATION_PERMISSIONS, organizationId],
    queryFn: () => getPermissions(organizationId),
  });

  const getOrganizationPermissions = () => {
    if (data && data.code === 200) {
      return data.featureData;
    }
    return {};
  };

  return { getOrganizationPermissions };
};
