import { PlusOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import propertyPlace from "../../assets/images/property-placeholder.png";

const NoList = ({ canWrite, show }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const enableButton = (
    <Button type="primary" icon={<PlusOutlined />} onClick={show}>
      {t("nolist.button1")}
    </Button>
  );
  const disableButton = (
    <Tooltip title={t("nolist.tooltip1")} color="gold">
      <Button
        disabled
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => history.push("/addproperty")}
      >
        {t("nolist.button1")}
      </Button>
    </Tooltip>
  );
  const buttonCreateProperty = canWrite === 1 ? enableButton : disableButton;

  return (
    <div className="add-team-page">
      <div className="add-subuser">
        <img src={propertyPlace} alt="subuser" />
        <h4>{t("strings.property")}</h4>
        <p>{t("nolist.heading1")}</p>
        {buttonCreateProperty}
      </div>
    </div>
  );
};

NoList.propTypes = {
  canWrite: PropTypes.bool,
  show: PropTypes.func,
};
NoList.defaultProps = {
  canWrite: true,
  show: () => {},
};
export default NoList;
