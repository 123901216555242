import "./property.css";

import { CloseOutlined, DownOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Select,
} from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CounterInput from "react-counter-input";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { propertyInstance, userInstance } from "../../axios/axiosConfig";
import languageAvailable from "../../config/language";
import translate from "../../config/translation";
import { rentalTypeOptions } from "../../utils/rentalTypeUtils";
import { TIME_ZONE_LIST } from "../../utils/timeZone";
import Meta from "../meta";
import Wrapper from "../wrapper";
import EditAmenities from "./EditAmenities";

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

const images = importAll(
  require.context("../../assets/images/flags/", false, /\.(png|jpe?g|svg)$/)
);

const Overview = () => {
  const { organizationId, unitTypeId } = useParams();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [isShowRooms, setIsShowRooms] = useState(false);
  const [isShowAmenities, setIsShowAmenities] = useState(false);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [propertyName, setPropertyName] = useState("");
  const [propertyDescription, setPropertyDescription] = useState("");
  const [noOfBedRooms, setNoOfBedRooms] = useState(0);
  const [noOfGuests, setNoOfGuests] = useState(0);
  const [noOfUnits, setNoOfUnits] = useState(1);
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const [showAdd, setShowAdd] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [languageAction, setLanguageAction] = useState("select");
  const [languageSelection, setLanguageSelection] = useState({});
  const [languageList, setLanguageList] = useState([]);
  const [languageToFilter, setLanguageToFilter] = useState("");
  const [languageSelected, setLanguageSelected] = useState("en");
  const [languageCount, setLanguageCount] = useState(1);
  const [displayLang, setDisplayLang] = useState("English");
  const [translateLanguage, setTranslateLanguage] = useState([]);
  const [unitsPanel, setUnitsPanel] = useState([
    {
      unitName: "unit 1",
    },
  ]);

  const refSelect = useRef(null);
  const [deletedUnitsId, setDeletedUnitsId] = useState([]);
  const { t } = useTranslation();

  const showEditSleeping = () => {
    setVisible(true);
  };

  const showSleepArrangement = () => {
    setIsShowRooms(true);
  };

  const showAmenitiesPanel = () => {
    setIsShowAmenities(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancel1 = () => {
    setIsShowRooms(false);
  };

  const handleCancel2 = () => {
    setIsShowAmenities(false);
  };

  const handleFinish = async () => {
    // console.log(values);
  };

  const getData = useCallback(async () => {
    const response = await propertyInstance.post("/fetchUnitTypeData", {
      unitTypeId: unitTypeId,
    });

    if (response.data.code === 200 && response.status !== 204) {
      const data = response.data.unitType;
      if (data && data.unitData.length) {
        setUnitsPanel(data.unitData);
        setNoOfUnits(data.unitData.length);
        data.unitData.forEach((el, i) => {
          form2.setFieldsValue({
            [`unit${i}`]: el.unitName,
          });
        });
      }

      if (data.propertyType !== null) {
        form.setFieldsValue({
          propertyType: data.propertyType,
        });
      }

      if (data.timeZone) {
        form.setFieldsValue({
          timeZone: data.timeZone,
        });
      }

      if (data.sizeValue > 0) {
        form2.setFieldsValue({
          sqSelectedValue: data.sizeType,
          sqNumber: data.sizeValue,
        });
      }
      setNoOfBedRooms(data.bedRooms);
      setNoOfGuests(data.standardGuests);
      setSelectedAmenities(data.amenities);
    }
  }, [form, form2]);

  useEffect(() => {
    const getAmenities = async () => {
      const res = await userInstance.post("/getAmenities", {
        unitTypeId,
      });
      if (res.data.code === 200) {
        setAmenitiesList(res.data.amenities);
      }
    };

    const getPropertyName = async () => {
      const response = await propertyInstance.get(
        `/fetchTranslated/en/${unitTypeId}`
      );
      if (response.status === 200) {
        const { filteredName, filteredDescription } = response.data;
        const name =
          typeof filteredName !== "undefined" && filteredName.length > 0
            ? filteredName[0].name
            : "";
        const description =
          typeof filteredDescription !== "undefined" &&
          filteredDescription.length > 0
            ? filteredDescription[0].description
            : "";
        if (name || description) {
          setPropertyName(name);
          setPropertyDescription(description);
          form.setFieldsValue({
            name,
            description,
          });
        }
      }
    };
    getPropertyName();
    getAmenities();
    getData();
  }, [form, getData]);

  useEffect(() => {
    async function fetchData() {
      await getLanguages();
    }
    fetchData();
  }, []);

  const { TextArea } = Input;
  const handleOverview = async () => {
    if (propertyName && propertyDescription) {
      const engPropertyName = await translate(propertyName, "en");
      const payload = {
        propertyName: engPropertyName.trim(),
        propertyDescription: propertyDescription.trim(),
        unitTypeId,
        languageSelected,
      };
      const res = await propertyInstance.post(
        "/updateUnitTypeOverview",
        payload
      );
      if (res.data.code === 200) {
        toast.success("Data updates successfully", {
          containerId: "B",
          toastId: "AV",
        });
      }
    } else {
      if (!propertyName) {
        toast.error("Property name can not be empty", {
          containerId: "B",
          toastId: "B",
        });
      }
      toast.error("Please enter property description", {
        containerId: "B",
        toastId: "B",
      });
    }
  };

  const handleChangeTimeZone = async (value) => {
    const payload = {
      timeZone: value,
      unitTypeId,
    };
    const res = await propertyInstance.post("/changeTimezone", payload);
    if (res.data.code === 200) {
      toast.success("Data updated successfully");
    }
  };

  const handleRentalTypeSelect = async (value) => {
    const payload = {
      propertyType: value,
      unitTypeId,
    };
    const res = await propertyInstance.post("/changeRentalType", payload);
    if (res.data.code === 200) {
      toast.success("Data updated successfully");
    }
  };

  const onFinishPropertyInfo = async (values) => {
    values.unitTypeId = unitTypeId;
    values.noOfBedRooms = noOfBedRooms;
    values.noOfGuests = noOfGuests;
    values.noOfUnits = noOfUnits;
    values.unitsData = unitsPanel;
    values.deletedUnitArray = deletedUnitsId;
    const response = await propertyInstance.post("/updatePropertyInfo", values);
    if (response.data.code === 200) {
      getData();
      toast.success("Changes have been saved", {
        containerId: "B",
        toastId: "B",
      });
    }
  };

  const handleAddLanguage = async () => {
    const language = languageSelection;
    const res = await propertyInstance.post("/addLanguage", {
      unitTypeId,
      language,
    });
    if (res.status === 200) {
      getLanguages();
      toast.success(res.data.msg, {
        containerId: "B",
        toastId: "B",
      });
      setLanguageSelection([]);
    } else {
      toast.success(res.data.msg, {
        containerId: "B",
        toastId: "B",
      });
    }
    setShowAdd(false);
  };

  const getLanguages = async () => {
    const res = await propertyInstance.get(`/languages/${unitTypeId}`);
    if (res.status === 200) {
      setLanguageCount(res.data.language.length);
      setLanguageList([res.data.language]);
    }
  };

  const handleRemoveLanguage = async () => {
    const language = languageSelection;
    const res = await propertyInstance.post("/removeLanguage", {
      unitTypeId,
      language,
      filterLang: languageToFilter,
    });
    if (res.status === 200) {
      getLanguages();
      toast.success(res.data.msg, {
        containerId: "B",
        toastId: "B",
      });
      setLanguageSelection([]);
    } else {
      toast.error(res.data.msg, {
        containerId: "B",
        toastId: "B",
      });
    }
    setShowRemove(false);
  };

  const setTranslate = async (e) => {
    // console.log('language', languageList);
    // console.log('e', e);
    const filteredLang = languageList[0].filter(
      (el) => Object.keys(el)[0] !== e
    );
    setTranslateLanguage(filteredLang);
    const propertyId = unitTypeId;
    const response = await propertyInstance.get(
      `/fetchTranslated/${e}/${propertyId}`
    );
    if (response) {
      const {
        data: { filteredDescription, filteredName },
      } = response;
      const name = filteredName.length > 0 ? filteredName[0].name : "";
      const description =
        filteredDescription.length > 0
          ? filteredDescription[0].description
          : "";
      setPropertyDescription(description);
      setPropertyName(name);
      form.setFieldsValue({
        name,
        description,
      });
    }
  };

  const handleReset = ({ current }) => {
    current.selectedIndex = "0";
  };

  useEffect(() => {
    if (languageAction.languageAction === "add") setShowAdd(true);
    else if (languageAction.languageAction === "remove") setShowRemove(true);
  }, [languageAction]);

  /**
   * New units handling functions
   */
  const onUnitChange = (e, el, i) => {
    if (el.id) {
      unitsPanel.forEach((unit) => {
        if (unit.id === el.id) {
          unit.unitName = e.target.value;
        }
      });
      setUnitsPanel(unitsPanel);
    } else {
      unitsPanel.forEach((unit, j) => {
        if (i === j) {
          unit.unitName = e.target.value;
        }
      });
      setUnitsPanel(unitsPanel);
    }
  };
  /**
   * increasing unit boxes
   */
  const createUnits = () => {
    setNoOfUnits(noOfUnits + 1);
    // setUnitsArr(Array.from(Array(value).keys()));
    const oldArray = [...unitsPanel];
    oldArray.push({
      unitName: `unit ${noOfUnits + 1}`,
    });
    setUnitsPanel(oldArray);
  };

  /**
   * Descreasing unit boxes
   */
  const removeUnitBox = () => {
    const nUnits = noOfUnits - 1;
    if (nUnits < 1) {
      return;
    }

    setNoOfUnits(noOfUnits - 1);
    const oldArray = [...unitsPanel];
    const removedUnit = oldArray.pop();
    if (removedUnit.id) {
      const deletedUnitArray = [...deletedUnitsId];
      deletedUnitArray.push(removedUnit.id);
      setDeletedUnitsId(deletedUnitArray);
    }
    setUnitsPanel(oldArray);
  };
  const negativeCheck = (e) => {
    if (e.keyCode === 109) {
      e.preventDefault();
    }
  };

  function handleMenuClick(e) {
    setLanguageSelected(e.key);
    setDisplayLang(e.item.props["data-value"][0]);
    setTranslate(e.key);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      {languageList[0] &&
        languageList[0].map((el) => (
          <Menu.Item key={Object.keys(el)[0]} data-value={Object.values(el)}>
            <img
              src={`${images[`${Object.keys(el)[0]}.png`]}`}
              width="20px"
              height="20px"
              alt="country"
            />{" "}
            {Object.values(el)}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Wrapper organizationId={organizationId}>
      <Meta>
        <body className="overview-page-view" />
      </Meta>

      <div className="overview overview-space">
        <Row>
          <Col span={24}>
            <div className="overview-content">
              <Form form={form} onFinish={handleFinish}>
                {/* Overview */}
                <div className="overview-first-section">
                  <h3>{t("overview.heading1")}</h3>

                  <div className="overview-flex">
                    <div className="overview-input">
                      <Dropdown overlay={menu}>
                        <Button>
                          {languageSelected !== "" ? (
                            <>
                              <img
                                src={`${images[`${languageSelected}.png`]}`}
                                width="20px"
                                height="20px"
                                alt="country"
                              />{" "}
                              {displayLang}
                            </>
                          ) : (
                            "Select Language"
                          )}{" "}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </div>

                    <div className="overview-input">
                      <select
                        name="languageAction"
                        onChange={(e) => {
                          setLanguageAction({
                            ...languageAction,
                            [e.target.name]: e.target.value,
                          });
                          handleReset(refSelect);
                        }}
                        defaultValue={languageAction}
                        ref={refSelect}
                      >
                        <option value="select">
                          {t("overview.languages", "Languages")}
                        </option>
                        <option value="add">
                          {t("overview.add_language", "Add Language")}
                        </option>
                        <option value="remove">
                          {t("overview.remove_language", "Remove Language")}
                        </option>
                      </select>
                    </div>
                  </div>

                  <Row>
                    <Col span={24}>
                      <Form.Item name="name">
                        <Input
                          value={propertyName}
                          onChange={(e) => setPropertyName(e.target.value)}
                          placeholder={t("overview.placeholder1")}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item name="description">
                        <TextArea
                          value={propertyDescription}
                          placeholder="Description"
                          rows={4}
                          onChange={(e) =>
                            setPropertyDescription(e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Button onClick={handleOverview}>
                        {t("strings.save", "Save")}
                      </Button>
                    </Col>
                  </Row>
                </div>

                {/* Timezone Listing */}
                <div className="overview-second-section">
                  <h3>{t("overview.timezone")}</h3>
                  <Row>
                    <Col span={12}>
                      <Form.Item name="timeZone">
                        <Select
                          placeholder={t("overview.placeholder3")}
                          onSelect={handleChangeTimeZone}
                        >
                          {TIME_ZONE_LIST.map((item) => {
                            return (
                              <Select.Option
                                key={item.value}
                                value={item.value}
                              >
                                {item.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                {/* Rental Type */}
                <div className="overview-second-section">
                  <h3>{t("overview.heading2")}</h3>
                  <Row>
                    <Col span={12}>
                      <Form.Item name="propertyType">
                        <Select
                          placeholder={t("overview.placeholder3")}
                          onSelect={handleRentalTypeSelect}
                        >
                          {rentalTypeOptions(t).map((item) => {
                            return (
                              <Select.Option key={item.key} value={item.key}>
                                {item.value}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                {/* Property Info */}
                <div className="overview-third-section">
                  <Form form={form2} onFinish={onFinishPropertyInfo}>
                    <h3>{t("overview.heading3")}</h3>
                    <p>{t("overview.paragraph1")}</p>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          className="property-info-unit"
                          name="sqSelectedValue"
                          rules={[
                            {
                              required: true,
                              message: "Please select the type",
                            },
                          ]}
                          label={t("overview.label1")}
                        >
                          <Select placeholder="SQ MT">
                            <Select.Option value="SQ MT">
                              {t("overview.option21")}
                            </Select.Option>
                            <Select.Option value="SQ FT">
                              {t("overview.option20")}
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="property-info-unit"
                          name="sqNumber"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter the value",
                            },
                          ]}
                        >
                          <Input placeholder="0.00" onKeyDown={negativeCheck} />
                        </Form.Item>

                        <Form.Item label={t("overview.label4")}>
                          <div className="input-counter">
                            <CounterInput
                              min={0}
                              max={30}
                              count={noOfBedRooms}
                              onCountChange={(count) => setNoOfBedRooms(count)}
                            />
                          </div>
                        </Form.Item>

                        <Form.Item label={t("overview.label2")}>
                          <div className="input-counter">
                            <CounterInput
                              min={0}
                              max={200}
                              count={noOfGuests}
                              onCountChange={(count) => setNoOfGuests(count)}
                            />
                          </div>
                        </Form.Item>

                        <Form.Item label={t("overview.label3")}>
                          <div className="input-counter">
                            <div className="counter-input-box">
                              <Button onClick={removeUnitBox}>-</Button>
                              <span>{noOfUnits}</span>
                              <Button onClick={createUnits}>+</Button>
                            </div>
                          </div>
                        </Form.Item>
                        {unitsPanel.map((el, i) => (
                          <Form.Item
                            key={i}
                            className="property-info-unit"
                            label={t("overview.unit_name", "Units Name")}
                            name={`unit${i}`}
                          >
                            <Input
                              onChange={(e) => onUnitChange(e, el, i)}
                              placeholder={el.unitName}
                            />
                          </Form.Item>
                        ))}
                      </Col>
                    </Row>
                    <div>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!(noOfUnits > 0 && noOfGuests > 0)}
                      >
                        {t("overview.button1")}
                      </Button>
                    </div>
                  </Form>
                </div>

                {/* Sleep Arrange */}
                <div className="overview-fourth-section">
                  <h3>{t("overview.heading4")}</h3>
                  <p>{t("overview.paragraph2")}</p>
                  <Button onClick={() => showEditSleeping()}>
                    {t("overview.button2")}
                  </Button>
                </div>

                <div className="overview-fifth-section">
                  <h3>{t("overview.heading5")}</h3>
                  <p>
                    {t("overview.paragraph3")}
                    <span>&apos;</span>
                    {t("overview.paragraph4")}
                    <span>&apos;</span>
                    {t("overview.paragraph5")}
                  </p>
                  <Button onClick={() => showSleepArrangement()}>
                    {" "}
                    {t("overview.button3")}
                  </Button>
                </div>

                <div className="overview-sixth-section">
                  <h3>{t("overview.heading6")}</h3>
                  <p>{t("overview.paragraph6")}</p>
                  <Button onClick={() => showAmenitiesPanel()}>
                    {t("overview.button4")}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        title={t("overview.title1")}
        open={visible}
        onOk={handleCancel}
        onCancel={handleCancel}
        wrapClassName="right-modal"
      >
        <Helmet>
          <body className="ant-scrolling-effect" />
        </Helmet>
        <div className="cross-btn">
          <CloseOutlined onClick={handleCancel} />
        </div>
        <SleepingArrangement handleCancel={handleCancel} />
      </Modal>

      <Modal
        title={t("overview.title2")}
        open={isShowRooms}
        onOk={handleCancel1}
        onCancel={handleCancel1}
        wrapClassName="right-modal"
      >
        <Helmet>
          <body className="ant-scrolling-effect" />
        </Helmet>
        <div className="cross-btn">
          <CloseOutlined onClick={handleCancel1} />
        </div>

        <EditRooms handleCancel1={handleCancel1} />
      </Modal>

      <Modal
        title={t("overview.title3")}
        open={isShowAmenities}
        onOk={handleCancel2}
        onCancel={handleCancel2}
        wrapClassName="right-modal"
      >
        <Helmet>
          <body className="ant-scrolling-effect" />
        </Helmet>
        <div className="cross-btn">
          <CloseOutlined onClick={handleCancel2} />
        </div>

        <EditAmenities
          amenitiesList={amenitiesList}
          handleCancel2={handleCancel2}
          selectedAmenities={selectedAmenities}
          unitTypeId={unitTypeId}
        />
      </Modal>

      <Modal
        title={t("overview.add_language_btn", "Add Language")}
        open={showAdd}
        cancelButtonProps={{ className: "border-btn" }}
        onOk={handleAddLanguage}
        onCancel={() => setShowAdd(false)}
        okText={t("strings.ok", "Ok")}
        cancelText={t("strings.cancel", "Cancel")}
      >
        <div className="overview-input">
          <select
            name="languageSelection"
            onChange={(e) => {
              setLanguageSelection({
                [e.target.value]: e.target.selectedOptions[0].text,
              });
            }}
          >
            {languageAvailable &&
              languageAvailable.map((lang) => (
                <option key={lang.langCode} value={lang.langCode}>
                  {lang.name}
                </option>
              ))}
          </select>
        </div>
      </Modal>

      <Modal
        title={t("overview.remove_language", "Remove Language")}
        open={showRemove}
        onOk={handleRemoveLanguage}
        cancelButtonProps={{ className: "border-btn" }}
        onCancel={() => setShowRemove(false)}
        okText={t("strings.ok", "Ok")}
        cancelText={t("strings.cancel", "Cancel")}
      >
        <div className="overview-input">
          <select
            name="languageSelection"
            onChange={(e) => {
              setLanguageSelection({
                [e.target.value]: e.target.selectedOptions[0].text,
              });
              setLanguageToFilter(e.target.value);
            }}
          >
            {languageList[0] &&
              languageList[0].map((el) => (
                <option key={Object.keys(el)[0]} value={Object.keys(el)[0]}>
                  {Object.values(el)}
                </option>
              ))}
          </select>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Overview;

const SleepingArrangement = ({ handleCancel }) => {
  const [form] = Form.useForm();
  const { unitTypeId } = useParams();
  const [babyCrib, setBabyCrib] = useState(0);
  const [childBed, setChildBed] = useState(0);
  const [doubleBed, setDoubleBed] = useState(0);
  const [foldAwayBed, setFoldAwayBed] = useState(0);
  const [kingSizeBed, setKingSizeBed] = useState(0);
  const [loftBed, setLoftBed] = useState(0);
  const [queenSizeBed, setQueenSizeBed] = useState(0);
  const [sofaBed, setSofaBed] = useState(0);
  const [singleBed, setSingleBed] = useState(0);
  const { t } = useTranslation();
  const getData = async () => {
    const response = await propertyInstance.post("/fetchUnitTypeData", {
      unitTypeId,
    });
    if (response.data.code === 200) {
      const dataRes = response.data;
      if (dataRes && dataRes.unitType) {
        const data = dataRes.unitType;
        if (data.sleepingArrangement !== null) {
          setBabyCrib(data.sleepingArrangement.babyCrib);
          setChildBed(data.sleepingArrangement.childBed);
          setDoubleBed(data.sleepingArrangement.doubleBed);
          setFoldAwayBed(data.sleepingArrangement.foldAwayBed);
          setKingSizeBed(data.sleepingArrangement.kingSizeBed);
          setLoftBed(data.sleepingArrangement.loftBed);
          setQueenSizeBed(data.sleepingArrangement.queenSizeBed);
          setSofaBed(data.sleepingArrangement.sofaBed);
          setSingleBed(data.sleepingArrangement.singleBed);
        }
      }
    }
  };

  const onFinishSleeping = async (values) => {
    const obj = {
      babyCrib,
      childBed,
      doubleBed,
      foldAwayBed,
      kingSizeBed,
      loftBed,
      queenSizeBed,
      sofaBed,
      singleBed,
    };
    values.unitTypeId = unitTypeId;
    values.sleepingArrangement = JSON.stringify(obj);
    const response = await propertyInstance.post(
      "/updateSleepingArrangement",
      values
    );
    if (response.data.code === 200) {
      getData();
      handleCancel();
      toast.success("Changes have been saved", {
        containerId: "B",
        toastId: "B",
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Form form={form} onFinish={onFinishSleeping}>
      <h3>{t("overview.heading7")}</h3>
      <p>{t("overview.paragraph7")}</p>
      <div className="property-selection">
        <div className="icon-name">
          <InboxOutlined />
          <span>{t("overview.span1")}</span>
        </div>
        <div className="input-counter">
          <CounterInput
            min={0}
            max={10}
            count={babyCrib}
            onCountChange={(count) => setBabyCrib(count)}
          />
        </div>
      </div>

      <div className="property-selection">
        <div className="icon-name">
          <InboxOutlined />
          <span>{t("overview.span2")}</span>
        </div>
        <div className="input-counter">
          <CounterInput
            min={0}
            max={10}
            count={childBed}
            onCountChange={(count) => setChildBed(count)}
          />
        </div>
      </div>

      <div className="property-selection">
        <div className="icon-name">
          <InboxOutlined />
          <span>{t("overview.span3")}</span>
        </div>
        <div className="input-counter">
          <CounterInput
            min={0}
            max={10}
            count={doubleBed}
            onCountChange={(count) => setDoubleBed(count)}
          />
        </div>
      </div>

      <div className="property-selection">
        <div className="icon-name">
          <InboxOutlined />
          <span>{t("overview.span4")}</span>
        </div>
        <div className="input-counter">
          <CounterInput
            min={0}
            max={10}
            count={foldAwayBed}
            onCountChange={(count) => setFoldAwayBed(count)}
          />
        </div>
      </div>

      <div className="property-selection">
        <div className="icon-name">
          <InboxOutlined />
          <span>{t("overview.span5")}</span>
        </div>
        <div className="input-counter">
          <CounterInput
            min={0}
            max={10}
            count={kingSizeBed}
            onCountChange={(count) => setKingSizeBed(count)}
          />
        </div>
      </div>

      <div className="property-selection">
        <div className="icon-name">
          <InboxOutlined />
          <span>{t("overview.span6")}</span>
        </div>
        <div className="input-counter">
          <CounterInput
            min={0}
            max={10}
            count={loftBed}
            onCountChange={(count) => setLoftBed(count)}
          />
        </div>
      </div>

      <div className="property-selection">
        <div className="icon-name">
          <InboxOutlined />
          <span>{t("overview.span7")}</span>
        </div>
        <div className="input-counter">
          <CounterInput
            min={0}
            max={10}
            count={queenSizeBed}
            onCountChange={(count) => setQueenSizeBed(count)}
          />
        </div>
      </div>

      <div className="property-selection">
        <div className="icon-name">
          <InboxOutlined />
          <span>{t("overview.span8")}</span>
        </div>
        <div className="input-counter">
          <CounterInput
            min={0}
            max={10}
            count={sofaBed}
            onCountChange={(count) => setSofaBed(count)}
          />
        </div>
      </div>

      <div className="property-selection">
        <div className="icon-name">
          <InboxOutlined />
          <span>{t("overview.span9")}</span>
        </div>
        <div className="input-counter">
          <CounterInput
            min={0}
            max={10}
            count={singleBed}
            onCountChange={(count) => setSingleBed(count)}
          />
        </div>
      </div>

      <div className="property-btns">
        <Button onClick={handleCancel} className="border-btn">
          {t("overview.button5")}
        </Button>
        <Button type="primary" htmlType="submit">
          {t("overview.button6")}
        </Button>
      </div>
    </Form>
  );
};

SleepingArrangement.propTypes = {
  handleCancel: PropTypes.func,
};
SleepingArrangement.defaultProps = {
  handleCancel: () => {},
};

const EditRooms = ({ handleCancel1 }) => {
  const { unitTypeId } = useParams();
  const [form] = Form.useForm();
  const [nav, setNav] = useState(false);
  const handleMenu = (e) => {
    if (e === "open") {
      setNav(true);
    } else if (e === "close") {
      setNav(false);
    } else if (e === "toggle") {
      setNav(!nav);
    }
  };

  const [balconyPrivate, setBalconyPrivate] = useState(0);
  const [bathroomPrivate, setBathroomPrivate] = useState(0);
  const [bedroomPrivate, setBedroomPrivate] = useState(0);
  const [diningRoomPrivate, setDiningRoomPrivate] = useState(0);
  const [kitchenPrivate, setKitchenPrivate] = useState(0);
  const [livingRoomPrivate, setLivingRoomPrivate] = useState(0);
  const [playroomPrivate, setPlayroomPrivate] = useState(0);
  const [terracePrivate, setTerracePrivate] = useState(0);
  const [toiletPrivate, setToiletPrivate] = useState(0);
  const [workroomPrivate, setWorkroomPrivate] = useState(0);

  const [balconyShared, setBalconyShared] = useState(0);
  const [bathroomShared, setBathroomShared] = useState(0);
  const [bedroomShared, setBedroomShared] = useState(0);
  const [diningRoomShared, setDiningRoomShared] = useState(0);
  const [kitchenShared, setKitchenShared] = useState(0);
  const [livingRoomShared, setLivingRoomShared] = useState(0);
  const [playroomShared, setPlayroomShared] = useState(0);
  const [terraceShared, setTerraceShared] = useState(0);
  const [toiletShared, setToiletShared] = useState(0);
  const [workroomShared, setWorkRoomShared] = useState(0);

  const { t } = useTranslation();
  const getData = async () => {
    const response = await propertyInstance.post("/fetchUnitTypeData", {
      unitTypeId,
    });
    if (response.data.code === 200) {
      const data = response.data.unitType;
      if (data.rooms !== null) {
        setBalconyPrivate(data.rooms.balconyPrivate);
        setBathroomPrivate(data.rooms.bathroomPrivate);
        setBedroomPrivate(data.rooms.bedroomPrivate);
        setDiningRoomPrivate(data.rooms.diningRoomPrivate);
        setKitchenPrivate(data.rooms.kitchenPrivate);
        setLivingRoomPrivate(data.rooms.livingRoomPrivate);
        setPlayroomPrivate(data.rooms.playRoomPrivate);
        setToiletPrivate(data.rooms.toiletPrivate);
        setWorkroomPrivate(data.rooms.workRoomPrivate);
        setBalconyShared(data.rooms.balconyShared);
        setBathroomShared(data.rooms.bathroomShared);
        setBedroomShared(data.rooms.bedroomShared);
        setDiningRoomShared(data.rooms.diningRoomShared);
        setKitchenShared(data.rooms.kitchenShared);
        setLivingRoomShared(data.rooms.livingRoomShared);
        setPlayroomShared(data.rooms.playroomShared);
        setToiletShared(data.rooms.toiletShared);
        setWorkRoomShared(data.rooms.workroomShared);
      }
    }
  };

  const onFinishEditRoom = async (values) => {
    const obj = {
      balconyPrivate,
      bathroomPrivate,
      bedroomPrivate,
      diningRoomPrivate,
      kitchenPrivate,
      livingRoomPrivate,
      playroomPrivate,
      terracePrivate,
      toiletPrivate,
      workroomPrivate,

      balconyShared,
      bathroomShared,
      bedroomShared,
      diningRoomShared,
      kitchenShared,
      livingRoomShared,
      playroomShared,
      terraceShared,
      toiletShared,
      workroomShared,
    };
    values.unitTypeId = unitTypeId;
    values.rooms = JSON.stringify(obj);
    const response = await propertyInstance.post("/updateEditRooms", values);
    if (response.data.code === 200) {
      getData();
      handleCancel1();
      toast.success("Changes have been saved", {
        containerId: "B",
        toastId: "B",
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Form form={form} onFinish={onFinishEditRoom}>
      <h3>{t("overview.heading8")}</h3>
      <p>
        {t(
          "overview.edit_room_desc",
          "What other rooms does your property have? Whichever rooms you choose here will be shown on your website."
        )}
      </p>

      <div className="edit-room-content">
        <h5>{t("overview.heading9")}</h5>
        <div className="property-selection">
          <div className="icon-name">
            <InboxOutlined />
            <span>{t("overview.span10")}</span>
          </div>
          <div className="input-counter">
            <CounterInput
              min={0}
              max={10}
              count={balconyPrivate}
              onCountChange={(count) => setBalconyPrivate(count)}
            />
          </div>
        </div>

        <div className="property-selection">
          <div className="icon-name">
            <InboxOutlined />
            <span>{t("overview.span11")}</span>
          </div>
          <div className="input-counter">
            <CounterInput
              min={0}
              max={10}
              count={bathroomPrivate}
              onCountChange={(count) => setBathroomPrivate(count)}
            />
          </div>
        </div>

        <div className="property-selection">
          <div className="icon-name">
            <InboxOutlined />
            <span>{t("overview.span12")}</span>
          </div>
          <div className="input-counter">
            <CounterInput
              min={0}
              max={10}
              count={bedroomPrivate}
              onCountChange={(count) => setBedroomPrivate(count)}
            />
          </div>
        </div>

        <div className="property-selection">
          <div className="icon-name">
            <InboxOutlined />
            <span>{t("overview.span13")}</span>
          </div>
          <div className="input-counter">
            <CounterInput
              min={0}
              max={10}
              count={diningRoomPrivate}
              onCountChange={(count) => setDiningRoomPrivate(count)}
            />
          </div>
        </div>

        <div className="property-selection">
          <div className="icon-name">
            <InboxOutlined />
            <span>{t("overview.span14")}</span>
          </div>
          <div className="input-counter">
            <CounterInput
              min={0}
              max={10}
              count={kitchenPrivate}
              onCountChange={(count) => setKitchenPrivate(count)}
            />
          </div>
        </div>

        <div className="property-selection">
          <div className="icon-name">
            <InboxOutlined />
            <span>{t("overview.span15")}</span>
          </div>
          <div className="input-counter">
            <CounterInput
              min={0}
              max={10}
              count={livingRoomPrivate}
              onCountChange={(count) => setLivingRoomPrivate(count)}
            />
          </div>
        </div>

        <div className="property-selection">
          <div className="icon-name">
            <InboxOutlined />
            <span>{t("overview.span16")}</span>
          </div>
          <div className="input-counter">
            <CounterInput
              min={0}
              max={10}
              count={playroomPrivate}
              onCountChange={(count) => setPlayroomPrivate(count)}
            />
          </div>
        </div>

        <div className="property-selection">
          <div className="icon-name">
            <InboxOutlined />
            <span>{t("overview.span17")}</span>
          </div>
          <div className="input-counter">
            <CounterInput
              min={0}
              max={10}
              count={terracePrivate}
              onCountChange={(count) => setTerracePrivate(count)}
            />
          </div>
        </div>

        <div className="property-selection">
          <div className="icon-name">
            <InboxOutlined />
            <span>{t("overview.span18")}</span>
          </div>
          <div className="input-counter">
            <CounterInput
              min={0}
              max={10}
              count={toiletPrivate}
              onCountChange={(count) => setToiletPrivate(count)}
            />
          </div>
        </div>

        <div className="property-selection">
          <div className="icon-name">
            <InboxOutlined />
            <span>{t("overview.span19")}</span>
          </div>
          <div className="input-counter">
            <CounterInput
              min={0}
              max={10}
              count={workroomPrivate}
              onCountChange={(count) => setWorkroomPrivate(count)}
            />
          </div>
        </div>

        <div className={`more-content ${nav ? "show" : ""}`}>
          <h5>{t("overview.heading10")}</h5>
          <div className="property-selection">
            <div className="icon-name">
              <InboxOutlined />
              <span>{t("overview.span20")}</span>
            </div>
            <div className="input-counter">
              <CounterInput
                min={0}
                max={10}
                count={balconyShared}
                onCountChange={(count) => setBalconyShared(count)}
              />
            </div>
          </div>

          <div className="property-selection">
            <div className="icon-name">
              <InboxOutlined />
              <span>{t("overview.span21")}</span>
            </div>
            <div className="input-counter">
              <CounterInput
                min={0}
                max={10}
                count={bathroomShared}
                onCountChange={(count) => setBathroomShared(count)}
              />
            </div>
          </div>

          <div className="property-selection">
            <div className="icon-name">
              <InboxOutlined />
              <span>{t("overview.span22")}</span>
            </div>
            <div className="input-counter">
              <CounterInput
                min={0}
                max={10}
                count={bedroomShared}
                onCountChange={(count) => setBedroomShared(count)}
              />
            </div>
          </div>

          <div className="property-selection">
            <div className="icon-name">
              <InboxOutlined />
              <span>{t("overview.span23")}</span>
            </div>
            <div className="input-counter">
              <CounterInput
                min={0}
                max={10}
                count={diningRoomShared}
                onCountChange={(count) => setDiningRoomShared(count)}
              />
            </div>
          </div>

          <div className="property-selection">
            <div className="icon-name">
              <InboxOutlined />
              <span>{t("overview.span24")}</span>
            </div>
            <div className="input-counter">
              <CounterInput
                min={0}
                max={10}
                count={kitchenShared}
                onCountChange={(count) => setKitchenShared(count)}
              />
            </div>
          </div>

          <div className="property-selection">
            <div className="icon-name">
              <InboxOutlined />
              <span>{t("overview.span25")}</span>
            </div>
            <div className="input-counter">
              <CounterInput
                min={0}
                max={10}
                count={livingRoomShared}
                onCountChange={(count) => setLivingRoomShared(count)}
              />
            </div>
          </div>

          <div className="property-selection">
            <div className="icon-name">
              <InboxOutlined />
              <span>{t("overview.span26")}</span>
            </div>
            <div className="input-counter">
              <CounterInput
                min={0}
                max={10}
                count={playroomShared}
                onCountChange={(count) => setPlayroomShared(count)}
              />
            </div>
          </div>

          <div className="property-selection">
            <div className="icon-name">
              <InboxOutlined />
              <span>{t("overview.span27")}</span>
            </div>
            <div className="input-counter">
              <CounterInput
                min={0}
                max={10}
                count={terraceShared}
                onCountChange={(count) => setTerraceShared(count)}
              />
            </div>
          </div>

          <div className="property-selection">
            <div className="icon-name">
              <InboxOutlined />
              <span>{t("overview.span28")}</span>
            </div>
            <div className="input-counter">
              <CounterInput
                min={0}
                max={10}
                count={toiletShared}
                onCountChange={(count) => setToiletShared(count)}
              />
            </div>
          </div>

          <div className="property-selection">
            <div className="icon-name">
              <InboxOutlined />
              <span>{t("overview.span29")}</span>
            </div>
            <div className="input-counter">
              <CounterInput
                min={0}
                max={10}
                count={workroomShared}
                onCountChange={(count) => setWorkRoomShared(count)}
              />
            </div>
          </div>
        </div>

        <span
          className="view-more"
          onClick={() => handleMenu("toggle")}
          role="presentation"
        >
          {` ${
            nav
              ? t("overview.view_less", "View less")
              : t("overview.view_more", "View more")
          }`}
        </span>
      </div>

      <div className="property-btns">
        <Button onClick={handleCancel1} className="border-btn">
          {t("overview.button7")}
        </Button>
        <Button type="primary" htmlType="submit">
          {t("overview.button8")}
        </Button>
      </div>
    </Form>
  );
};

EditRooms.propTypes = {
  handleCancel1: PropTypes.func,
};
EditRooms.defaultProps = {
  handleCancel1: () => {},
};
