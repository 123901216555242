import { VerticalAlignMiddleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Dropdown, Layout, Menu } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { getOrganizations } from "../../../api/organizations";
import mblLogo from "../../../assets/images/logo-mobile.png";
import menuIcon from "../../../assets/images/menu/menu-icon.png";
import propertyMbl from "../../../assets/images/property-mbl.png";
import { StoreKeys } from "../../../storeKeys";

const { Header } = Layout;

const TopHeader = ({ fun, setMenuToggle, menuToggle, show }) => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const history = useHistory();

  const [organizationName, setOrganizationName] = useState();
  const [menu, setMenu] = useState();

  const { data } = useQuery([StoreKeys.GET_ORGANIZATIONS], () =>
    getOrganizations(30, 0)
  );

  useEffect(() => {
    if (organizationId && data) {
      const matchOrganizations = (data.organizations || []).filter(
        (org) => String(org.id) === organizationId
      );
      matchOrganizations.map((org) => setOrganizationName(org.name));
    }
  }, [organizationId, data]);

  const onChange = (value, name) => {
    setOrganizationName(name);
    fun(value);
  };

  const clear = () => {
    fun();
    setOrganizationName();
  };

  const selectOrganization = (e) => {
    let menuOrganizations = "";
    e.preventDefault();
    menuOrganizations = (
      <Menu>
        {(data ? data.organizations : []).map((el) => (
          <Menu.Item
            key={el.id}
            onClick={() => {
              onChange(el.id, el.name);
              history.push(`/organizations/${el.id}/booking`);
            }}
          >
            {el.name}
          </Menu.Item>
        ))}
      </Menu>
    );

    setMenu(menuOrganizations);
  };
  const [searchToggle, setSearchToggle] = useState(false);

  return show ? (
    <Header
      theme="light"
      className="site-layout-background header-css"
      style={{ padding: 0 }}
    >
      <Button className="menu-btn" onClick={() => setMenuToggle(!menuToggle)}>
        <img src={menuIcon} alt="menu" />
      </Button>

      <div
        className="mobile-logo"
        onClick={() => history.push("/organizations")}
      >
        <img src={mblLogo} alt="logo" />
      </div>

      <div className="header-property">
        <Dropdown overlay={menu} trigger={["click"]}>
          <div
            className="ant-dropdown-link"
            onClick={(e) => selectOrganization(e)}
            role="presentation"
          >
            {organizationName
              ? organizationName
              : t("header.searchPlaceholder")}

            <VerticalAlignMiddleOutlined />
          </div>
        </Dropdown>

        <div className="property-mbl">
          <Dropdown overlay={menu} trigger={["click"]}>
            <div onClick={(e) => selectOrganization(e)} role="presentation">
              <img src={propertyMbl} alt="" />
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  ) : (
    <Header
      theme="light"
      className="site-layout-background header-css"
      style={{ padding: 0 }}
    >
      <div
        className="mobile-logo"
        onClick={() => history.push("/organizations")}
      >
        <img src={mblLogo} alt="logo" />
      </div>
    </Header>
  );
};

TopHeader.propTypes = {
  fun: PropTypes.func,
  menuToggle: PropTypes.bool,
  setMenuToggle: PropTypes.func,
  show: PropTypes.bool,
};
TopHeader.defaultProps = {
  fun: () => {},
  setMenuToggle: () => {},
  menuToggle: false,
  show: true,
};

export default TopHeader;
