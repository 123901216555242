import "./team.css";

import { CloseOutlined } from "@ant-design/icons";
// import team from '../../assets/images/profile_user.jpg';
import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { updateMember } from "../../api/organizations";

const EditSubUserPopup = (props) => {
  const { t } = useTranslation();
  const {
    subUserData,
    getData,
    close,
    handleOk,
    handleCancel,
    visible,
    permission,
    organizationId,
  } = props;
  const [form] = Form.useForm();
  const [bookingRead, setBookingRead] = useState(false);
  const [bookingWrite, setBookingWrite] = useState(false);
  const [bookingDelete, setBookingDelete] = useState(false);
  const [propertiesRead, setPropertiesRead] = useState(false);
  const [propertiesWrite, setPropertiesWrite] = useState(false);
  const [propertiesDelete, setPropertiesDelete] = useState(false);
  const [calendarRead, setCalendarRead] = useState(false);
  const [calendarWrite, setCalendarWrite] = useState(false);
  const [calendarDelete, setCalendarDelete] = useState(false);
  const [guestsRead, setGuestsRead] = useState(false);
  const [guestsWrite, setGuestsWrite] = useState(false);
  const [guestsDelete, setGuestsDelete] = useState(false);
  const [channelRead, setChannelRead] = useState(false);
  const [channelWrite, setChannelWrite] = useState(false);
  const [channelDelete, setChannelDelete] = useState(false);
  const [teamRead, setTeamRead] = useState(false);
  const [teamWrite, setTeamWrite] = useState(false);
  const [teamDelete, setTeamDelete] = useState(false);
  const [invoicesRead, setInvoicesRead] = useState(false);
  const [invoicesWrite, setInvoicesWrite] = useState(false);
  const [invoicesDelete, setInvoicesDelete] = useState(false);
  const [statsRead, setStatsRead] = useState(false);
  const [statsWrite, setStatsWrite] = useState(false);
  const [statsDelete, setStatsDelete] = useState(false);
  const [serviceRead, setServiceRead] = useState(false);
  const [serviceWrite, setServiceWrite] = useState(false);
  const [serviceDelete, setServiceDelete] = useState(false);
  const [billingRead, setBillingRead] = useState(false);
  const [billingWrite, setBillingWrite] = useState(false);
  const [billingDelete, setBillingDelete] = useState(false);
  const [clickedOnRead, setClickedOnRead] = useState(true);

  const updateValues = useCallback(() => {
    form.setFieldsValue({
      email: subUserData.email,
      role: subUserData.role,
    });
    setBookingRead(subUserData.bookingRead);
    setBookingWrite(subUserData.bookingWrite);
    setBookingDelete(subUserData.bookingDelete);
    setPropertiesRead(subUserData.propertiesRead);
    setPropertiesWrite(subUserData.propertiesWrite);
    setPropertiesDelete(subUserData.propertiesDelete);
    setCalendarRead(subUserData.calendarRead);
    setCalendarWrite(subUserData.calendarWrite);
    setCalendarDelete(subUserData.calendarDelete);
    setChannelRead(subUserData.channelRead);
    setChannelWrite(subUserData.channelWrite);
    setChannelDelete(subUserData.channelDelete);
    setGuestsRead(subUserData.guestsRead);
    setGuestsWrite(subUserData.guestsWrite);
    setGuestsDelete(subUserData.guestsDelete);
    setTeamRead(subUserData.teamRead);
    setTeamWrite(subUserData.teamWrite);
    setTeamDelete(subUserData.teamDelete);
    setInvoicesRead(subUserData.invoicesRead);
    setInvoicesWrite(subUserData.invoicesWrite);
    setInvoicesDelete(subUserData.invoicesDelete);
    setStatsRead(subUserData.statsRead);
    setStatsWrite(subUserData.statsWrite);
    setStatsDelete(subUserData.statsDelete);
    setServiceRead(subUserData.serviceRead);
    setServiceWrite(subUserData.serviceWrite);
    setServiceDelete(subUserData.serviceDelete);
    setBillingWrite(subUserData.billingWrite);
    setBillingRead(subUserData.billingRead);
    setBillingDelete(subUserData.billingDelete);
  }, [subUserData, form]);

  useEffect(() => {
    updateValues();
  }, [visible, updateValues]);

  const handleSelect = (value) => {
    if (value === "read") {
      if (clickedOnRead) {
        setClickedOnRead(false);
      } else {
        setClickedOnRead(true);
      }
    }
    if (value === "fullaccess") {
      setBookingRead(true);
      setBookingWrite(true);
      setBookingDelete(true);
      setPropertiesRead(true);
      setPropertiesWrite(true);
      setPropertiesDelete(true);
      setCalendarRead(true);
      setCalendarWrite(true);
      setCalendarDelete(true);
      setChannelRead(true);
      setChannelWrite(true);
      setChannelDelete(true);
      setGuestsRead(true);
      setGuestsWrite(true);
      setGuestsDelete(true);
      setTeamRead(true);
      setTeamWrite(true);
      setTeamDelete(true);
      setInvoicesRead(true);
      setInvoicesWrite(true);
      setInvoicesDelete(true);
      setStatsRead(true);
      setStatsWrite(true);
      setStatsDelete(true);
      setServiceRead(true);
      setServiceWrite(true);
      setServiceDelete(true);
      setBillingRead(true);
      setBillingWrite(true);
      setBillingDelete(true);
    } else if (value === "write") {
      setBookingRead(true);
      setBookingWrite(true);
      setBookingDelete(false);
      setPropertiesRead(true);
      setPropertiesWrite(true);
      setPropertiesDelete(false);
      setCalendarRead(true);
      setCalendarWrite(true);
      setCalendarDelete(false);
      setChannelRead(true);
      setChannelWrite(true);
      setChannelDelete(false);
      setGuestsRead(true);
      setGuestsWrite(true);
      setGuestsDelete(false);
      setTeamRead(true);
      setTeamWrite(true);
      setTeamDelete(false);
      setInvoicesRead(true);
      setInvoicesWrite(true);
      setInvoicesDelete(false);
      setStatsRead(true);
      setStatsWrite(true);
      setStatsDelete(false);
      setServiceRead(true);
      setServiceWrite(true);
      setServiceDelete(false);
      setBillingRead(true);
      setBillingWrite(true);
      setBillingDelete(false);
    } else if (value === "read") {
      if (clickedOnRead) {
        setBookingRead(true);
        setBookingWrite(false);
        setBookingDelete(false);
        setPropertiesRead(true);
        setPropertiesWrite(false);
        setPropertiesDelete(false);
        setCalendarRead(true);
        setCalendarWrite(false);
        setCalendarDelete(false);
        setChannelRead(true);
        setChannelWrite(false);
        setChannelDelete(false);
        setGuestsRead(true);
        setGuestsWrite(false);
        setGuestsDelete(false);
        setTeamRead(true);
        setTeamWrite(false);
        setTeamDelete(false);
        setInvoicesRead(true);
        setInvoicesWrite(false);
        setInvoicesDelete(false);
        setStatsRead(true);
        setStatsWrite(false);
        setStatsDelete(false);
        setServiceRead(true);
        setServiceWrite(false);
        setServiceDelete(false);
        setBillingRead(true);
        setBillingWrite(false);
        setBillingDelete(false);
      }
      // else {
      //   makeEverythingUncheck();
      // }
    }
  };

  const makeEverythingUncheck = () => {
    setBookingRead(false);
    setBookingWrite(false);
    setBookingDelete(false);
    setPropertiesRead(false);
    setPropertiesWrite(false);
    setPropertiesDelete(false);
    setCalendarRead(false);
    setCalendarWrite(false);
    setCalendarDelete(false);
    setChannelRead(false);
    setChannelWrite(false);
    setChannelDelete(false);
    setGuestsRead(false);
    setGuestsWrite(false);
    setGuestsDelete(false);
    setTeamRead(false);
    setTeamWrite(false);
    setTeamDelete(false);
    setInvoicesRead(false);
    setInvoicesWrite(false);
    setInvoicesDelete(false);
    setStatsRead(false);
    setStatsWrite(false);
    setStatsDelete(false);
    setServiceRead(false);
    setServiceWrite(false);
    setServiceDelete(false);
    setBillingRead(false);
    setBillingWrite(false);
    setBillingDelete(false);
  };

  const updateMemberMutation = useMutation(
    ({ organizationId, values }) => updateMember(organizationId, values),
    {
      onSuccess: (data) => {
        if (data.code === 200) {
          getData();
          close();
          toast.success("Member updated successfully", {
            containerId: "B",
          });
          form.resetFields();
          makeEverythingUncheck();
        } else {
          toast.error("Some error occurred!", { containerId: "B" });
        }
      },
    }
  );

  const onFinish = async (values) => {
    values.bookingRead = bookingRead;
    values.bookingWrite = bookingWrite;
    values.bookingDelete = bookingDelete;
    values.propertiesRead = propertiesRead;
    values.propertiesWrite = propertiesWrite;
    values.propertiesDelete = propertiesDelete;
    values.calendarRead = calendarRead;
    values.calendarWrite = calendarWrite;
    values.calendarDelete = calendarDelete;
    values.channelRead = channelRead;
    values.channelWrite = channelWrite;
    values.channelDelete = channelDelete;
    values.guestsRead = guestsRead;
    values.guestsWrite = guestsWrite;
    values.guestsDelete = guestsDelete;
    values.teamRead = teamRead;
    values.teamWrite = teamWrite;
    values.teamDelete = teamDelete;
    values.invoicesRead = invoicesRead;
    values.invoicesWrite = invoicesWrite;
    values.invoicesDelete = invoicesDelete;
    values.statsRead = statsRead;
    values.statsWrite = statsWrite;
    values.statsDelete = statsDelete;
    values.serviceRead = serviceRead;
    values.serviceWrite = serviceWrite;
    values.serviceDelete = serviceDelete;
    values.billingRead = billingRead;
    values.billingWrite = billingWrite;
    values.billingDelete = billingDelete;
    values.id = subUserData.id;

    updateMemberMutation.mutate({ organizationId, values });
  };

  const handleCustomCheck = () => {
    form.setFieldsValue({
      role: "Custom",
    });
  };

  const handleBookingRead = (e) =>
    e.target.value ? setBookingRead(false) : setBookingRead(true);
  const handleBookingWrite = (e) =>
    e.target.value ? setBookingWrite(false) : setBookingWrite(true);
  const handleBookingDelete = (e) =>
    e.target.value ? setBookingDelete(false) : setBookingDelete(true);
  const handlePropertiesRead = (e) =>
    e.target.value ? setPropertiesRead(false) : setPropertiesRead(true);
  const handlePropertiesWrite = (e) =>
    e.target.value ? setPropertiesWrite(false) : setPropertiesWrite(true);
  const handlePropertiesDelete = (e) =>
    e.target.value ? setPropertiesDelete(false) : setPropertiesDelete(true);
  const handleCalendarRead = (e) =>
    e.target.value ? setCalendarRead(false) : setCalendarRead(true);
  const handleCalendarWrite = (e) =>
    e.target.value ? setCalendarWrite(false) : setCalendarWrite(true);
  const handleCalendarDelete = (e) =>
    e.target.value ? setCalendarDelete(false) : setCalendarDelete(true);
  const handleChannelRead = (e) =>
    e.target.value ? setChannelRead(false) : setChannelRead(true);
  const handleChannelWrite = (e) =>
    e.target.value ? setChannelWrite(false) : setChannelWrite(true);
  const handleChannelDelete = (e) =>
    e.target.value ? setChannelDelete(false) : setChannelDelete(true);
  const handleGuestsRead = (e) =>
    e.target.value ? setGuestsRead(false) : setGuestsRead(true);
  const handleGuestsWrite = (e) =>
    e.target.value ? setGuestsWrite(false) : setGuestsWrite(true);
  const handleGuestsDelete = (e) =>
    e.target.value ? setGuestsDelete(false) : setGuestsDelete(true);
  const handleTeamRead = (e) =>
    e.target.value ? setTeamRead(false) : setTeamRead(true);
  const handleTeamWrite = (e) =>
    e.target.value ? setTeamWrite(false) : setTeamWrite(true);
  const handleTeamDelete = (e) =>
    e.target.value ? setTeamDelete(false) : setTeamDelete(true);
  const handleInvoicesRead = (e) =>
    e.target.value ? setInvoicesRead(false) : setInvoicesRead(true);
  const handleInvoicesWrite = (e) =>
    e.target.value ? setInvoicesWrite(false) : setInvoicesWrite(true);
  const handleInvoicesDelete = (e) =>
    e.target.value ? setInvoicesDelete(false) : setInvoicesDelete(true);
  const handleStatsRead = (e) =>
    e.target.value ? setStatsRead(false) : setStatsRead(true);
  const handleStatsWrite = (e) =>
    e.target.value ? setStatsWrite(false) : setStatsWrite(true);
  const handleStatsDelete = (e) =>
    e.target.value ? setStatsDelete(false) : setStatsDelete(true);
  const handleServiceRead = (e) =>
    e.target.value ? setServiceRead(false) : setServiceRead(true);
  const handleServiceWrite = (e) =>
    e.target.value ? setServiceWrite(false) : setServiceWrite(true);
  const handleServiceDelete = (e) =>
    e.target.value ? setServiceDelete(false) : setServiceDelete(true);
  const handleBillingRead = (e) =>
    e.target.value ? setBillingRead(false) : setBillingRead(true);
  const handleBillingWrite = (e) =>
    e.target.value ? setBillingWrite(false) : setBillingWrite(true);
  const handleBillingDelete = (e) =>
    e.target.value ? setBillingDelete(false) : setBillingDelete(true);

  const handleCross = () => close();

  return (
    <Modal
      title={t("subuserpopup.edit_title", "Edit Member")}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName="right-modal-container"
    >
      <Helmet>
        <body className="ant-scrolling-effect" />
      </Helmet>
      <div className="cross-btn">
        <CloseOutlined onClick={handleCross} />
      </div>
      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        style={{ padding: "0px 20px" }}
      >
        <div className="subuser-fields-section">
          <Row style={{ alignItems: "center" }}>
            <Col sm={18} md={10} xs={18}>
              <Form.Item
                label={t("strings.email")}
                name="email"
                style={{ paddingRight: 20 }}
                rules={[
                  {
                    type: "email",
                    message: t("subuserpopup.label1"),
                  },
                  {
                    required: true,
                    message: t("subuserpopup.label2"),
                  },
                ]}
              >
                <Input placeholder={t("strings.email")} disabled />
              </Form.Item>
            </Col>

            <Col sm={18} md={10} xs={18}>
              <Form.Item label={t("subuserpopup.label25")} name="role">
                <Select
                  placeholder={t("subuserpopup.label3")}
                  onSelect={(value) => handleSelect(value)}
                >
                  <Select.Option value="read">Read</Select.Option>

                  <Select.Option value="write">Write</Select.Option>
                  <Select.Option value="fullaccess">Full Access</Select.Option>
                  <Select.Option value="custom" hidden>
                    custom
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} />
          </Row>
        </div>

        <Row>
          <div className="custom-table subuser-table">
            <table>
              <thead>
                <tr>
                  <th> {t("subuserpopup.name")}</th>
                  <th> {t("subuserpopup.label16")}</th>
                  <th> {t("subuserpopup.label17")}</th>
                  <th> {t("subuserpopup.delete")}</th>
                  <th> {t("subuserpopup.label18")}</th>
                </tr>
              </thead>
              <tbody>
                <tr hidden={permission.booking !== 1}>
                  <td>{t("subuserpopup.label5")}</td>
                  <td>
                    <Checkbox
                      value={bookingRead}
                      onChange={(e) => handleBookingRead(e)}
                      checked={bookingRead}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={bookingWrite}
                      onChange={(e) => handleBookingWrite(e)}
                      checked={bookingWrite}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={bookingDelete}
                      onChange={(e) => handleBookingDelete(e)}
                      checked={bookingDelete}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    {t(
                      "subuserpopup.booking_desc",
                      "Manage bookings of the team"
                    )}
                  </td>
                </tr>

                <tr hidden={permission.calendar !== 1}>
                  <td> {t("subuserpopup.label7")}</td>
                  <td>
                    <Checkbox
                      value={calendarRead}
                      onChange={(e) => handleCalendarRead(e)}
                      checked={calendarRead}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={calendarWrite}
                      onChange={(e) => handleCalendarWrite(e)}
                      checked={calendarWrite}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={calendarDelete}
                      onChange={(e) => handleCalendarDelete(e)}
                      checked={calendarDelete}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td> {t("subuserpopup.label8")}</td>
                </tr>

                <tr hidden={permission.properties !== 1}>
                  <td> {t("subuserpopup.label9")}</td>
                  <td>
                    <Checkbox
                      value={propertiesRead}
                      onChange={(e) => handlePropertiesRead(e)}
                      checked={propertiesRead}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={propertiesWrite}
                      onChange={(e) => handlePropertiesWrite(e)}
                      checked={propertiesWrite}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={propertiesDelete}
                      onChange={(e) => handlePropertiesDelete(e)}
                      checked={propertiesDelete}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    {t(
                      "subuserpopup.properties_desc",
                      "Add and edit information and prices properties of the team"
                    )}
                  </td>
                </tr>

                <tr hidden={permission.guests !== 1}>
                  <td> {t("subuserpopup.label11")}</td>
                  <td>
                    <Checkbox
                      value={guestsRead}
                      onChange={(e) => handleGuestsRead(e)}
                      checked={guestsRead}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={guestsWrite}
                      onChange={(e) => handleGuestsWrite(e)}
                      checked={guestsWrite}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={guestsDelete}
                      onChange={(e) => handleGuestsDelete(e)}
                      checked={guestsDelete}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    {t("subuserpopup.guests_desc", "Manage guests of the team")}
                  </td>
                </tr>

                {/* <tr hidden={permission.channel !== 1}>
                  <td> {t("subuserpopup.label26")}</td>
                  <td>
                    <Checkbox
                      value={channelRead}
                      onChange={(e) => handleChannelRead(e)}
                      checked={channelRead}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={channelWrite}
                      onChange={(e) => handleChannelWrite(e)}
                      checked={channelWrite}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={channelDelete}
                      onChange={(e) => handleChannelDelete(e)}
                      checked={channelDelete}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>{t("subuserpopup.label27")}</td>
                </tr> */}

                <tr hidden={permission.team !== 1}>
                  <td> {t("subuserpopup.label13")}</td>
                  <td>
                    <Checkbox
                      value={teamRead}
                      onChange={(e) => handleTeamRead(e)}
                      checked={teamRead}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={teamWrite}
                      onChange={(e) => handleTeamWrite(e)}
                      checked={teamWrite}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={teamDelete}
                      onChange={(e) => handleTeamDelete(e)}
                      checked={teamDelete}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    {" "}
                    {t(
                      "subuserpopup.team_desc",
                      "Add and change role of team member"
                    )}
                  </td>
                </tr>

                {/* <tr hidden={permission.invoice !== 1}>
                  <td> {t("subuserpopup.label15")}</td>
                  <td>
                    <Checkbox
                      value={invoicesRead}
                      onChange={(e) => handleInvoicesRead(e)}
                      checked={invoicesRead}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={invoicesWrite}
                      onChange={(e) => handleInvoicesWrite(e)}
                      checked={invoicesWrite}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={invoicesDelete}
                      onChange={(e) => handleInvoicesDelete(e)}
                      checked={invoicesDelete}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td> {t("subuserpopup.label19")}</td>
                </tr> */}

                {/* <tr hidden={permission.stats !== 1}>
                  <td> {t("subuserpopup.label20")}</td>
                  <td>
                    <Checkbox
                      value={statsRead}
                      onChange={(e) => handleStatsRead(e)}
                      checked={statsRead}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={statsWrite}
                      onChange={(e) => handleStatsWrite(e)}
                      checked={statsWrite}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={statsDelete}
                      onChange={(e) => handleStatsDelete(e)}
                      checked={statsDelete}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td> {t("subuserpopup.label21")}</td>
                </tr> */}

                <tr>
                  <td> {t("subuserpopup.label22")}</td>
                  <td>
                    <Checkbox
                      value={serviceRead}
                      onChange={(e) => handleServiceRead(e)}
                      checked={serviceRead}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={serviceWrite}
                      onChange={(e) => handleServiceWrite(e)}
                      checked={serviceWrite}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={serviceDelete}
                      onChange={(e) => handleServiceDelete(e)}
                      checked={serviceDelete}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>{t("subuserpopup.services_desc", "Service settings")}</td>
                </tr>

                {/* <tr>
                  <td>Billing</td>
                  <td>
                    <Checkbox
                      value={billingRead}
                      onChange={(e) => handleBillingRead(e)}
                      checked={billingRead}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={billingWrite}
                      onChange={(e) => handleBillingWrite(e)}
                      checked={billingWrite}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>
                    <Checkbox
                      value={billingDelete}
                      onChange={(e) => handleBillingDelete(e)}
                      checked={billingDelete}
                      onClick={handleCustomCheck}
                    />
                  </td>
                  <td>Billing, Upgrade/downgrade plans</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </Row>

        <Row
          style={{
            alignItems: "center",
            textAlign: "right",
            marginTop: "30px",
          }}
        >
          <Col span={24} className="subuser-fields-section">
            <Form.Item>
              <Button style={{ marginRight: 10 }} onClick={close}>
                {t("strings.cancel")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("strings.save")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

EditSubUserPopup.propTypes = {
  subUserData: PropTypes.object,
  getData: PropTypes.func,
  close: PropTypes.func,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  visible: PropTypes.bool,
  permission: PropTypes.object,
  organizationId: PropTypes.string,
};

EditSubUserPopup.defaultProps = {
  subUserData: {},
  getData: () => {},
  close: () => {},
  handleCancel: () => {},
  handleOk: () => {},
  visible: false,
  permission: {},
  organizationId: "",
};
export default EditSubUserPopup;
