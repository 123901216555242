import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./booking.css";
// import Toaster from '../toaster/toaster';
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { CountryDropdown } from "react-country-region-selector";
import { upsertGuest } from "../../api/bookings";

const GuestPopup = (props) => {
  const { t } = useTranslation();
  const {
    editValues,
    getData,
    close: close1,
    setBooked,
    closeToaster,
    visible,
    handleOk,
    handleCancel,
  } = props;
  const [form] = Form.useForm();
  const guestData = editValues;
  const [country, setCountry] = useState(null);

  const upsertGuestMutation = useMutation(
    ({ bookingId, data }) => upsertGuest(bookingId, data),
    {
      onSuccess: (data) => {
        const statusCode = data.code;
        if (statusCode === 200) {
          toast.success(
            t("guestpopup.success_msg", "Data added successfully"),
            { containerId: "B" }
          );
          getData();
          close1();
          setBooked(true);
        } else {
          toast.error(t("strings.server_error", "some error occurred!"), {
            containerId: "B",
          });
        }
        form.resetFields();
        closeToaster();
      },
    }
  );

  useEffect(() => {
    const initialValue = moment().subtract(3, "years");
    if (Object.keys(guestData).length) {
      const m1 = moment(guestData.dob);
      setCountry(guestData.country);

      form.setFieldsValue({
        id: guestData.id,
        fullName: guestData.fullName,
        country: guestData.country,
        email: guestData.email,
        phone: guestData.phone,
        dob: guestData.dob ? m1 : initialValue,
        // dob: guestData.dob,
        gender: guestData.gender,
        typeOfDoc: guestData.typeOfDoc,
        docNo: guestData.docNo,
        // citizenShip: guestData.citizenShip,
        place: guestData.place,
        notes: guestData.notes,
      });
    }
  }, [guestData, form]);

  const onFinish = async (values) => {
    const bookingId = localStorage.getItem("bookingId");
    await upsertGuestMutation.mutate({ bookingId, data: values });
  };
  const disabledDate = (current) =>
    current > moment().subtract(3, "y") || current > moment();

  return (
    <Modal
      title={t("strings.guest")}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName="right-modal-container"
    >
      <Helmet>
        <body className="ant-scrolling-effect" />
      </Helmet>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        style={{ padding: "0px 20px", marginTop: 10 }}
      >
        <Row style={{ alignItems: "center" }}>
          <Form.Item name="id">
            <Input hidden />
          </Form.Item>
          <Col span={12}>
            <Form.Item
              label={t("strings.full")}
              name="fullName"
              style={{ paddingRight: 20 }}
              rules={[
                {
                  required: true,
                  message: t("guestpopup.label1"),
                },
              ]}
            >
              <Input placeholder={t("strings.full")} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t("guestpopup.label2")}
              name="country"
              rules={[{ required: true, message: t("guestpopup.label3") }]}
            >
              <CountryDropdown className="country-select" />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={12}>
            <Form.Item
              label={t("strings.email")}
              name="email"
              style={{ paddingRight: 20 }}
            >
              <Input placeholder={t("strings.email")} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={t("strings.phone")} name="phone">
              <Input placeholder={t("strings.phone")} type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center", padding: "0px 20px" }}>
          <Col span={12}>
            <Form.Item
              name="dob"
              label={t("strings.dob")}
              style={{ paddingRight: 20 }}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="gender" label={t("strings.gender")}>
              <Radio.Group name="radiogroup" defaultValue={1}>
                <Radio value={1}>M</Radio>
                <Radio value={2}>F</Radio>
                <Radio value={3}>Other</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center", padding: "0px 20px" }}>
          <Col span={12}>
            <Form.Item
              label={t("guestpopup.label4")}
              name="typeOfDoc"
              style={{ paddingRight: 20 }}
            >
              <Select>
                <Select.Option value="Passport">Passport </Select.Option>
                <Select.Option value="ID Card">ID Card </Select.Option>
                <Select.Option value="Driving License">
                  Driving License
                </Select.Option>
                <Select.Option value="Other">Other </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={t("guestpopup.label6")} name="docNo">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center", padding: "0px 20px" }}>
          <Col span={12}>
            <Form.Item label={t("guestpopup.label8")} name="place">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center", padding: "0px 20px" }}>
          <Col span={24}>
            <Form.Item label={t("strings.note")} name="notes">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row
          style={{
            alignItems: "center",
            textAlign: "right",
            padding: "0px 20px",
          }}
        >
          <Col span={24}>
            <Form.Item>
              <Button style={{ marginRight: 10 }} onClick={close1}>
                {t("strings.cancel")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("strings.save")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

GuestPopup.propTypes = {
  editValues: PropTypes.objectOf(PropTypes.object),
  getData: PropTypes.func,
  close: PropTypes.func,
  setBooked: PropTypes.func,
  closeToaster: PropTypes.func,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
};
GuestPopup.defaultProps = {
  editValues: {},
  getData: () => {},
  close: () => {},
  setBooked: () => {},
  closeToaster: () => {},
  visible: false,
  handleCancel: () => {},
  handleOk: () => {},
};
export default GuestPopup;
