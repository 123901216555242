import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  changePassword,
  logout,
  updateBasicInfo,
  updateTimezone,
} from "../api/users";
import { StoreKeys } from "../storeKeys";

export const useMutateAuth = () => {
  const queryClient = useQueryClient();

  const logoutMutation = useMutation(() => logout(), {
    onSuccess: (_res) => {
      localStorage.clear();
    },
  });

  const updateBasicInfoMutation = useMutation((data) => updateBasicInfo(data), {
    onSuccess: (res) => {
      if (res && res.code === 200) {
        queryClient.invalidateQueries(StoreKeys.GET_ME);
      }
    },
  });

  const updateTimezoneMutation = useMutation((data) => updateTimezone(data), {
    onSuccess: (res) => {
      if (res && res.code === 200) {
        queryClient.invalidateQueries(StoreKeys.GET_ME);
      }
    },
  });

  const changePasswordMutation = useMutation((data) => changePassword(data), {
    onSuccess: (res) => {
      if (res && res.code === 200) {
        queryClient.invalidateQueries(StoreKeys.GET_ME);
      }
    },
  });

  return {
    logoutMutation,
    updateBasicInfoMutation,
    updateTimezoneMutation,
    changePasswordMutation,
  };
};
