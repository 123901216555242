import { InboxOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { propertyInstance } from "../../axios/axiosConfig";
import { server } from "../../config/keys";
import Meta from "../meta";
import Wrapper from "../wrapper";

const Photos = () => {
  const { unitTypeId, organizationId } = useParams();
  const [propertyImage, setPropertyImage] = useState("");
  const { t } = useTranslation();
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getData = async () => {
    const response = await propertyInstance.post("/fetchUnitTypeData", {
      unitTypeId,
    });
    if (response.data.code === 200) {
      if (response.data.unitType) {
        const data = response.data.unitType;
        setPropertyImage(data.image);
      }
    }
  };

  const props = {
    name: "file",
    multiple: false,
    // beforeUpload(file) {
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     toast.error('Image must smaller than 2MB!');
    //   }
    //   return isLt2M;
    // },
    action: `${server}/users/propertyPicture?unitTypeId=${unitTypeId}&organizationId=${organizationId}`,
    onChange(info) {
      if (info.file.status === "done") {
        getData();
        toast.success(`${info.file.name} file uploaded successfully`, {
          containerId: "B",
        });
      } else if (info.file.status === "error") {
        toast.error(`${info.file.name} file upload failed.`, {
          containerId: "B",
        });
      }
    },
  };

  useEffect(() => {
    getData();
  }, []);

  const removePhoto = async () => {
    const res = await propertyInstance.post("/removePropertyPhoto", {
      unitTypeId,
    });
    if (res.data.code === 200) {
      getData();
      toast.success("photo removed successfully", { containerId: "B" });
    } else {
      toast.error("server error", { containerId: "B" });
    }
  };

  return (
    <Wrapper propertyImage={propertyImage}>
      <Meta>
        <body className="photos-page-view" />
      </Meta>

      <div className="photos">
        <Row>
          <Col span={24}>
            <div className="photos-content">
              <Form>
                <div className="location-first-section">
                  <h3>{t("photos.heading1")}</h3>
                  <p>
                    {t(
                      "photos.description",
                      "Catch a potential guest's eye with high-quality photo."
                    )}
                  </p>
                  <Form.Item>
                    <Form.Item
                      name="dragger"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      noStyle
                    >
                      <Upload.Dragger {...props}>
                        {propertyImage ? (
                          <>
                            <div className="user-pic-success">
                              <img src={propertyImage} alt="propertyImage" />
                            </div>
                          </>
                        ) : (
                          <>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                              {t("photos.paragraph5")}
                            </p>
                            {/* <p className="ant-upload-hint">
                                  {t('photos.paragraph6')}
                                </p> */}
                          </>
                        )}
                      </Upload.Dragger>
                      {propertyImage && (
                        <div className="remove-images-btn">
                          <Button onClick={removePhoto}>
                            {t("photos.remove_photo", "Remove photo")}
                          </Button>
                        </div>
                      )}
                    </Form.Item>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default Photos;
