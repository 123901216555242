import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import guestImg from "../../assets/images/guest/guestinfo.svg";
import { bookingInstance } from "../../axios/axiosConfig";
import "./guests.css";

const UpdateGuestPopup = (props) => {
  const { t } = useTranslation();
  const { visible, onOk, handleCancel, close, editValues, getData } = props;
  const [country, setCountry] = useState("");

  const [form] = Form.useForm();
  const guestData = editValues;

  useEffect(() => {
    if (Object.keys(guestData).length) {
      const m1 = moment(guestData.dob);
      form.setFieldsValue({
        id: guestData.id,
        fullName: guestData.fullName,
        country: guestData.country,
        email: guestData.email,
        phone: guestData.phone,
        dob: guestData.dob && m1,
        gender: guestData.gender,
        typeOfDoc: guestData.typeOfDoc,
        docNo: guestData.docNo,
        place: guestData.place,
        notes: guestData.notes,
      });
      setCountry(guestData.country);
    }
  }, [guestData, form]);

  const onFinish = async (values) => {
    values.bookingId = localStorage.getItem("bookingId");
    const response = await bookingInstance.post(
      `/${values.bookingId}/addGuest`,
      values
    );
    const statusCode = response.data.code;
    if (statusCode === 200) {
      toast.success("Data added successfully", { containerId: "B" });
      getData();
      close();
    } else {
      toast.error("some error occurred!", { containerId: "B" });
    }
    form.resetFields();
  };

  return (
    <Modal
      title={t("guests.edit_guest_lb", "Update Guest")}
      open={visible}
      onOk={onOk}
      onCancel={handleCancel}
      wrapClassName="right-modal-container"
      className="guest-modal"
    >
      <Helmet>
        <body className={visible ? "ant-scrolling-effect" : ""} />
      </Helmet>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        style={{ padding: "0px 20px" }}
      >
        <h4 className="guest-info" style={{ marginTop: 10 }}>
          <img src={guestImg} alt="guest-img" />
          {t("guests.guest_info_lb", "Guest Information")}
        </h4>
        <Row style={{ alignItems: "center" }}>
          <Form.Item name="id">
            <Input hidden />
          </Form.Item>
          <Col span={12}>
            <Form.Item
              label={t("strings.full")}
              name="fullName"
              style={{ paddingRight: 20 }}
              rules={[
                {
                  required: true,
                  message: t("guestpopup.label1"),
                },
              ]}
            >
              <Input placeholder={t("strings.full")} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t("guestpopup.label2")}
              name="country"
              rules={[{ required: true, message: t("guestpopup.label3") }]}
            >
              <CountryDropdown value={country} />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={12}>
            <Form.Item
              label={t("strings.email")}
              name="email"
              style={{ paddingRight: 20 }}
            >
              <Input placeholder={t("strings.email")} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={t("strings.phone")} name="phone">
              <Input placeholder={t("strings.phone")} type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={12}>
            <Form.Item
              name="dob"
              label={t("strings.dob")}
              style={{ paddingRight: 20 }}
              initialValue={moment().subtract(18, "years")}
            >
              <DatePicker />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="gender" label={t("strings.gender")}>
              <Radio.Group name="radiogroup" defaultValue={1}>
                <Radio value={1}>M</Radio>
                <Radio value={2}>F</Radio>
                <Radio value={3}>Other</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={12}>
            <Form.Item
              name="typeOfDoc"
              label={t("strings.type_of_doc", "Type of Document")}
              style={{ paddingRight: 20 }}
            >
              <Select placeholder="Select">
                <Select.Option value="Passport" selected>
                  Passport
                </Select.Option>
                <Select.Option value="ID Card">ID Card</Select.Option>
                <Select.Option value="Driving License">
                  Driving License
                </Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={t("guestpopup.label6")} name="docNo">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={12}>
            <Form.Item
              label={t("guestpopup.label8")}
              name="place"
              style={{ paddingRight: 20 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={24}>
            <Form.Item label={t("strings.note")} name="notes">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center", textAlign: "right" }}>
          <Col span={24}>
            <Form.Item>
              <Button style={{ marginRight: 10 }} onClick={close}>
                {t("strings.cancel")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("strings.save")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

UpdateGuestPopup.propTypes = {
  editValues: PropTypes.objectOf(PropTypes.object),
  getData: PropTypes.func,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  onOk: PropTypes.func,
  close: PropTypes.func,
};
UpdateGuestPopup.defaultProps = {
  editValues: {},
  getData: () => {},
  visible: false,
  handleCancel: () => {},
  onOk: () => {},
  close: () => {},
};
export default UpdateGuestPopup;
