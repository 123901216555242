import { CommentOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { ChatButton } from "../crisp-chat/CrispChat";
import Meta from "../meta";
import Wrapper from "../wrapper";
import "./contact-admin.css";

const ContactAdmin = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Meta />

      <div>
        <div className="page-header">
          <h1>
            <UserOutlined />
            {t("contact.heading", "Contact Admin")}
          </h1>
        </div>

        <div className="chat-container">
          <div className="chat-content">
            <p className="icon-header">
              <CommentOutlined />
            </p>
            <h3>{t("contact.title", "Contact")}</h3>
            <p>
              {t(
                "contact.desc1",
                "For any questions or if you'd like to share your feedback, send an email to:"
              )}{" "}
              <a href="mailto:support@propsatlas.com">
                <b>support@propsatlas.com</b>
              </a>{" "}
              {t("contact.or", "or")}:
            </p>
            <ChatButton text={t("contact.chat_btn", "Start chat")} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ContactAdmin;
