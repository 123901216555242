import "react-calendar-timeline/lib/Timeline.css";
import "./calendar.css";

import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Col, Row, Tooltip } from "antd";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import Timeline, {
  CustomHeader,
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { getTimelineCalendar } from "../../api/bookings";
import { getProperties } from "../../api/properties";
import { useMemberPermissions, useOrganizationSubscription } from "../../hooks";
import {
  setIsCreatingBooking,
  setViewingBookingId,
} from "../../slices/bookings";
import { StoreKeys } from "../../storeKeys";
import {
  getEndOfMonth,
  getFirstOfMonth,
  isSameDate,
} from "../../utils/dateUtils";
import CreateBookingPopup from "../booking/createbookingpopup";
import EditBookingPopup from "../booking/editbookingpopup";
import Loading from "../Loading";
import Meta from "../meta";
import NoProperty from "../no-property/NoProperty";
import UserLock from "../userlock/userlock";
import UserNotAuthorize from "../userlock/UserNotAuthorize";
import Wrapper from "../wrapper";
import { getPerNightPriceAndMinStay } from "./utils";

const CalendarNew = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const dispatch = useDispatch();

  const { hasAccess } = useOrganizationSubscription(organizationId);
  const { getPermissions } = useMemberPermissions(organizationId);
  const { calendarRead: canRead } = getPermissions();

  const [openGroups, setOpenGroups] = useState({});
  const [firstGroup, setFirstGroup] = useState(null);

  const [visibleTimeStart, setVisibleTimeStart] = useState(
    getFirstOfMonth(new Date())
  );
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(
    getEndOfMonth(new Date())
  );

  const [currentDate, setCurrentDate] = useState(moment().format("MMMM YYYY"));

  const [propertyData, setPropertyData] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);

  const { data: propertiesData, loading: propertiesLoading } = useQuery({
    queryKey: [StoreKeys.GET_PROPERTIES, organizationId],
    queryFn: () => getProperties(organizationId, 1000, 0),
    onSuccess: (data) => {
      if (data.code === 200) {
        setPropertyData(data.properties);
      }
    },
  });

  const formatGroupId = (unitTypeId) => {
    return `parent_${unitTypeId}`;
  };

  const formatChildId = (unitTypeId, unitId) => {
    return `child_${unitTypeId}_${unitId}`;
  };

  const formatPerNight = (unitTypeId) => {
    return `per_night_${unitTypeId}`;
  };

  const formatMinimumStay = (unitTypeId) => {
    return `min_stay_${unitTypeId}`;
  };

  const getTimelineCalendarMutation = useMutation(
    ({ startTime, endTime }) =>
      getTimelineCalendar(organizationId, startTime, endTime),
    {
      onSuccess: (data) => {
        if (data.code === 200) {
          if (data.data && data.data.length > 0) {
            setUnitTypes(data.data);
          }
        }
      },
    }
  );

  useEffect(() => {
    if (organizationId) {
      getTimelineCalendarMutation.mutate({
        startTime: visibleTimeStart,
        endTime: visibleTimeEnd,
      });
    }
  }, [organizationId]);

  const keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start",
    itemTimeEndKey: "end",
    groupLabelKey: "title",
  };

  const toggleGroup = (id) => {
    setOpenGroups({ ...openGroups, [id]: !openGroups[id] });
  };

  const generatePricePerNight = (unitTypeId, normalRates, rates) => {
    const unitTypeNormalRates = normalRates[unitTypeId];
    const unitTypeRates = rates[unitTypeId];
    let startTime = visibleTimeStart;
    const results = [];

    while (startTime <= visibleTimeEnd) {
      const beginDate = startTime;
      const endDate = moment(beginDate).add(1, "d").valueOf();
      const data = getPerNightPriceAndMinStay(
        beginDate,
        endDate,
        unitTypeNormalRates,
        unitTypeRates
      );

      results.push({
        id: `price_night_${unitTypeId}_${beginDate}`,
        group: formatPerNight(unitTypeId),
        title: data.abbreviatePricePerNight,
        start: beginDate,
        end: endDate,
        className: "item-value",
      });
      results.push({
        id: `min_stay_${unitTypeId}_${beginDate}`,
        group: formatMinimumStay(unitTypeId),
        title: data.minimumStay,
        start: beginDate,
        end: endDate,
        className: "item-value",
      });

      startTime = moment(startTime).add(1, "day").valueOf();
    }
    return results;
  };

  const rates = {};
  const normalRates = {};
  const customRates = {};

  let groupUnitTypes = [];
  let items = [];

  for (let i = 0; i < unitTypes.length; i++) {
    const unitType = unitTypes[i];
    const unitTypeId = unitType.id;

    rates[unitTypeId] = unitType.rates.data;
    normalRates[unitTypeId] = unitType.normalRates.data;
    customRates[unitTypeId] = unitType.customRates.data;

    const groupId = formatGroupId(unitTypeId);
    if (!firstGroup && i === 0) {
      setFirstGroup(groupId);
    }

    const units = unitType.units.data;
    const unitTypeName = unitType.name
      .filter((e) => e.lang === "en")
      .map((name) => name.name)[0];

    groupUnitTypes.push({
      id: groupId,
      title: unitTypeName,
      rightTitle: unitTypeName,
      root: true,
      parent: null,
    });

    groupUnitTypes.push({
      id: formatPerNight(unitTypeId),
      title: t("calendar.price_per_night", "Price per night"),
      rightTitle: t("calendar.price_per_night", "Price per night"),
      root: false,
      parent: groupId,
      className: "text-bold",
    });

    const pricePerNightItems = generatePricePerNight(
      unitTypeId,
      normalRates,
      rates
    );
    items = items.concat([...pricePerNightItems]);

    groupUnitTypes.push({
      id: formatMinimumStay(unitTypeId),
      title: t("calendar.minimum_stay", "Minimum stay"),
      rightTitle: t("calendar.minimum_stay", "Minimum stay"),
      root: false,
      parent: groupId,
      className: "text-bold",
    });

    if (units && units.length > 0) {
      for (let k = 0; k < units.length; k++) {
        const unit = units[k];
        const unitChildId = formatChildId(unitTypeId, unit.id);
        const bookings = unit.bookings.data || [];

        groupUnitTypes.push({
          id: unitChildId,
          title: unit.name,
          rightTitle: unit.name,
          root: false,
          parent: groupId,
        });

        if (bookings) {
          bookings.forEach((booking) => {
            items.push({
              id: String(booking.id),
              group: unitChildId,
              title: booking.guestName,
              start: booking.from,
              end: booking.to,
            });
          });
        }
      }
    }
  }

  groupUnitTypes = groupUnitTypes
    .filter((g) => g.root || openGroups[g.parent])
    .map((group) => {
      return Object.assign({}, group, {
        title: group.root ? (
          <Tooltip title={group.title}>
            <div
              className="header-parent"
              onClick={() => {
                toggleGroup(group.id);
              }}
            >
              {openGroups[group.id] ? "[-]" : "[+]"} {group.title}
            </div>
          </Tooltip>
        ) : (
          <div
            className={
              group.className
                ? `${group.className} header-children`
                : "header-children"
            }
          >
            {"•"} {group.title}
          </div>
        ),
      });
    });

  const onPrevClick = () => {
    const prevStartOfMonth = moment(visibleTimeStart)
      .add(-15, "days")
      .startOf("month")
      .valueOf();
    const prevEndOfMonth = moment(visibleTimeStart)
      .add(-15, "days")
      .endOf("month")
      .valueOf();

    setVisibleTimeStart(prevStartOfMonth);
    setVisibleTimeEnd(prevEndOfMonth);
    getTimelineCalendarMutation.mutate({
      startTime: prevStartOfMonth,
      endTime: prevEndOfMonth,
    });
  };

  const onNextClick = () => {
    const nextStartOfMonth = moment(visibleTimeStart)
      .add(+35, "days")
      .startOf("month")
      .valueOf();
    const nextEndOfMonth = moment(visibleTimeStart)
      .add(+35, "days")
      .endOf("month")
      .valueOf();

    setVisibleTimeStart(nextStartOfMonth);
    setVisibleTimeEnd(nextEndOfMonth);
    getTimelineCalendarMutation.mutate({
      startTime: nextStartOfMonth,
      endTime: nextEndOfMonth,
    });
  };

  useEffect(() => {
    setCurrentDate(
      moment(visibleTimeStart + (visibleTimeEnd - visibleTimeStart) / 2).format(
        "MMMM YYYY"
      )
    );
  }, [visibleTimeStart, visibleTimeEnd]);

  useEffect(() => {
    if (firstGroup) {
      setOpenGroups({ ...openGroups, [firstGroup]: true });
    }
  }, [firstGroup]);

  const isAccess = hasAccess();

  if (canRead !== 1) {
    return <UserNotAuthorize />;
  }

  if (!isAccess) {
    return (
      <Wrapper organizationId={organizationId}>
        <UserLock />
      </Wrapper>
    );
  }

  if (propertiesData && propertiesData.properties.length === 0) {
    return <NoProperty organizationId={organizationId} />;
  }

  return (
    <Wrapper organizationId={organizationId}>
      <Meta>
        <body className="calendar-page-view" />
      </Meta>

      <div className="calendar">
        <div className="calendar-btn">
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  dispatch(setIsCreatingBooking(true));
                }}
              >
                {t("calendar.add_reservation", "Add Booking")}
              </Button>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 8, offset: 8 }}>
              {" "}
              <div className="year-select">
                <Button className="prev-btn" onClick={onPrevClick}>
                  ❮
                </Button>
                <span>{currentDate}</span>
                <Button className="next-btn" onClick={onNextClick}>
                  ❯
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        {getTimelineCalendarMutation.isLoading && <Loading />}
        {!getTimelineCalendarMutation.isLoading && (
          <Row>
            <Col span={24}>
              <div className="calendar-calendar">
                {groupUnitTypes.length > 0 &&
                visibleTimeStart &&
                visibleTimeEnd ? (
                  <Timeline
                    groups={groupUnitTypes}
                    items={items}
                    keys={keys}
                    sidebarWidth={150}
                    canSelect
                    itemTouchSendsClick={false}
                    stackItems={true}
                    itemHeightRatio={0.75}
                    showCursorLine
                    visibleTimeStart={visibleTimeStart}
                    visibleTimeEnd={visibleTimeEnd}
                    onCanvasClick={(groupId, time, e) => {
                      console.log("Canvas Click: ", groupId, time, e);
                    }}
                    onItemClick={(itemId, event, time) => {
                      console.log("Click item ==> ", itemId, event, time);
                      dispatch(setViewingBookingId(itemId));
                    }}
                    itemRenderer={({
                      item,
                      timelineContext,
                      itemContext,
                      getItemProps,
                      getResizeProps,
                    }) => {
                      const { left: leftResizeProps, right: rightResizeProps } =
                        getResizeProps();

                      return (
                        <div {...getItemProps(item.itemProps)}>
                          {itemContext.useResizeHandle ? (
                            <div {...leftResizeProps} />
                          ) : (
                            ""
                          )}

                          <div
                            className="rct-item-content"
                            style={{
                              maxHeight: `${itemContext.dimensions.height}`,
                            }}
                          >
                            {itemContext.title}
                          </div>

                          {itemContext.useResizeHandle ? (
                            <div {...rightResizeProps} />
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    }}
                  >
                    <TimelineHeaders className="calendar-sticky">
                      <SidebarHeader>
                        {({ getRootProps }) => {
                          return (
                            <div className="sidebar-left" {...getRootProps()}>
                              {t(
                                "calendar.select_properties",
                                "Select properties"
                              )}
                            </div>
                          );
                        }}
                      </SidebarHeader>
                      <DateHeader className="header-month" unit="month" />
                      <CustomHeader height={50} unit="day">
                        {({
                          headerContext: { intervals },
                          getRootProps,
                          getIntervalProps,
                        }) => {
                          return (
                            <div {...getRootProps()}>
                              {intervals.map((interval) => {
                                return (
                                  <div
                                    {...getIntervalProps({
                                      interval,
                                    })}
                                    className={
                                      isSameDate(interval.startTime)
                                        ? `date-header-item now-date`
                                        : "date-header-item"
                                    }
                                  >
                                    <p className="date-number">
                                      {interval.startTime.format("DD")}
                                    </p>
                                    <p className="date-text">
                                      {interval.startTime.format("ddd")}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }}
                      </CustomHeader>
                    </TimelineHeaders>

                    <TimelineMarkers>
                      <TodayMarker>
                        {({ styles }) => {
                          return (
                            <div
                              className="today-marker"
                              style={{ ...styles }}
                            />
                          );
                        }}
                      </TodayMarker>
                    </TimelineMarkers>
                  </Timeline>
                ) : null}

                <CreateBookingPopup organizationId={organizationId} />

                <EditBookingPopup />
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Wrapper>
  );
};

export default CalendarNew;
