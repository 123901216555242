import "./sideNav.css";

import { ApartmentOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";

import packageJson from "../../../../package.json";
import logo from "../../../assets/images/logo.png";
import bookingIcon from "../../../assets/images/menu/booking-icon.png";
import calenderIcon from "../../../assets/images/menu/calendar-icon.png";
import closeIcon from "../../../assets/images/menu/close-icon.png";
import guestIcon from "../../../assets/images/menu/guest-icon.png";
import propertyIcon from "../../../assets/images/menu/property-icon.png";
import statsIcon from "../../../assets/images/menu/stats-icon.png";
import submenuCompany from "../../../assets/images/menu/submenu-company.png";
import submenuGuest from "../../../assets/images/menu/submenu-guest.png";
import teamIcon from "../../../assets/images/menu/team-icon.png";
import unitIcon from "../../../assets/images/menu/unit-type-icon.png";
import {
  useMemberPermissions,
  useOrganizationPermissions,
} from "../../../hooks";
import UserProfile from "../userprofile/UserProfile";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideNav = ({
  imgState,
  userName,
  img,
  propertyImage,
  name,
  getUserInfo,
  handleMenuSide,
  menuToggle,
  showMenuSideNav,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { unitTypeId, organizationId } = useParams();

  const { getPermissions } = useMemberPermissions(organizationId);
  const { getOrganizationPermissions } =
    useOrganizationPermissions(organizationId);
  const {
    bookingRead,
    calendarRead,
    propertiesRead,
    guestsRead,
    invoicesRead,
    statsRead,
    teamRead,
  } = getPermissions();
  const {
    booking: bookingPermission,
    calendar: calendarPermission,
    properties: propertiesPermission,
    team: teamPermission,
    invoice: invoicePermission,
    stats: statsPermission,
    guests: guestsPermission,
  } = getOrganizationPermissions();

  const hideBooking = () => {
    return !(bookingPermission === 1 && bookingRead === 1);
  };
  const hideCalendar = () => {
    return !(calendarPermission === 1 && calendarRead === 1);
  };
  const hideInvoice = () => {
    return !(invoicePermission === 1 && invoicesRead === 1);
  };

  const hideTeam = () => {
    return !(teamPermission === 1 && teamRead === 1);
  };

  const hideStats = () => {
    return !(statsPermission === 1 && statsRead === 1);
  };

  const hideProperties = () => {
    return !(propertiesPermission === 1 && propertiesRead === 1);
  };

  const hideGuests = () => {
    return !(guestsPermission === 1 && guestsRead === 1);
  };

  const [nav, setNav] = useState(false);
  const [ratesNav, setRatesNav] = useState(false);
  const [propertyNav, setPropertyNav] = useState(false);

  const changeMenu = useCallback(() => {
    const { pathname } = window.location;
    if (
      (pathname === "/property" ||
        pathname === "/unittype" ||
        pathname === "/services" ||
        pathname === "/groups" ||
        pathname === "/task") &&
      nav === false
    ) {
      setNav(true);
    }
  }, [nav]);

  const changeMenuUnitTypes = useCallback(() => {
    const { pathname } = window.location;
    if (
      (pathname === "/addunittype" ||
        pathname === "/rates" ||
        pathname === "/season-rates" ||
        pathname === "/create-season-rates") &&
      ratesNav === false
    ) {
      setRatesNav(true);
    }
  }, [ratesNav]);

  const changeMenuProperty = useCallback(() => {
    const { pathname } = window.location;
    if (
      (pathname.includes("overview") ||
        pathname.includes("location") ||
        pathname.includes("photos") ||
        pathname.includes("rates") ||
        pathname.includes("season-rates") ||
        pathname.includes("/services")) &&
      propertyNav === false
    ) {
      setPropertyNav(true);
    }
  }, [propertyNav]);

  useEffect(() => {
    changeMenu();
    changeMenuUnitTypes();
    changeMenuProperty();
  }, [changeMenu, changeMenuUnitTypes, changeMenuProperty]);

  const handleMenu = (e) => {
    if (e === "open") {
      setNav(true);
    } else if (e === "close") {
      setNav(false);
    } else if (e === "toggle") {
      setNav(!nav);
    }
  };

  const homePage = () => {
    history.push(`/organizations/${organizationId}/booking`);
  };

  const handleBack = () => {
    history.push(`/organizations/${organizationId}/properties`);
    localStorage.removeItem("property");
    handleMenu("close");
  };

  return (
    <Sider
      theme="light"
      trigger={null}
      collapsible
      className={`side-menu ${menuToggle ? "menu-show" : ""}`}
    >
      <div className="sidebar-logo">
        <img
          className="logo"
          src={logo}
          alt="logo"
          onClick={homePage}
          role="presentation"
        />
        <img
          className="close-icon"
          src={closeIcon}
          alt="close"
          onClick={() => handleMenuSide("close")}
          role="presentation"
          aria-hidden="true"
        />
      </div>

      <UserProfile
        userName={userName}
        imgState={imgState}
        img={img}
        propertyImg={propertyImage}
        name={name}
        getUserInfo={getUserInfo}
      />
      {showMenuSideNav ? (
        <>
          <Menu
            className={`main-menu ${
              nav || ratesNav || propertyNav ? "hide" : ""
            }`}
            theme="light"
            mode="inline"
            style={{ height: "100%" }}
          >
            <Menu.Item className="booking-nav" hidden={hideBooking()}>
              <img src={bookingIcon} alt="booking-icon" />
              <Link to="booking">{t("sidebar.menu1")}</Link>
            </Menu.Item>

            <Menu.Item className="calendar-nav" hidden={hideCalendar()}>
              <img src={calenderIcon} alt="calendar-icon" />
              <Link to="calendar">{t("sidebar.menu2")}</Link>
            </Menu.Item>

            <Menu.Item className="property-nav" hidden={hideProperties()}>
              <img src={propertyIcon} alt="property-icon" />
              <Link to="properties">{t("sidebar.menu3")}</Link>
            </Menu.Item>

            <SubMenu
              hidden={hideGuests()}
              title={
                <div>
                  <img src={guestIcon} alt="guest-icon" />
                  {t("sidebar.menu4")}
                </div>
              }
            >
              <Menu.Item className="guest-nav" hidden={hideGuests()}>
                <img src={submenuGuest} alt="guest-icon" />
                <Link to="guests">{t("sidebar.menu4")}</Link>
              </Menu.Item>
              <Menu.Item className="company-nav" hidden={hideGuests()}>
                <img src={submenuCompany} alt="guest-icon" />
                <Link to="companies">
                  {t("sidebar.companies", "Companies")}
                </Link>
              </Menu.Item>
            </SubMenu>

            {/* <Menu.Item className="channel-nav" hidden={hideChannel}>
              <img src={channelIcon} alt="channel" />
              <Link to="channel">{t("sidebar.menu14")}</Link>
            </Menu.Item> */}
            <Menu.Item className="team-nav" hidden={hideTeam()}>
              <img src={teamIcon} alt="team" />
              <Link to="team">{t("sidebar.menu5")}</Link>
            </Menu.Item>

            {/* <Menu.Item className="invoice-nav" hidden={hideInvoice()}>
              <img src={invoiceIcon} alt="invoice-icon" />
              <Link to="invoice">{t("sidebar.menu6")}</Link>
            </Menu.Item> */}

            {/* <Menu.Item className="stats-nav" hidden={hideStats()}>
              <img src={statsIcon} alt="stats=icon" />
              <Link to="stats">{t("sidebar.menu7")}</Link>
            </Menu.Item> */}
          </Menu>

          <Menu
            className={`main-menu-mbl ${propertyNav ? "show" : ""}`}
            theme="light"
            mode="inline"
            style={{ height: "100%" }}
          >
            <span
              className="submenu-heading"
              // onClick={() => handleMenu('close')}
              role="presentation"
              onClick={handleBack}
            >
              <Link
                to={`/organizations/${organizationId}/properties`}
                onClick={() => localStorage.removeItem("property")}
              >
                <ArrowLeftOutlined /> {t("sidebar.back_property", "Property")}
              </Link>
            </span>
            <Menu.Item className="overview-nav">
              <img src={propertyIcon} alt="property" />
              <div className="menu-dot" />
              <Link
                to={`/organizations/${organizationId}/properties/${unitTypeId}/overview`}
              >
                {t("sidenav.menu1")}
              </Link>
            </Menu.Item>

            <Menu.Item className="location-nav">
              <img src={statsIcon} alt="property" />
              <div className="menu-dot" />
              <Link
                to={`/organizations/${organizationId}/properties/${unitTypeId}/location`}
              >
                {t("sidenav.menu2")}
              </Link>
            </Menu.Item>

            <Menu.Item className="photos-nav">
              <img src={guestIcon} alt="unit" />
              <div className="menu-dot" />
              <Link
                to={`/organizations/${organizationId}/properties/${unitTypeId}/photos`}
              >
                {t("sidenav.menu3")}
              </Link>
            </Menu.Item>

            <Menu.Item className="rates-nav">
              <img src={unitIcon} alt="unit" />
              <div className="menu-dot" />
              <Link
                to={`/organizations/${organizationId}/properties/${unitTypeId}/rates`}
              >
                {t("sidenav.menu4")}
              </Link>
            </Menu.Item>

            <Menu.Item className="season-nav">
              <img src={teamIcon} alt="channel" />
              <Link
                to={`/organizations/${organizationId}/properties/${unitTypeId}/season-rates`}
              >
                {t("sidenav.menu5")}
              </Link>
            </Menu.Item>
            <Menu.Item className="service-nav">
              <ApartmentOutlined />{" "}
              <Link
                to={`/organizations/${organizationId}/properties/${unitTypeId}/services`}
              >
                {t("sidebar.menu15")}
              </Link>
            </Menu.Item>
          </Menu>
        </>
      ) : null}

      <div className="company-ip">
        <h6 className="text-bold">{`PropsAtlas v${packageJson.version}`}</h6>
      </div>
    </Sider>
  );
};

SideNav.propTypes = {
  userName: PropTypes.string,
  imgState: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  img: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  propertyImage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  name: PropTypes.string,
  getUserInfo: PropTypes.func,
  menuToggle: PropTypes.bool,
  handleMenuSide: PropTypes.func,
  showMenuSideNav: PropTypes.bool,
  organizationId: PropTypes.string,
};

SideNav.defaultProps = {
  userName: "",
  imgState: "",
  img: "",
  propertyImage: "",
  name: "",
  getUserInfo: () => {},
  handleMenuSide: () => {},
  menuToggle: false,
  showMenuSideNav: true,
  organizationId: "",
};

export default SideNav;
