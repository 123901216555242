import "./organization.css";

import { EllipsisOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Card, Dropdown, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  deleteOrganization,
  updateOrganization,
} from "../../api/organizations";
import propertyPlace from "../../assets/images/property-placeholder.png";
import { organizationInstance } from "../../axios/axiosConfig";
import { truncateText } from "../../utils/strUtils";
import Loading from "../Loading";
import Meta from "../meta";
import Wrapper from "../wrapper";
import CreateOrganizationButton from "./CreateOrganizationButton";
import CreateOrganizationPopup from "./CreateOrganizationPopup";
import DeleteOrganizationPopup from "./DeleteOrganizationPopup";
import EditOrganizationPopup from "./EditOrganizationPopup";

const OrganizationList = () => {
  const userId = localStorage.getItem("userId");
  const [organizations, setOrganizations] = useState([]);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCreateOrganization, setShowCreateOrganization] = useState(false);
  const [showEditOrganization, setShowEditOrganization] = useState(false);
  const [showDeleteOrganization, setShowDeleteOrganization] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const deleteOrganizationMutation = useMutation(
    (organizationId) => deleteOrganization(organizationId),
    {
      onSuccess: (data) => {
        if (data.code === 200) {
          getOrganizations();
          toast.success(
            t(
              "organizations.delete_success",
              "Delete organization successfully"
            ),
            {
              containerId: "B",
              toastId: "B",
            }
          );
        } else {
          toast.error(t("strings.server_error", "Something went wrong!"), {
            containerId: "B",
            toastId: "B",
          });
        }
        setShowDeleteOrganization(false);
      },
    }
  );
  const updateOrganizationMutation = useMutation(
    ({ organizationId, values }) => updateOrganization(organizationId, values),
    {
      onSuccess: (data) => {
        if (data.code === 200) {
          getOrganizations();
          toast.success(
            t(
              "organizations.update_success",
              "Update organization successfully"
            ),
            {
              containerId: "B",
              toastId: "B",
            }
          );
        } else {
          toast.error(t("strings.server_error", "Something went wrong!"), {
            containerId: "B",
            toastId: "B",
          });
        }
        setShowEditOrganization(false);
      },
    }
  );

  const getOrganizations = async () => {
    setLoading(true);
    const organizationsResponse = await organizationInstance.get("/", {
      params: { limit: 300, offset: 0 },
    });
    const statusCode = organizationsResponse.data.code;
    if (statusCode === 200) {
      setOrganizations(organizationsResponse.data.organizations);
    } else if (statusCode === 402) {
      localStorage.removeItem("token");
      history.push("/");
    } else {
      toast.error(t("strings.server_error", "Something went wrong!"), {
        containerId: "B",
        toastId: "B",
      });
    }

    setLoading(false);
  };

  const getPlanColor = (plan) => {
    switch (plan) {
      case "basic":
        return "gold";
      default:
        return "green";
    }
  };

  const menuDropdown = (organizationId) => {
    return [
      {
        label: t("strings.edit", "Edit"),
        key: `edit-${organizationId}`,
        onClick: () => setShowEditOrganization(true),
      },
      {
        label: t("strings.delete", "Delete"),
        key: `delete-${organizationId}`,
        onClick: () => {
          setShowDeleteOrganization(true);
        },
      },
    ];
  };

  const handleCreateNewOrganization = async (values) => {
    const response = await organizationInstance.post("/create", values);
    if (response.data.code === 200) {
      toast.success(
        t("organizations.create_success", "Successfully create a organization"),
        {
          containerId: "B",
          toastId: "B",
        }
      );
      setShowCreateOrganization(false);
      getOrganizations();
    } else {
      toast.error(t("strings.server_error", "Something went wrong!"), {
        containerId: "B",
        toastId: "B",
      });
    }
  };

  const handleUpdateOrganization = async (values) => {
    updateOrganizationMutation.mutate({
      organizationId: currentOrganization.id,
      values,
    });
  };

  const goToBooking = (organizationId) => {
    history.push(`/organizations/${organizationId}/booking`);
  };

  useEffect(() => {
    if (userId) {
      getOrganizations();
    }
  }, [userId]);

  if (loading) {
    return <Loading />;
  }

  if (organizations.length === 0) {
    return (
      <Wrapper showMenuSideNav={false}>
        <Meta />
        <div className="add-organization-page">
          <div className="add-new-organization">
            <img src={propertyPlace} alt="organization" />
            <h4>{t("organizations.header", "Organization")}</h4>
            <p>
              {t(
                "organizations.no_organizations",
                "Currently, you do not have any organization created"
              )}
            </p>
            <CreateOrganizationButton
              onClick={() => setShowCreateOrganization(true)}
            />
          </div>
          <CreateOrganizationPopup
            visible={showCreateOrganization}
            handleCancel={() => setShowCreateOrganization(false)}
            close={() => setShowCreateOrganization(false)}
            handleOk={handleCreateNewOrganization}
          />
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper showMenuSideNav={false}>
      <Meta />
      <div>
        <div>
          <CreateOrganizationButton
            onClick={() => setShowCreateOrganization(true)}
          />
        </div>
        <div className="list-organizations">
          {organizations.map((organization) => {
            return (
              <Card
                className="organization-card"
                hoverable={true}
                style={{ marginRight: 16 }}
                key={organization.id}
              >
                <div className="header-organ-card">
                  <h4 onClick={() => goToBooking(organization.id)}>
                    {truncateText(organization.name, 30)}
                  </h4>
                  <Tag
                    onClick={() => goToBooking(organization.id)}
                    color={getPlanColor(organization.planType)}
                  >
                    {organization.planType}
                  </Tag>
                  <div>
                    <Dropdown
                      menu={{ items: menuDropdown(organization.id) }}
                      trigger={["click"]}
                    >
                      <div className="ant-dropdown-link">
                        <EllipsisOutlined
                          onClick={() => setCurrentOrganization(organization)}
                        />
                      </div>
                    </Dropdown>
                  </div>
                </div>
                <p onClick={() => goToBooking(organization.id)}>
                  {truncateText(organization.description, 100)}
                </p>
              </Card>
            );
          })}
        </div>
        <CreateOrganizationPopup
          visible={showCreateOrganization}
          handleCancel={() => setShowCreateOrganization(false)}
          close={() => setShowCreateOrganization(false)}
          handleOk={handleCreateNewOrganization}
        />
        <EditOrganizationPopup
          visible={showEditOrganization}
          handleCancel={() => setShowEditOrganization(false)}
          close={() => setShowEditOrganization(false)}
          handleOk={handleUpdateOrganization}
          organization={currentOrganization}
        />
        <DeleteOrganizationPopup
          dataObject={currentOrganization}
          visible={showDeleteOrganization}
          handleOk={() => {
            console.log("Delete");
            deleteOrganizationMutation.mutate(currentOrganization.id);
          }}
          handleCancel={() => setShowDeleteOrganization(false)}
          cancel={() => setShowDeleteOrganization(false)}
        />
      </div>
    </Wrapper>
  );
};

export default OrganizationList;
