import { v4 as uuidv4 } from "uuid";

export const truncateText = (input, length = 200) => {
  if (input.length > length) {
    return input.substring(0, length) + "...";
  }
  return input;
};

export const randomId = () => {
  return uuidv4();
};
