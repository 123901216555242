import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addService, deleteService } from "../api/properties";
import { StoreKeys } from "../storeKeys";

export const useMutateProperty = () => {
  const queryClient = useQueryClient();

  const addServiceMutation = useMutation((data) => addService(data), {
    onSuccess: (_res, variables) => {
      queryClient.invalidateQueries({
        queryKey: [StoreKeys.GET_SERVICES, variables.unitTypeId],
      });
    },
  });

  const deleteServiceMutation = useMutation((data) => deleteService(data), {
    onSuccess: (_res, _variables) => {
      queryClient.invalidateQueries(StoreKeys.GET_SERVICES);
    },
  });

  return { addServiceMutation, deleteServiceMutation };
};
