import { useQuery } from "@tanstack/react-query";
import { Alert } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { getTrialDays } from "../../api/organizations";
import { StoreKeys } from "../../storeKeys";
import "./alert.css";

const AlertBox = ({ organizationId }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [hideBanner, setHideBanner] = useState(false);

  const { data } = useQuery({
    queryKey: [StoreKeys.GET_ORGANIZATION_STATUS, organizationId],
    queryFn: () => getTrialDays(organizationId),
  });

  if (data && data.code === 200) {
    const { isOnTrial, isFree } = data;
    if (!isOnTrial || isFree) {
      localStorage.setItem("collapse", 1);
    }
  }

  useEffect(() => {
    if (
      location.pathname === "/billing-information" ||
      location.pathname === "/contact-admin" ||
      location.pathname === "/organizations" ||
      location.pathname === "/profile"
    ) {
      setHideBanner(true);
    }
  }, [location]);

  const handleClose = () => {
    localStorage.setItem("collapse", 1);
  };

  const isCollapsed = localStorage.getItem("collapse") || false;

  return (
    <>
      {parseInt(isCollapsed, 10) !== 1 && data ? (
        <div className="alert-box" hidden={hideBanner}>
          <Alert
            message={`${t("propertylist.alert")} ${data.remainingDays || 0} ${t(
              "strings.days"
            )}`}
            description={
              <Link to="/contact-admin">{t("propertylist.contact_admin")}</Link>
            }
            type="warning"
            showIcon
            closable
            onClose={() => handleClose}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

AlertBox.propTypes = {
  organizationId: PropTypes.string,
};

AlertBox.defaultProps = {
  organizationId: undefined,
};

export default AlertBox;
