import { companyInstance } from "../axios/axiosConfig";

export const getCompanies = async (organizationId, limit = 30, offset = 0) => {
  const res = await companyInstance.get("/", {
    params: { organizationId, limit, offset },
  });
  return res.data;
};

export const updateCompany = async (values) => {
  const res = await companyInstance.post("/add", values);
  return res.data;
};

export const deleteCompany = async (companyId) => {
  const res = await companyInstance.post("/delete", {
    id: companyId,
  });
  return res.data;
};
