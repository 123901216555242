import { LeftOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
} from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { propertyInstance } from "../../axios/axiosConfig";
import currenciesList from "../../utils";
import Meta from "../meta";
import Wrapper from "../wrapper";
import "./rates.css";

const { RangePicker } = DatePicker;

const CreateSeasonRates = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { organizationId, unitTypeId } = useParams();
  const querySearchValues = queryString.parse(window.location.search);
  const { seasonRateId } = querySearchValues;

  const [form] = Form.useForm();
  const [showCustomNights, setShowCustomNights] = useState(false);
  const [disabledInput, setDisabledInput] = useState(true);
  const [nights, setNights] = useState(14);
  const [lengthOfStay, setLengthOfStay] = useState(false);
  const [pricePerDayWeek, setPricePerDayWeek] = useState(false);
  const [minStayPerWeek, setMinStayPerWeek] = useState(false);
  const [occupancy, setOccupancy] = useState(false);
  const [shortStay, setShortStay] = useState(false);
  const [restriction, setRestriction] = useState(false);
  const [currency, setCurrency] = useState("");

  const goBack = () => {
    history.push(
      `/organizations/${organizationId}/properties/${unitTypeId}/season-rates`
    );
  };

  const days = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };
  const [checkInBox, setCheckInBox] = useState(days);
  const [checkOutBox, setCheckOutBox] = useState(days);
  const [selectAll, setSelectAll] = useState({
    checkIn: false,
    checkOut: false,
  });

  function customPeriodChange(e) {
    setShowCustomNights(e.target.checked);
    if (e.target.checked) {
      setDisabledInput(false);
    } else {
      setDisabledInput(true);
    }
    setNights(nights);
  }

  function customInputChange(e) {
    if (!e.target.value) {
      setShowCustomNights(false);
    } else {
      setShowCustomNights(true);
    }
    setNights(e.target.value);
  }

  const handleCheckInBox = (e) => {
    const day = e.target.id;
    if (day === "monday") {
      return checkInBox.monday
        ? setCheckInBox({ ...checkInBox, monday: false })
        : setCheckInBox({ ...checkInBox, monday: true });
    }
    if (day === "tuesday") {
      return checkInBox.tuesday
        ? setCheckInBox({ ...checkInBox, tuesday: false })
        : setCheckInBox({ ...checkInBox, tuesday: true });
    }
    if (day === "wednesday") {
      return checkInBox.wednesday
        ? setCheckInBox({ ...checkInBox, wednesday: false })
        : setCheckInBox({ ...checkInBox, wednesday: true });
    }
    if (day === "thursday") {
      return checkInBox.thursday
        ? setCheckInBox({ ...checkInBox, thursday: false })
        : setCheckInBox({ ...checkInBox, thursday: true });
    }
    if (day === "friday") {
      return checkInBox.friday
        ? setCheckInBox({ ...checkInBox, friday: false })
        : setCheckInBox({ ...checkInBox, friday: true });
    }
    if (day === "saturday") {
      return checkInBox.saturday
        ? setCheckInBox({ ...checkInBox, saturday: false })
        : setCheckInBox({ ...checkInBox, saturday: true });
    }
    if (day === "sunday") {
      return checkInBox.sunday
        ? setCheckInBox({ ...checkInBox, sunday: false })
        : setCheckInBox({ ...checkInBox, sunday: true });
    }
    return true;
  };

  const handleSelectAllCheckIn = () => {
    if (selectAll.checkIn) {
      deSelectAll();
    } else {
      selectall();
    }
    return selectAll.checkIn
      ? setSelectAll({ ...selectAll, checkIn: false })
      : setSelectAll({ ...selectAll, checkIn: true });
  };

  const selectall = () => {
    setCheckInBox({
      ...checkInBox,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    });
  };
  const deSelectAll = () => {
    setCheckInBox({
      ...checkInBox,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    });
  };

  const handleCheckOutBox = (e) => {
    const day = e.target.id;
    if (day === "monday") {
      return checkOutBox.monday
        ? setCheckOutBox({ ...checkOutBox, monday: false })
        : setCheckOutBox({ ...checkOutBox, monday: true });
    }
    if (day === "tuesday") {
      return checkOutBox.tuesday
        ? setCheckOutBox({ ...checkOutBox, tuesday: false })
        : setCheckOutBox({ ...checkOutBox, tuesday: true });
    }
    if (day === "wednesday") {
      return checkOutBox.wednesday
        ? setCheckOutBox({ ...checkOutBox, wednesday: false })
        : setCheckOutBox({ ...checkOutBox, wednesday: true });
    }
    if (day === "thursday") {
      return checkOutBox.thursday
        ? setCheckOutBox({ ...checkOutBox, thursday: false })
        : setCheckOutBox({ ...checkOutBox, thursday: true });
    }
    if (day === "friday") {
      return checkOutBox.friday
        ? setCheckOutBox({ ...checkOutBox, friday: false })
        : setCheckOutBox({ ...checkOutBox, friday: true });
    }
    if (day === "saturday") {
      return checkOutBox.saturday
        ? setCheckOutBox({ ...checkOutBox, saturday: false })
        : setCheckOutBox({ ...checkOutBox, saturday: true });
    }
    if (day === "sunday") {
      return checkOutBox.sunday
        ? setCheckOutBox({ ...checkOutBox, sunday: false })
        : setCheckOutBox({ ...checkOutBox, sunday: true });
    }
    return true;
  };

  const handleSelectAllCheckOut = () => {
    if (selectAll.checkOut) {
      deSelectAllCheckOut();
    } else {
      selectallCheckOut();
    }
    return selectAll.checkOut
      ? setSelectAll({ ...selectAll, checkOut: false })
      : setSelectAll({ ...selectAll, checkOut: true });
  };

  const selectallCheckOut = () => {
    setCheckOutBox({
      ...checkOutBox,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    });
  };
  const deSelectAllCheckOut = () => {
    setCheckOutBox({
      ...checkOutBox,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    });
  };

  const onFinish = async (values) => {
    const params = queryString.parse(window.location.search);
    values.id = params.seasonRateId;
    values.unitTypeId = unitTypeId;
    values.checkInOnMonday = checkInBox.monday;
    values.checkInOnTuesday = checkInBox.tuesday;
    values.checkInOnWednesday = checkInBox.wednesday;
    values.checkInOnThursday = checkInBox.thursday;
    values.checkInOnFriday = checkInBox.friday;
    values.checkInOnSaturday = checkInBox.saturday;
    values.checkInOnSunday = checkInBox.sunday;
    values.checkOutOnMonday = checkOutBox.monday;
    values.checkOutOnTuesday = checkOutBox.tuesday;
    values.checkOutOnWednesday = checkOutBox.wednesday;
    values.checkOutOnThursday = checkOutBox.thursday;
    values.checkOutOnFriday = checkOutBox.friday;
    values.checkOutOnSaturday = checkOutBox.saturday;
    values.checkOutOnSunday = checkOutBox.sunday;
    values.extraChargeOnGuest = values.extraCharge;
    values.currency = getSymbolFromCurrency(values.currencyCode);

    if (values.customNightsPrice) {
      values.customNights = nights;
    }

    const response = await propertyInstance.post("/addSeasonRates", values);
    const statusCode = response.data.code;
    if (statusCode === 200) {
      toast.success(
        t(
          "createseasonrates.create_success",
          "Season rates update successfully"
        ),
        { containerId: "B" }
      );
      // form.resetFields();
    } else {
      toast.error(t("strings.server_error", "Server error please try again"), {
        containerId: "B",
      });
    }
  };

  const fetchData = useCallback(async () => {
    if (seasonRateId !== undefined) {
      const response = await propertyInstance.get(
        `/getSeasonRate/${seasonRateId}`
      );
      if (response.data.code === 200) {
        const data = response.data.seasonRateData;
        let m1;
        let m2;
        if (data.startDate && data.endDate) {
          m1 = moment(data.startDate);
          m2 = moment(data.endDate);
        }
        setLengthOfStay(false);
        setPricePerDayWeek(false);
        setMinStayPerWeek(false);
        setOccupancy(false);
        setShortStay(false);
        setRestriction(false);
        if (data.discountPriceCustomNights) {
          setShowCustomNights(true);
          setNights(data.customNights);
        }

        setCurrency(data.currency);

        form.setFieldsValue({
          seasonRateName: data.seasonRateName,
          groupname: [m1, m2],
          currencyCode: data.currencyCode,
          pricePerNight: data.pricePerNight,
          minStay: data.minimumStay,
          weeklyPrice: data.discountPricePerWeek,
          monthlyPrice: data.discountPricePerMonth,
          customNightsPrice: data.discountPriceCustomNights,
          priceOnMon: data.priceOnMonday,
          priceOnTues: data.priceOnTuesday,
          priceOnWed: data.priceOnWednesday,
          priceOnThu: data.priceOnThursday,
          priceOnFri: data.priceOnFriday,
          priceOnSat: data.priceOnSaturday,
          priceOnSun: data.priceOnSunday,
          minStayOnMon: data.minimumStayOnMonday,
          minStayOnTues: data.minimumStayOnTuesday,
          minStayOnWed: data.minimumStayOnWednesday,
          minStayOnThu: data.minimumStayOnThursday,
          minStayOnFri: data.minimumStayOnFriday,
          minStayOnSat: data.minimumStayOnSaturday,
          minStayOnSun: data.minimumStayOnSunday,
          extraCharge: data.extraChargeOnGuest,
          extraGuest: data.extraGuest,
          shortStayNight: data.shortStay,
          shortStayPrice: data.extraChargeOnStay,
        });
        setCheckInBox({
          ...checkInBox,
          monday: data.checkInOnMonday,
          tuesday: data.checkInOnTuesday,
          wednesday: data.checkInOnWednesday,
          thursday: data.checkInOnThursday,
          friday: data.checkInOnFriday,
          saturday: data.checkInOnSaturday,
          sunday: data.checkInOnSunday,
        });
        setCheckOutBox({
          ...checkOutBox,
          monday: data.checkOutOnMonday,
          tuesday: data.checkOutOnTuesday,
          wednesday: data.checkOutOnWednesday,
          thursday: data.checkOutOnThursday,
          friday: data.checkOutOnFriday,
          saturday: data.checkOutOnSaturday,
          sunday: data.checkOutOnSunday,
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  const onChangePricePerNight = (pricePerNight) => {
    form.setFieldsValue({
      priceOnMon: pricePerNight,
      priceOnTues: pricePerNight,
      priceOnWed: pricePerNight,
      priceOnThu: pricePerNight,
      priceOnFri: pricePerNight,
      priceOnSat: pricePerNight,
      priceOnSun: pricePerNight,
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, seasonRateId]);

  const handleCurrencySelect = (e) => {
    const symbol = getSymbolFromCurrency(e);
    setCurrency(symbol);
  };

  return (
    <Wrapper organizationId={organizationId}>
      <Meta>
        <body className="season-page-view" />
      </Meta>

      <div className="rates-page create-season-rate">
        <Row>
          <Col span={24}>
            <div className="rates-content">
              <Form form={form} onFinish={onFinish}>
                <div className="rate-first-section">
                  <div className="go-back" onClick={goBack} role="presentation">
                    <LeftOutlined />
                    {t("createseasonrates.back_btn", "Go back")}
                  </div>
                  <h3>
                    {t(
                      "createseasonrates.title_label",
                      "Season name and date periods"
                    )}
                  </h3>
                  <p>
                    {t(
                      "createseasonrates.create_description",
                      'Give your season a descriptive name, e.g. "High Season" or "Low Season" and define for which date period this season should apply to'
                    )}
                  </p>
                  <Row style={{ alignItems: "flex-end" }}>
                    <Col span={11}>
                      <Form.Item name="seasonRateName">
                        <Input
                          placeholder={t(
                            "createseasonrates.name_placeholder",
                            "e.g. High Season or Low Season"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={8}>
                      <Form.Item name="currencyCode">
                        <Select
                          placeholder="USD"
                          onSelect={handleCurrencySelect}
                        >
                          {currenciesList.map((currency) => (
                            <Select.Option
                              key={currency.value}
                              value={currency.value}
                            >
                              {currency.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="groupname"
                        label={t(
                          "createseasonrates.date_periods_lb",
                          "Date Periods"
                        )}
                      >
                        <RangePicker
                          defaultValue={moment()}
                          format="YYYY-MM-DD"
                          disabledDate={(current) =>
                            current && current < moment().subtract(1, "day")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <div className="rate-second-section">
                  <h3>
                    {t(
                      "createseasonrates.title_price_min_rates",
                      "Price per night & Min stay"
                    )}
                  </h3>
                  <p>
                    {t(
                      "createseasonrates.price_min_stay_desc",
                      "Set the default price per night and minimum stay guests."
                    )}
                  </p>
                  <Row>
                    <Col span={6}>
                      <Form.Item
                        name="pricePerNight"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "createseasonrates.price_per_night",
                              "Please enter price!"
                            ),
                          },
                        ]}
                      >
                        <Input
                          placeholder={currency}
                          onChange={(e) =>
                            onChangePricePerNight(e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={6}>
                      <Form.Item name="minStay">
                        <Input placeholder="nights 1" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <div className="toggle-box-section">
                  <h3>
                    {t(
                      "createseasonrates.length_stay_label",
                      "Length-of-stay prices"
                    )}
                    <Switch
                      // checked={lengthOfStay}
                      onClick={() => setLengthOfStay(!lengthOfStay)}
                    />
                  </h3>
                  <p>
                    {t(
                      "createseasonrates.length_stay_desc",
                      "Set discounted prices for reservations that are 7 nights or longer. Add additional prices per week and per month."
                    )}
                  </p>

                  <div
                    className={`toggle-content ${lengthOfStay ? "show" : ""}`}
                  >
                    <Row>
                      <Col span={6}>
                        <Form.Item
                          name="weeklyPrice"
                          label={t("strings.weekly", "Weekly")}
                        >
                          <Input placeholder={currency} />
                        </Form.Item>
                      </Col>
                      <Col span={1} />
                      <Col span={6}>
                        <Form.Item
                          name="monthlyPrice"
                          label={t("strings.monthly", "Monthly")}
                        >
                          <Input placeholder={currency} />
                        </Form.Item>
                      </Col>
                      <Col span={1} />
                      <Col span={6}>
                        <Form.Item
                          name="customNightsPrice"
                          label={`${nights} ${t("strings.nights", "Nights")}`}
                          hidden={!showCustomNights}
                        >
                          <Input placeholder={currency} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item className="custom-period">
                          <Checkbox
                            checked={showCustomNights}
                            onChange={customPeriodChange}
                          >
                            {t(
                              "createseasonrates.custom_nights_lb",
                              "Custom Period"
                            )}
                          </Checkbox>
                          <Input
                            value={nights}
                            onChange={customInputChange}
                            disabled={disabledInput}
                            defaultValue={nights}
                          />
                          <span>{t("strings.nights", "Nights")}</span>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="toggle-box-section">
                  <h3>
                    {t(
                      "createseasonrates.price_per_week_lb",
                      "Price per weekday"
                    )}
                    <Switch
                      // checked={pricePerDayWeek}
                      onClick={() => setPricePerDayWeek(!pricePerDayWeek)}
                    />
                  </h3>
                  <p>
                    {t(
                      "createseasonrates.price_per_weekday_des",
                      'Set here a different nightly rate depending on the day of the week. In case you have both "Price per night" and "Price per weekday" settings, "Price per weekday" will be applied.'
                    )}
                  </p>

                  <div
                    className={`toggle-content ${
                      pricePerDayWeek ? "show" : ""
                    }`}
                  >
                    <Row>
                      <Col span={24}>
                        <div className="weekend-input">
                          <Form.Item
                            label={t("rates.monday", "Mo")}
                            name="priceOnMon"
                            rules={[
                              {
                                required: true,
                                message: "Input must be valid number!",
                              },
                            ]}
                          >
                            <Input placeholder={currency} />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.tueday", "Tu")}
                            name="priceOnTues"
                            rules={[
                              {
                                required: true,
                                message: "Input must be valid number!",
                              },
                            ]}
                          >
                            <Input placeholder={currency} />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.wednesday", "We")}
                            name="priceOnWed"
                            rules={[
                              {
                                required: true,
                                message: "Input must be valid number!",
                              },
                            ]}
                          >
                            <Input placeholder={currency} />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.thursday", "Th")}
                            name="priceOnThu"
                            rules={[
                              {
                                required: true,
                                message: "Input must be valid number!",
                              },
                            ]}
                          >
                            <Input placeholder={currency} />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.friday", "Fr")}
                            name="priceOnFri"
                            rules={[
                              {
                                required: true,
                                message: "Input must be valid number!",
                              },
                            ]}
                          >
                            <Input placeholder={currency} />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.saturday", "Sa")}
                            name="priceOnSat"
                            rules={[
                              {
                                required: true,
                                message: "Input must be valid number!",
                              },
                            ]}
                          >
                            <Input placeholder={currency} />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.sunday", "Su")}
                            name="priceOnSun"
                            rules={[
                              {
                                required: true,
                                message: "Input must be valid number!",
                              },
                            ]}
                          >
                            <Input placeholder={currency} />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="toggle-box-section">
                  <h3>
                    {t(
                      "createseasonrates.min_stay_weekday_lb",
                      "Minimum stay per weekday"
                    )}
                    <Switch
                      // checked={minStayPerWeek}
                      onClick={() => setMinStayPerWeek(!minStayPerWeek)}
                    />
                  </h3>
                  <p>
                    {t(
                      "createseasonrates.min_stay_weekday_desc",
                      "Set a different minimum stay depending on the day of the week."
                    )}
                  </p>

                  <div
                    className={`toggle-content ${minStayPerWeek ? "show" : ""}`}
                  >
                    <Row>
                      <Col span={24}>
                        <div className="weekend-input">
                          <Form.Item
                            label={t("rates.monday", "Mo")}
                            name="minStayOnMon"
                          >
                            <Input placeholder="1" />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.tueday", "Tu")}
                            name="minStayOnTues"
                          >
                            <Input placeholder="1" />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.wednesday", "We")}
                            name="minStayOnWed"
                          >
                            <Input placeholder="1" />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.thursday", "Th")}
                            name="minStayOnThu"
                          >
                            <Input placeholder="1" />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.friday", "Fr")}
                            name="minStayOnFri"
                          >
                            <Input placeholder="1" />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.saturday", "Sa")}
                            name="minStayOnSat"
                          >
                            <Input placeholder="1" />
                          </Form.Item>
                          <Form.Item
                            label={t("rates.sunday", "Su")}
                            name="minStayOnSun"
                          >
                            <Input placeholder="1" />
                          </Form.Item>
                          <Form.Item>
                            <span>{t("strings.nights", "nights")}</span>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="toggle-box-section">
                  <h3>
                    {t("createseasonrates.occupancy_lb", "Occupancy")}
                    <Switch
                      // checked={occupancy}
                      onClick={() => setOccupancy(!occupancy)}
                    />
                  </h3>
                  <p>
                    {t(
                      "createseasonrates.occupancy_desc",
                      "Set an extra charge per Person per Night."
                    )}
                  </p>

                  <div className={`toggle-content ${occupancy ? "show" : ""}`}>
                    <Row align="middle">
                      <Col span={6}>
                        <Form.Item
                          className="occupancy-input"
                          name="extraCharge"
                        >
                          <Input placeholder={currency} />
                        </Form.Item>
                      </Col>
                      <Col span={1} />
                      <Col>
                        <p>{t("rates.span3", "after")}</p>
                      </Col>
                      <Col span={1} />
                      <Col span={6}>
                        <Form.Item name="extraGuest">
                          <Select placeholder={t("rates.placeholder13")}>
                            <Select.Option value={1}>
                              {t("rates.option3")}
                            </Select.Option>
                            <Select.Option value={2}>
                              {t("rates.option4")}
                            </Select.Option>
                            <Select.Option value={3}>
                              {t("rates.option5")}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="toggle-box-section">
                  <h3>
                    {t("createseasonrates.short_stay_lb", "Short stay premium")}
                    <Switch
                      // checked={shortStay}
                      onClick={() => setShortStay(!shortStay)}
                    />
                  </h3>
                  <p>
                    {t(
                      "createseasonrates.short_stay_desc",
                      "Increase your nightly rate if a guest stays only for a short time."
                    )}
                  </p>

                  <div className={`toggle-content ${shortStay ? "show" : ""}`}>
                    <Row>
                      <Col span={24}>
                        <span>
                          {t(
                            "createseasonrates.short_stay_desc1",
                            "A short stay has no more than"
                          )}
                        </span>
                        <Form.Item name="shortStayNight" className="stay-input">
                          <Input
                            type="number"
                            placeholder={t("strings.nights", "nights")}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <span>
                          {t(
                            "createseasonrates.short_stay_desc2",
                            "Extra charge per night"
                          )}
                        </span>
                        <Form.Item name="shortStayPrice" className="stay-input">
                          <Input type="number" placeholder={currency} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="toggle-box-section">
                  <h3>
                    {t(
                      "createseasonrates.in_out_restriction_lb",
                      "Check-in/Check-out restrictions"
                    )}
                    <Switch
                      // checked={restriction}
                      onClick={() => setRestriction(!restriction)}
                    />
                  </h3>
                  <p>
                    {t(
                      "createseasonrates.in_out_restriction_desc",
                      "Restrict check-ins and check-outs to certain days of the week."
                    )}
                  </p>

                  <div
                    className={`toggle-content ${restriction ? "show" : ""}`}
                  >
                    <Row>
                      <Col span={24}>
                        <div className="checkin-box">
                          <span className="checkin-label">Check in</span>
                          <div className="content-box-wrap">
                            <Form.Item
                              name="monday"
                              label={t("rates.monday", "Mo")}
                            >
                              <Checkbox
                                value={checkInBox.monday}
                                onChange={handleCheckInBox}
                                checked={checkInBox.monday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="tuesday"
                              label={t("rates.tueday", "Tu")}
                            >
                              <Checkbox
                                value={checkInBox.tuesday}
                                onChange={handleCheckInBox}
                                checked={checkInBox.tuesday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="wednesday"
                              label={t("rates.wednesday", "We")}
                            >
                              <Checkbox
                                value={checkInBox.wednesday}
                                onChange={handleCheckInBox}
                                checked={checkInBox.wednesday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="thursday"
                              label={t("rates.thursday", "Th")}
                            >
                              <Checkbox
                                value={checkInBox.thursday}
                                onChange={handleCheckInBox}
                                checked={checkInBox.thursday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="friday"
                              label={t("rates.friday", "Fr")}
                            >
                              <Checkbox
                                value={checkInBox.friday}
                                onChange={handleCheckInBox}
                                checked={checkInBox.friday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="saturday"
                              label={t("rates.saturday", "Sa")}
                            >
                              <Checkbox
                                value={checkInBox.saturday}
                                onChange={handleCheckInBox}
                                checked={checkInBox.saturday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="sunday"
                              label={t("rates.sunday", "Su")}
                            >
                              <Checkbox
                                value={checkInBox.sunday}
                                onChange={handleCheckInBox}
                                checked={checkInBox.sunday}
                              />
                            </Form.Item>

                            <Form.Item
                              className="check-selectall"
                              label={t("rates.label9", "Select all")}
                            >
                              <Checkbox
                                value={selectAll.checkIn}
                                onChange={handleSelectAllCheckIn}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="checkin-box">
                          <span className="checkin-label">Check out</span>
                          <div className="content-box-wrap">
                            <Form.Item
                              name="monday"
                              label={t("rates.monday", "Mo")}
                            >
                              <Checkbox
                                value={checkOutBox.monday}
                                onChange={handleCheckOutBox}
                                checked={checkOutBox.monday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="tuesday"
                              label={t("rates.tueday", "Tu")}
                            >
                              <Checkbox
                                value={checkOutBox.tuesday}
                                onChange={handleCheckOutBox}
                                checked={checkOutBox.tuesday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="wednesday"
                              label={t("rates.wednesday", "We")}
                            >
                              <Checkbox
                                value={checkOutBox.wednesday}
                                onChange={handleCheckOutBox}
                                checked={checkOutBox.wednesday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="thursday"
                              label={t("rates.thursday", "Th")}
                            >
                              <Checkbox
                                value={checkOutBox.thursday}
                                onChange={handleCheckOutBox}
                                checked={checkOutBox.thursday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="friday"
                              label={t("rates.friday", "Fr")}
                            >
                              <Checkbox
                                value={checkOutBox.friday}
                                onChange={handleCheckOutBox}
                                checked={checkOutBox.friday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="saturday"
                              label={t("rates.saturday", "Sa")}
                            >
                              <Checkbox
                                value={checkOutBox.saturday}
                                onChange={handleCheckOutBox}
                                checked={checkOutBox.saturday}
                              />
                            </Form.Item>
                            <Form.Item
                              name="sunday"
                              label={t("rates.sunday", "Su")}
                            >
                              <Checkbox
                                value={checkOutBox.sunday}
                                onChange={handleCheckOutBox}
                                checked={checkOutBox.sunday}
                              />
                            </Form.Item>

                            <Form.Item
                              className="check-selectall"
                              label={t("rates.label9", "Select all")}
                            >
                              <Checkbox
                                value={selectAll.checkOut}
                                onChange={handleSelectAllCheckOut}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="toggle-box-button">
                  <Button className="gray-btn" onClick={goBack}>
                    {t("strings.back", "Back")}
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {t("strings.save", "Save")}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default CreateSeasonRates;
