import { Button, Col, Form, Input, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { propertyInstance } from "../../axios/axiosConfig";
import "./rates.css";

const AddSeason = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { close, getData } = props;

  const onFinish = async (values) => {
    values.unitTypeId = props.unitTypeId;
    const response = await propertyInstance.post("/addSeasonRates", values);
    const statusCode = response.data.code;
    if (statusCode === 200) {
      toast.success(
        t("seasonrates.add_success", "Season rate added successfully"),
        {
          containerId: "B",
          toastId: "B",
        }
      );
      history.push(
        `/organizations/${props.organizationId}/properties/${props.unitTypeId}/create-season-rates?seasonRateId=${response.data.savedId}`
      );
      close();
      getData();
      form.resetFields();
    } else {
      toast.error(t("strings.server_error", "server error please try again"), {
        containerId: "B",
        toastId: "B",
      });
    }
  };

  return (
    <div className="add-season">
      <Form form={form} onFinish={onFinish}>
        <Row style={{ alignItems: "center" }}>
          <Col span={24}>
            <Form.Item
              label={t("seasonrates.season_name", "Season Name")}
              name="seasonRateName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t("seasonrates.name_error", "Please enter the name"),
                },
              ]}
            >
              <Input
                placeholder={t("seasonrates.season_name", "Season Name")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center", textAlign: "right" }}>
          <Col span={24}>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button
                className="border-btn"
                style={{ marginRight: 10 }}
                onClick={close}
              >
                {t("strings.cancel", "Cancel")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("strings.create", "Create")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

AddSeason.propTypes = {
  close: PropTypes.func,
  getData: PropTypes.func,
  unitTypeId: PropTypes.string,
  organizationId: PropTypes.string,
};
AddSeason.defaultProps = {
  close: () => {},
  getData: () => {},
  unitTypeId: "",
  organizationId: "",
};

export default AddSeason;
