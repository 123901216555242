import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Layout } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { getUserPermissions } from "../../api/organizations";
import { StoreKeys } from "../../storeKeys";
import SideNav from "../navbar/sidenav/SideNav";
import TopHeader from "../navbar/topheader/TopHeader";
import AlertBox from "./alert";

const { Content } = Layout;

const Wrapper = ({
  fun,
  children,
  onChange,
  userName,
  imgState,
  propertyImage,
  showMenuSideNav,
  organizationId,
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(() => getUserPermissions(organizationId), {
    onSuccess: (data, variables) => {
      if (data) {
        queryClient.setQueryData(
          [StoreKeys.GET_USER_PERMISSIONS, organizationId],
          data.permissions
        );
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (organizationId) {
      mutation.mutate();
    }
  }, [organizationId]);
  const handleChange = (newValue) => {
    onChange(newValue);
  };

  const [menuToggle, setMenuToggle] = useState(false);
  const handleMenuSide = (e) => {
    if (e === "open") {
      setMenuToggle(true);
    } else if (e === "close") {
      setMenuToggle(false);
    } else if (e === "toggle") {
      setMenuToggle(!menuToggle);
    }
  };

  return (
    <div className={`wrapper ${menuToggle ? "wrapper-expand" : ""}`}>
      <AlertBox organizationId={organizationId} />
      <Layout>
        <SideNav
          userName={userName}
          imgState={imgState}
          propertyImage={propertyImage}
          menuToggle={menuToggle}
          handleMenuSide={handleMenuSide}
          showMenuSideNav={showMenuSideNav}
        />
        <Layout className="site-layout">
          <TopHeader
            show={showMenuSideNav}
            onChange={handleChange}
            fun={fun}
            setMenuToggle={setMenuToggle}
            menuToggle={menuToggle}
          />
          <Content
            className="site-layout-background"
            style={{
              // margin: "24px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            <div className="content">{children}</div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

Wrapper.propTypes = {
  imgState: PropTypes.string,
  propertyImage: PropTypes.string,
  // getUserInfo: PropTypes.func,
  userName: PropTypes.string,
  fun: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.symbol,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  onChange: PropTypes.func,
  showMenuSideNav: PropTypes.bool,
  organizationId: PropTypes.string,
};

Wrapper.defaultProps = {
  imgState: "",
  propertyImage: "",
  // getUserInfo: () => {},
  userName: "",
  fun: () => {},
  children: "",
  onChange: () => {},
  showMenuSideNav: true,
  organizationId: "",
};

export default Wrapper;
