import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getServices, getUnitType } from "../api/properties";
import { unitTypesReceived } from "../slices/unitTypes";
import { StoreKeys } from "../storeKeys";

export const useQueryProperty = () => {
  const dispatch = useDispatch();

  const useGetServices = (unitTypeId) =>
    useQuery({
      queryKey: [StoreKeys.GET_SERVICES, unitTypeId],
      queryFn: () => getServices({ unitTypeId }),
      enabled: Boolean(unitTypeId),
      staleTime: 0,
      refetchOnWindowFocus: true,
    });

  const useGetUnitType = (unitTypeId) =>
    useQuery({
      queryKey: [StoreKeys.GET_UNIT_TYPE, unitTypeId],
      queryFn: () => getUnitType({ unitTypeId }),
      enabled: Boolean(unitTypeId),
      staleTime: 0,
      refetchOnWindowFocus: true,
      onSuccess: (data) => {
        if (data.code === 200 && data.unitType) {
          dispatch(unitTypesReceived({ unitTypes: [data.unitType] }));
        }
      },
    });

  return { useGetServices, useGetUnitType };
};
