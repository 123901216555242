import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import favicon from "../../assets/images/logo-mobile.png";

const Meta = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <link rel="icon" href={favicon} />
      <title>{t("seo.title")}</title>
      <meta name="description" content={t("seo.description")} />
      {children}
    </Helmet>
  );
};

export default Meta;
