import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getUserData } from "../api/users";
import { setUser } from "../slices/auth";
import { StoreKeys } from "../storeKeys";

export const useQueryAuth = () => {
  const dispatch = useDispatch();

  const useQueryMe = () =>
    useQuery({
      queryKey: [StoreKeys.GET_ME],
      queryFn: () => getUserData(),
      staleTime: Infinity,
      onSuccess: (data) => {
        if (data.code == 200 && data.user) {
          dispatch(setUser(data.user));
        }
      },
    });

  return { useQueryMe };
};
