import "./booking.css";

import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownSquareOutlined,
  EditOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  UpSquareOutlined,
} from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getProperties } from "../../api/properties";
import { useMutateBooking } from "../../hooks/useMutateBooking";
import { useQueryProperty } from "../../hooks/useQueryProperty";
import { setIsCreatingBooking } from "../../slices/bookings";
import { StoreKeys } from "../../storeKeys";
import { channelsList } from "../../utils/channelUtils";
import { randomId } from "../../utils/strUtils";
import GenderSelect from "../GenderSelect/GenderSelect";

const { Panel } = Collapse;

const { RangePicker } = DatePicker;

const CreateBookingPopup = ({ organizationId }) => {
  const isCreatingBooking = useSelector(
    (state) => state.booking.isCreatingBooking
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [queryProperty, setQueryProperty] = useState("");

  const [channel, setChannel] = useState("");
  const [channelCommission, setChannelCommission] = useState(null);
  const [guestsPanel, setGuestsPanel] = useState([1]);
  const [price, setPrice] = useState(0);
  const [night, setNight] = useState(0);
  const [amt, setAmt] = useState(0);

  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState("%");
  const [discountAmount, setDiscountAmount] = useState(0);

  const [accommodation, setAccommodation] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [currentPropertyName, setCurrentPropertyName] = useState("");
  const [unitName, setUnitName] = useState("");
  const [depositType, setDepositType] = useState("%");
  const [depositAmount, setDepositAmount] = useState(null);
  const [visibleGuest, setVisibleGuest] = useState(false);
  const [showOptional, setShowOptional] = useState(true);
  const [leftDays, setLeftDays] = useState(0);
  const [daysArr, setDaysArr] = useState([]);

  const [beginDate, setBeginDate] = useState(new Date());

  const [upDown, setUpDown] = useState(false);
  const [servicePanel, setServicePanel] = useState([
    {
      serviceName: "",
      servicePrice: 0,
      serviceQuantity: 0,
      serviceTax: 0,
      serviceTotal: 0,
      id: randomId(),
    },
  ]);
  const [totalServiceAmount, setTotalServiceAmount] = useState(0);

  const [currentService, setCurrentService] = useState({});
  const [unitData, setUnitData] = useState([]);
  const [currentPropertyId, setCurrentPropertyId] = useState(null);
  const [noOfAdult, setNoOfAdult] = useState(0);
  const [maxAdults, setMaxAdults] = useState(10);
  const [guestEle, setGuestEle] = useState(0);
  const [unitId, setUnitId] = useState(null);
  const [unitTypeId, setUnitTypeId] = useState(null);
  const [selectDisable, setSelectDisable] = useState(false);
  const [currency, setCurrency] = useState("$");
  const [currencyCode, setCurrencyCode] = useState("USD");

  const handleClosePopup = () => {
    dispatch(setIsCreatingBooking(false));
  };

  const hidePopUp = () => {
    close();
    setCalendarBookingDate([]);
    setSelectDisable(false);
    form.resetFields();
  };

  const addMoreGuest = () => {
    setGuestsPanel([...guestsPanel, randomId()]);
  };

  const guestForm = (value) => {
    setVisibleGuest(true);
    setGuestEle(value);
  };

  const removeGuestPanel = (el) => {
    setGuestsPanel(guestsPanel.filter((item) => item !== el));
  };

  const addMoreService = async () => {
    const newItem = {
      serviceName: "",
      servicePrice: 0,
      serviceQuantity: 0,
      serviceTax: 0,
      serviceTotal: 0,
      id: randomId(),
    };
    setServicePanel([...servicePanel, newItem]);
  };

  const removeServicePanel = (el, i) => {
    const newServices = servicePanel.filter((item) => item.id !== el.id);
    setServicePanel(newServices);

    const n = "serviceName";
    const p = "servicePrice";
    const q = "serviceQuantity";
    const tax = "serviceTax";
    const total = "serviceTotal";

    const lastIdx = newServices.length;

    newServices.forEach((item, i) => {
      form.setFieldsValue({
        [`${n}${i}`]: item.serviceName,
        [`${p}${i}`]: item.servicePrice,
        [`${q}${i}`]: item.serviceQuantity,
        [`${tax}${i}`]: item.serviceTax,
        [`${total}${i}`]: item.serviceTotal,
      });
    });
    form.setFieldsValue({
      [`${n}${lastIdx}`]: "",
      [`${p}${lastIdx}`]: "",
      [`${q}${lastIdx}`]: "",
      [`${tax}${lastIdx}`]: "",
      [`${total}${lastIdx}`]: "",
    });
  };

  const { useGetServices } = useQueryProperty();
  const { createBookingMutation } = useMutateBooking();
  const { data: servicesData, isFetching: isServicesFetching } =
    useGetServices(unitTypeId);

  const onFinish = async (values) => {
    values.perNight = price;
    values.night = night;
    values.amt = amt;
    values.discountType = discountType;
    values.discount = discount;
    values.deposit = deposit;
    values.depositType = depositType;
    values.accommodation = accommodation;
    values.totalAmount = accommodation + totalServiceAmount;

    const guestData = [];
    guestsPanel.forEach((el) => {
      guestData.push(values[el]);
    });
    values.guestData = guestData;
    if (guestData.length > 0) {
      values.guest = guestData[0].fullName;
    } else {
      values.guest = "No Guest";
    }

    values.serviceData = servicePanel;
    values.propertyId = currentPropertyId;
    values.channel = channel;
    values.commission = channelCommission;
    values.unitName = unitName;
    values.unit = unitId;
    values.adult = noOfAdult;
    values.currency = currency || "$";
    values.currencyCode = currencyCode || "USD";
    values.noOfGuest = guestData.length;
    values.noOfServices = servicePanel.length;
    values.propertyName = currentPropertyName;
    values.unitTypeId = unitTypeId;
    values.organizationId = organizationId;

    const result = await createBookingMutation.mutateAsync(values);
    if (result.code === 200) {
      toast.success(
        t("bookingpop.msg_success", "Booking created successfully!"),
        {
          containerId: "B",
        }
      );
    } else {
      toast.error(t("strings.server_error", "Some error occurred!"), {
        containerId: "B",
      });
    }
    cleanFormData();
    dispatch(setIsCreatingBooking(false));
  };

  const cleanFormData = () => {
    form.resetFields();
    setAccommodation(0);
    setPrice(0);
    setNight(0);
    setDiscount(0);
    setDepositAmount(0);
    setDeposit(0);
    setChannelCommission(0);
    setTotalServiceAmount(0);
    setServicePanel([
      {
        serviceName: "",
        servicePrice: 0,
        serviceQuantity: 0,
        serviceTax: 0,
        serviceTotal: 0,
      },
    ]);
  };

  const { data: propertiesData, isLoading: propertiesLoading } = useQuery({
    queryKey: [StoreKeys.GET_PROPERTIES, organizationId],
    queryFn: () => getProperties(organizationId, 1000, 0),
  });

  const {
    data: searchProperties,
    isFetching: searchPropertiesLoading,
    refetch: refetchSearchProperties,
  } = useQuery({
    queryKey: [StoreKeys.SEARCH_PROPERTIES, organizationId],
    queryFn: () => getProperties(organizationId, 30, 0, queryProperty),
  });

  const propertiesOptions = () => {
    if (searchProperties && searchProperties.properties) {
      const options = searchProperties.properties.map((el) => {
        const unitTypeName = (el.unitTypeName || []).filter(
          (e) => e.lang === "en"
        );

        return {
          label: unitTypeName.length > 0 ? unitTypeName[0].name : "__",
          key: el.id,
        };
      });

      return options;
    }
    return [];
  };

  const changeCurrency = useCallback(async () => {
    if (propertiesData && propertiesData.code === 200) {
      const [{ currency: unitCurrency, currencyCode: unitCurrencyCode }] =
        propertiesData.properties;

      setCurrency(unitCurrency);
      setCurrencyCode(unitCurrencyCode);
    }
  }, [propertiesData]);

  useEffect(() => {
    changeCurrency();
  }, [propertiesData]);

  useEffect(() => {
    if (discountType === "%") {
      setDiscountAmount((amt * discount) / 100);
    } else {
      setDiscountAmount(discount);
    }
  }, [discount, discountType, amt]);

  useEffect(() => {
    setAmt(price * night);
  }, [price, night]);

  // Calculate accommodation
  useEffect(() => {
    setAccommodation(amt - discountAmount);
  }, [amt, discountAmount]);

  // Calculate total service amount
  useEffect(() => {
    setTotalServiceAmount(
      (servicePanel || [])
        .map((item) => item.serviceTotal || 0)
        .reduce((a, b) => a + b, 0)
    );
  }, [servicePanel]);

  const onSelectProperty = async (value, event) => {
    console.log(event);
    propertiesData.properties
      .filter((el) => el.id === value)
      .map((filter) => {
        setUnitData(filter.unitData);
        setMaxAdults(filter.standardGuests);
        setCurrency(filter.currency);
        setCurrencyCode(filter.currencyCode);

        return true;
      });
    setCurrentPropertyName(event.children);
    setCurrentPropertyId(value);
    setUnitTypeId(value);
  };

  const onSelectServices = (value, i) => {
    (servicesData ? servicesData.services : [])
      .filter((el) => el.serviceName === value)
      .map((filterService) => setCurrentService(filterService));

    servicePanel.forEach((el, j) => {
      if (i === j) {
        el.serviceName = value;
      }
    });
    setServicePanel(servicePanel);
  };

  const onSelectAdult = (value) => {
    setNoOfAdult(value);
  };

  const onSelectUnit = async (value, event) => {
    const unitNameSelected = event.children;
    setUnitName(unitNameSelected);
    setUnitId(value);
  };

  const onSelectChannel = (value, event) => {
    setChannel(event.children);
  };
  const handleCommissionChange = (e) => {
    setChannelCommission(e.target.value);
  };

  useEffect(() => {
    if (depositType === "%") {
      const total = accommodation + totalServiceAmount;
      const data = (total * deposit) / 100;
      setDepositAmount(data);
    } else {
      setDepositAmount(deposit);
    }
  }, [depositType, deposit]);

  const disabledDate = (current) =>
    current > moment().subtract(18, "y") || current > moment();

  const onChangeDate = (value) => {
    if (value) {
      const d1 = new Date(value[0]._d);
      const d2 = new Date(value[1]._d);

      setBeginDate(d1);
      const diff = Math.abs(d1 - d2);
      const day = Math.floor(diff / (24 * 60 * 60 * 1000));
      setNight(day);
      setDaysArr(Array.from(Array(day).keys()));
    }
  };

  const onOptionalDate = (value) => {
    if (value) {
      const d1 = new Date(value._d);
      const d2 = new Date();
      const diff = Math.abs(d1 - d2);
      const day = Math.floor(diff / (24 * 60 * 60 * 1000)) + 1;
      setLeftDays(day);
    }
  };

  const priceFunction = (value) => {
    setPrice(value);
    daysArr.forEach((el, j) => {
      form.setFieldsValue({
        [`everyDayPrice${j}`]: value,
      });
    });
  };

  const priceSingleNight = (value, index) => {
    let sum = 0;
    Array.from(Array(night).keys()).forEach((i) => {
      sum += parseInt(form.getFieldValue([`everyDayPrice${i}`]), 10);
    });
    const newPricePerNight = sum / night;
    if (newPricePerNight) {
      form.setFieldsValue({
        perNight: newPricePerNight.toFixed(2),
      });
    }

    form.setFieldsValue({
      [`everyDayPrice${index}`]: value,
    });
    setPrice(newPricePerNight);
  };

  const onOk = () => {
    setVisibleGuest(false);
  };

  const onCancel = () => {
    setVisibleGuest(false);
  };

  const calculateServiceTotal = (price, quantity, tax) => {
    const nPrice = price || 0;
    const nQuantity = quantity || 0;
    const nTax = tax || 0;
    return nPrice * nQuantity + (nPrice * nQuantity * nTax) / 100;
  };

  const handleServiceQuantity = (e, ele, i) => {
    const newServicePanel = servicePanel.map((item, idx) => {
      if (idx === i) {
        const amount = calculateServiceTotal(
          item.servicePrice,
          e.target.value,
          item.serviceTax
        );
        form.setFieldsValue({
          [`serviceTotal${i}`]: amount,
        });
        return {
          ...item,
          serviceQuantity: e.target.value || 0,
          serviceTotal: amount,
        };
      }
      return item;
    });
    setServicePanel(newServicePanel);
  };

  const handleServicePrice = (value, i) => {
    const newServicesPanel = servicePanel.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          servicePrice: value,
          serviceTotal: calculateServiceTotal(
            value,
            item.serviceQuantity,
            item.serviceTax
          ),
        };
      }
      return item;
    });

    setServicePanel(newServicesPanel);
  };

  const handleServiceTax = (e, ele, i) => {
    const newServicesPanel = servicePanel.map((item, idx) => {
      if (idx === i) {
        const amount = calculateServiceTotal(
          item.servicePrice,
          item.serviceQuantity,
          e.target.value
        );

        form.setFieldsValue({
          [`serviceTotal${i}`]: amount,
        });
        return { ...item, serviceTax: e.target.value, serviceTotal: amount };
      }
      return item;
    });

    setServicePanel(newServicesPanel);
  };

  const createGuestDetails = (
    <>
      {guestsPanel.map((el) => (
        <div className="addi-box" id={el} key={el}>
          <Row style={{ alignItems: "center" }}>
            <Col span={6}>
              <Form.Item
                label={t("strings.full")}
                name={[el, "fullName"]}
                style={{ paddingRight: 20 }}
                rules={[
                  {
                    required: "true",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("strings.email")}
                name={[el, "email"]}
                style={{ paddingRight: 20 }}
                rules={[
                  {
                    required: "true",
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("strings.country")}
                name={[el, "country"]}
                style={{ paddingRight: 20 }}
                rules={[
                  {
                    required: "true",
                  },
                ]}
              >
                <Select showSearch value={form.getFieldValue([el, "country"])}>
                  {countryList()
                    .getData()
                    .map((ele) => (
                      <Select.Option value={ele.label} key={ele.label}>
                        {ele.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("strings.phone")}
                name={[el, "phone"]}
                style={{ paddingRight: 20 }}
                rules={[{ required: true }]}
              >
                <Input type="number" minLength="9" maxLength="15" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <div
                className="additional-edit"
                onClick={() => guestForm(el)}
                role="presentation"
              >
                <div>
                  <EditOutlined /> {t("bookingpop.heading2")}
                </div>
              </div>
            </Col>
          </Row>

          <div className="delete-data">
            <DeleteOutlined onClick={() => removeGuestPanel(el)} />
          </div>
          <Modal
            title={t("strings.guest")}
            open={visibleGuest}
            onOk={onOk}
            onCancel={onCancel}
            wrapClassName="right-modal-container"
          >
            <div style={{ padding: "0px 20px" }}>
              <Helmet>
                <body
                  className={isCreatingBooking ? "ant-scrolling-effect" : ""}
                />
              </Helmet>
              <Row style={{ alignItems: "center", paddingTop: 10 }}>
                <Col span={12}>
                  <Form.Item
                    label={t("strings.full")}
                    name={[guestEle, "fullName"]}
                    style={{ paddingRight: 20 }}
                    rules={[
                      {
                        required: true,
                        message: t("guestpopup.label1"),
                      },
                    ]}
                  >
                    <Input placeholder={t("strings.full")} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t("guestpopup.label2")}
                    name={[guestEle, "country"]}
                    rules={[
                      { required: true, message: t("guestpopup.label3") },
                    ]}
                  >
                    <Select
                      showSearch
                      value={form.getFieldValue([guestEle, "country"])}
                    >
                      {countryList()
                        .getData()
                        .map((ele) => (
                          <Select.Option value={ele.label} key={ele.label}>
                            {ele.label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ alignItems: "center" }}>
                <Col span={12}>
                  <Form.Item
                    label={t("strings.email")}
                    name={[guestEle, "email"]}
                    style={{ paddingRight: 20 }}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={t("strings.email")} type="email" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t("strings.phone")}
                    name={[guestEle, "phone"]}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={t("strings.phone")} type="number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ alignItems: "center" }}>
                <Col span={12}>
                  <Form.Item
                    name={[guestEle, "dob"]}
                    label={t("strings.dob")}
                    style={{ paddingRight: 20 }}
                    initialValue={moment().subtract(2, "years")}
                  >
                    <DatePicker disabledDate={disabledDate} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <GenderSelect
                    value={form.getFieldValue([guestEle, "gender"])}
                    defaultValue={1}
                    name={[guestEle, "gender"]}
                    label={t("strings.gender")}
                  />
                </Col>
              </Row>

              <Row style={{ alignItems: "center" }}>
                <Col span={12}>
                  <Form.Item
                    label={t("guestpopup.label4")}
                    name={[guestEle, "typeOfDoc"]}
                    style={{ paddingRight: 20 }}
                  >
                    <Select
                      placeholder={t("guestpopup.label4")}
                      name={[guestEle, "typeOfDoc"]}
                      rules={[
                        {
                          required: true,
                          message: t("guestpopup.label5"),
                        },
                      ]}
                    >
                      <Select.Option value="Passport">Passport</Select.Option>
                      <Select.Option value="ID Card">ID Card</Select.Option>
                      <Select.Option value="Driving License">
                        Driving License
                      </Select.Option>
                      <Select.Option value="Other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t("guestpopup.label6")}
                    name={[guestEle, "docNo"]}
                    // rules={[{ required: true, message: t("guestpopup.label5") }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ alignItems: "center" }}>
                <Col span={12}>
                  <Form.Item
                    label={t("guestpopup.label8")}
                    name={[el, "place"]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ alignItems: "center" }}>
                <Col span={24}>
                  <Form.Item label={t("strings.note")} name={[el, "notes"]}>
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ alignItems: "center", textAlign: "right" }}>
                <Col span={24}>
                  <Form.Item>
                    <Button style={{ marginRight: 10 }} onClick={onCancel}>
                      {t("strings.cancel")}
                    </Button>
                    <Button type="primary" onClick={onOk}>
                      {t("strings.save")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      ))}
    </>
  );

  return (
    <Modal
      title={t("bookingpop.heading")}
      name="modal1"
      open={isCreatingBooking}
      onCancel={handleClosePopup}
      wrapClassName="right-modal-container"
    >
      <Helmet>
        <body className={isCreatingBooking ? "ant-scrolling-effect" : ""} />
      </Helmet>
      <div className="cross-btn">
        <CloseOutlined onClick={handleClosePopup} />
      </div>
      <Form form={form} name="basic" onFinish={onFinish}>
        <Row
          style={{ alignItems: "center", padding: "0px 20px", marginTop: 10 }}
        >
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label={t("bookingpop.label1")}
              name="groupname"
              style={{ paddingRight: 20 }}
              onChange={onChangeDate}
              rules={[
                {
                  required: true,
                  message: t("bookingpop.rule1"),
                },
              ]}
            >
              <RangePicker
                disabled={selectDisable}
                defaultValue={moment()}
                format="YYYY-MM-DD"
                onChange={onChangeDate}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Radio.Group name="radiogroup" defaultValue={1}>
              <Radio value={1} onClick={() => setShowOptional(true)}>
                {t("strings.confirmed")}
              </Radio>
              <Radio value={2} onClick={() => setShowOptional(false)}>
                {t("strings.option")}
              </Radio>
            </Radio.Group>
          </Col>

          <Col span={24}>
            <div className="option-content" hidden={showOptional}>
              <p>
                <ClockCircleOutlined />{" "}
                {t("bookingpop.option_des", "Option is active until")}
              </p>
              <DatePicker
                disabledDate={(current) =>
                  current && current < moment().subtract(1, "day")
                }
                onChange={onOptionalDate}
              />
              <span>
                (days left:
                {leftDays})
              </span>
              <div className="option-tag">
                <CheckOutlined /> Confirmed
              </div>
            </div>
            <p className="checked-avail">
              {t(
                "bookingpop.warning_check",
                "*Availability is checked automatically"
              )}
            </p>
          </Col>
        </Row>

        <Row style={{ alignItems: "center", padding: "0px 20px" }}>
          <Col xs={{ span: 12 }} md={{ span: 8 }}>
            <Form.Item
              label={t("strings.property")}
              name="property"
              style={{ paddingRight: 20 }}
              rules={[
                {
                  required: true,
                  message: t("bookingpop.rule2"),
                },
              ]}
            >
              <Select
                showSearch={true}
                onSearch={(value) => {
                  setQueryProperty(value);
                  refetchSearchProperties();
                }}
                disabled={selectDisable}
                placeholder={t("strings.select")}
                filterOption={false}
                notFoundContent={
                  searchPropertiesLoading ? <Spin size="small" /> : null
                }
                onSelect={(value, event) => onSelectProperty(value, event)}
              >
                {propertiesOptions().map((el) => {
                  return (
                    <Select.Option value={el.key} key={el.key}>
                      {el.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 12 }} md={{ span: 8 }}>
            <Form.Item
              label={t("strings.unit")}
              name="unit"
              style={{ paddingRight: 20 }}
              rules={[
                {
                  required: true,
                  message: t("bookingpop.rule3"),
                },
              ]}
            >
              <Select
                disabled={selectDisable}
                placeholder={t("strings.select")}
                onSelect={(value, event) => onSelectUnit(value, event)}
              >
                {unitData &&
                  unitData.map((el) => (
                    <Select.Option value={el.id} key={el.id}>
                      {el.unitName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 20 }} md={{ span: 8 }}>
            <Form.Item
              className="commission"
              label={t("strings.chanel_commission")}
              name="channel"
            >
              <Select
                placeholder={t("strings.select")}
                onSelect={(value, event) => onSelectChannel(value, event)}
                style={{ width: "70%", display: "inline-block" }}
              >
                <Select.Option value="">Select</Select.Option>
                {channelsList.map((item, idx) => {
                  return (
                    <Select.Option key={idx} value={item.value}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>

              <Input
                placeholder="0,00"
                name="commission"
                style={{
                  width: "26%",
                  display: "inline-block",
                  verticalAlign: "top",
                  marginLeft: "4%",
                }}
                value={channelCommission}
                onChange={(e) => handleCommissionChange(e)}
                rules={[
                  {
                    required: true,
                    message: t("bookingpop.rule4"),
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center", padding: "0px 20px" }}>
          <Col span={8}>
            <Form.Item
              label={t("strings.adults")}
              name="adult"
              style={{ paddingRight: 20 }}
              rules={[
                {
                  required: true,
                  message: t("bookingpop.rule5"),
                },
              ]}
            >
              <Select
                placeholder={t("strings.select")}
                onSelect={(value, event) => onSelectAdult(value, event)}
              >
                {[...Array(maxAdults).keys()].map((item) => {
                  return (
                    <Select.Option key={item} value={item + 1}>
                      {item + 1}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={t("bookingpop.label16")}
              name="children1"
              style={{ paddingRight: 20 }}
            >
              <Select placeholder={t("strings.select")}>
                <Select.Option value="0">0</Select.Option>
                <Select.Option value="1">1</Select.Option>
                <Select.Option value="2">2</Select.Option>
                <Select.Option value="3">3</Select.Option>
                <Select.Option value="4">4</Select.Option>
                <Select.Option value="5">5</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label={t("bookingpop.label17")} name="children2">
              <Select placeholder={t("strings.select")}>
                <Select.Option value="0">0</Select.Option>
                <Select.Option value="1">1</Select.Option>
                <Select.Option value="2">2</Select.Option>
                <Select.Option value="3">3</Select.Option>
                <Select.Option value="4">4</Select.Option>
                <Select.Option value="5">5</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col span={24}>
            <Form.Item style={{ marginBottom: "0" }}>
              <Collapse defaultActiveKey={["1"]} accordion>
                <Panel
                  icon={<PlusSquareOutlined />}
                  header={t("bookingpop.label18")}
                  key="1"
                >
                  <div className="additional-guest">
                    {createGuestDetails}

                    <Row>
                      <Col span={24}>
                        <div
                          className="additional-add"
                          onClick={addMoreGuest}
                          role="presentation"
                        >
                          <PlusOutlined /> {t("bookingpop.label2")}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Panel>

                <Panel
                  icon={<PlusSquareOutlined />}
                  header={t("bookingpop.label19")}
                  key="2"
                >
                  <div className="add-notes">
                    <Form.Item name="notes1">
                      <Input.TextArea />
                    </Form.Item>
                  </div>
                </Panel>

                <Panel
                  icon={<PlusSquareOutlined />}
                  header={t("bookingpop.label20")}
                  key="3"
                  name="notes"
                >
                  <div className="add-notes">
                    <Form.Item name="notes2">
                      <Input.TextArea />
                    </Form.Item>
                  </div>
                </Panel>
              </Collapse>
            </Form.Item>
          </Col>
        </Row>

        <div className="accommodation">
          <Row style={{ alignItems: "center", padding: "0px 20px" }}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <Form.Item className="accommodation--header">
                <p>{t("bookingpop.label3")}</p>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 16 }}>
              <Form.Item>
                <div className="inline-form">
                  <label htmlFor="abc">
                    <input hidden />
                    {t("bookingpop.label4")}
                  </label>
                  <Form.Item name="perNight">
                    <Input
                      type="number"
                      placeholder="0,00"
                      value={price}
                      rules={[
                        {
                          required: true,
                          message: t("bookingpop.rule5"),
                        },
                      ]}
                      onChange={(e) => priceFunction(e.target.value)}
                    />
                  </Form.Item>
                  <label htmlFor="number">
                    <input hidden />X
                  </label>
                  <Input
                    type="number"
                    placeholder="0 nights"
                    name="nights"
                    value={night}
                    disabled
                    onChange={(e) => setNight(e.target.value)}
                  />
                  <label htmlFor="amount">
                    <input hidden />=
                  </label>

                  <Input name="totalAAmount" type="number" value={amt} />
                  <label htmlFor="usd">
                    <input hidden />
                    {currency}
                  </label>
                </div>

                <div className="inline-form">
                  <label htmlFor="discount">
                    <input hidden />
                    {t("bookingpop.label5")}
                  </label>
                  <Input
                    type="number"
                    placeholder="0,00"
                    value={discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                    }}
                  />
                  <label htmlFor="discount">
                    <input hidden />X
                  </label>
                  <Form.Item name="discountType">
                    <Select
                      placeholder={t("bookingpop.rule6")}
                      onSelect={(value) => setDiscountType(value)}
                      defaultValue={discountType}
                    >
                      <Select.Option value={currency}>{currency}</Select.Option>
                      <Select.Option value="%">%</Select.Option>
                    </Select>
                  </Form.Item>
                  <label htmlFor="equal">
                    <input hidden />=
                  </label>

                  <Input type="number" value={discountAmount} />

                  <label htmlFor="eur">
                    <input hidden />
                    {currency}
                  </label>
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col span={24}>
              <div className="per-night">
                <label htmlFor="night">
                  <DownSquareOutlined
                    hidden={upDown}
                    onClick={() => setUpDown(true)}
                  />
                  <UpSquareOutlined
                    hidden={!upDown}
                    onClick={() => setUpDown(!upDown)}
                  />
                  <input hidden />
                  {t("bookingpop.label6")}
                </label>
                <span>{t("bookingpop.label7")}:</span>
                <span className="amnt">
                  {accommodation} {currency}
                </span>
              </div>
              <div className="per-night-content" hidden={upDown}>
                <div className="night-container">
                  {daysArr.map((ele, j) => (
                    <div className="night-box" key={ele}>
                      <Form.Item
                        label={moment(beginDate).add(j, "day").format("DD/MM")}
                        name={`everyDayPrice${j}`}
                      >
                        <Input
                          type="number"
                          placeholder="0,00"
                          onChange={(e) => priceSingleNight(e.target.value, j)}
                        />
                      </Form.Item>
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col span={24}>
              <div
                className="srvice-heading"
                onClick={addMoreService}
                role="presentation"
              >
                <PlusOutlined /> {t("bookingpop.label8")}
              </div>
            </Col>

            <Col span={24}>
              <Form.Item style={{ marginBottom: "0" }}>
                <Collapse
                  defaultActiveKey={["1"]}
                  className="service-panel"
                  accordion
                >
                  <Panel
                    icon={<PlusSquareOutlined />}
                    header={t("bookingpop.services", "Services")}
                    key="1"
                  >
                    <div className="service-form">
                      {servicePanel &&
                        servicePanel.map((ele, i) => (
                          <div className="inline-form" key={ele}>
                            <div className="delete-data">
                              <DeleteOutlined
                                onClick={() => removeServicePanel(ele, i)}
                              />
                            </div>
                            <Col span={4}>
                              <Form.Item name={`serviceName${i}`}>
                                <Select
                                  style={{ width: "100px" }}
                                  placeholder={t("bookingpop.rule7")}
                                  loading={isServicesFetching}
                                  onSelect={(value) =>
                                    onSelectServices(value, i)
                                  }
                                >
                                  {(servicesData
                                    ? servicesData.services
                                    : []
                                  ).map((element) => (
                                    <Select.Option
                                      key={element.id}
                                      value={element.serviceName}
                                    >
                                      {element.serviceName}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item name={`servicePrice${i}`}>
                                <Select
                                  placeholder={t("bookingpop.rule9")}
                                  onSelect={(value) =>
                                    handleServicePrice(value, i)
                                  }
                                >
                                  <Select.Option
                                    value={currentService.servicePrice}
                                  >
                                    {currentService.servicePrice}
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <label htmlFor="x">
                              <input hidden />X
                            </label>
                            <Col span={4}>
                              <Form.Item name={`serviceQuantity${i}`}>
                                <Input
                                  type="number"
                                  placeholder={t("bookingpop.rule8")}
                                  onChange={(e) =>
                                    handleServiceQuantity(e, ele, i)
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <label htmlFor="plus">
                              <input hidden />+
                            </label>
                            <Col span={4}>
                              <Form.Item name={`serviceTax${i}`}>
                                <Input
                                  type="number"
                                  placeholder="Tax (%)"
                                  onChange={(e) => handleServiceTax(e, ele, i)}
                                />
                              </Form.Item>
                            </Col>

                            <label htmlFor="equal">
                              <input hidden />=
                            </label>

                            <Col span={4}>
                              <Form.Item name={`serviceTotal${i}`}>
                                <Input value={ele.serviceTotal} />
                              </Form.Item>
                            </Col>

                            <label htmlFor="eur">
                              <input hidden />
                              {currency}
                            </label>
                          </div>
                        ))}
                    </div>
                  </Panel>
                </Collapse>
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="amnt-total">
                <h4>
                  {t("bookingpop.label10", "Total")}:
                  {accommodation + totalServiceAmount} {currency}
                </h4>
              </div>

              <div className="deposit">
                <label htmlFor="discount">
                  <input hidden />
                  {t("bookingpop.label11")}
                </label>

                <div className="inline-form">
                  <label htmlFor="deposit">
                    <input hidden />
                    {t("bookingpop.label12")}
                  </label>

                  <Input
                    name="deposit"
                    type="number"
                    placeholder="0,00"
                    value={deposit}
                    onChange={(e) => {
                      setDeposit(e.target.value);
                    }}
                  />
                  <Form.Item name="depositType">
                    <Select
                      placeholder={t("bookingpop.label13")}
                      onSelect={(value) => setDepositType(value)}
                      defaultValue={depositType}
                    >
                      <Select.Option value={currency}>{currency}</Select.Option>
                      <Select.Option value="%">%</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Col>

            <Col span={24}>
              <div className="outstanding">
                <label htmlFor="depo">
                  {t("bookingpop.label12")}:{" "}
                  <span>
                    {depositType === "%"
                      ? `${depositAmount || 0}${currency} (${deposit || 0}%)`
                      : `${depositAmount || 0}${currency}`}
                  </span>
                </label>
                <label htmlFor="amount">
                  {t("bookingpop.label14")}:{" "}
                  <span>
                    {accommodation + totalServiceAmount - depositAmount}{" "}
                    {currency}
                  </span>
                </label>
              </div>
            </Col>
          </Row>
        </div>

        <Row
          style={{
            alignItems: "center",
            background: "#fbfbfc",
            padding: "0px 20px",
            paddingTop: "20px",
          }}
        >
          <Col span={24}>
            <Form.Item style={{ textAlign: "right" }}>
              <Button style={{ marginRight: 10 }} onClick={handleClosePopup}>
                {t("strings.cancel")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("strings.save")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

CreateBookingPopup.propTypes = {
  organizationId: PropTypes.string,
};

CreateBookingPopup.defaultProps = {
  organizationId: "",
};

export default CreateBookingPopup;
