import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import "./team.css";

const DeletePopup = (props) => {
  const { dataObject, cancel, visible, handleOk, handleCancel } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName="delete-modal"
    >
      <div className="delete-popup-box">
        <WarningOutlined />
        <h5>{t("strings.delete_title", "Delete this object?")}</h5>
        <Row>
          <Col span={24}>
            <Button style={{ marginRight: 50 }} onClick={cancel}>
              {t("strings.cancel", "Cancel")}
            </Button>
            <Button
              icon={<DeleteOutlined />}
              type="primary"
              onClick={dataObject}
            >
              {t("strings.delete_item", "Delete Item")}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

DeletePopup.propTypes = {
  dataObject: PropTypes.func,
  cancel: PropTypes.func,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
};
DeletePopup.defaultProps = {
  dataObject: () => {},
  cancel: () => {},
  visible: false,
  handleCancel: () => {},
  handleOk: () => {},
};
export default DeletePopup;
