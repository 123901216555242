import "antd/dist/antd.min.css";
import "./responsive.css";

import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import PageNotFound from "./components/404/404";
// import ChannelManager from './components/property/channelmanager';
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import Booking from "./components/booking/booking";
import CreateBookingPopup from "./components/booking/createbookingpopup";
import BookingFilter from "./components/booking/filter";
import GuestPopup from "./components/booking/guestpopup";
import CalendarNew from "./components/calendar/calendar-new";
import ChannelAirbnb from "./components/channelmanager/channelairbnb";
import ChannelBooking from "./components/channelmanager/channelbooking";
import ChannelExpedia from "./components/channelmanager/channelexpedia";
import Channel from "./components/channelmanager/channelmanager";
import Company from "./components/company/company";
import CrispChat from "./components/crisp-chat/CrispChat";
import Footer from "./components/footer/footer";
import Forget from "./components/forget/forget";
import CompanyList from "./components/guests/company";
import GuestList from "./components/guests/guests";
import Header from "./components/header/header";
import Invoice from "./components/invoice/invoice";
import Login from "./components/login/login";
import SideNav from "./components/navbar/sidenav/SideNav";
import OrganizationList from "./components/organization/OrganizationList";
import Owner from "./components/owner/owner";
import BillingInformation from "./components/profile/BillingInformation";
import ContactAdmin from "./components/profile/ContactAdmin";
import Profile from "./components/profile/profile";
import AddProperty from "./components/property/addproperty";
import AddUnitType from "./components/property/addunittype";
import CreateSeasonRates from "./components/property/createseasonrates";
import DeletePopup from "./components/property/deletepopup";
import Groups from "./components/property/group";
import Location from "./components/property/location";
import Overview from "./components/property/overview";
import Photos from "./components/property/photos";
import PropertyList from "./components/property/propertylist";
import Rates from "./components/property/rates";
import SeasonRates from "./components/property/seasonrates";
import Services from "./components/property/services";
import Task from "./components/property/task";
import UnitType from "./components/property/unittype";
import Register from "./components/register/register";
import Reset from "./components/reset/reset";
import Stats from "./components/stats/stats";
import Team from "./components/team/team";
import NotAuthorize from "./components/userlock/UserNotAuthorize";
import { LoginRoute, PrivateRoute } from "./Routes/PrivateRoute";
import { store } from "./store";

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error) => {
      console.log(error);
      toast.error(
        "Uh oh! Something went wrong. The issue has been logged and we'll fix it as soon as possible. Please try again later."
      );
    },
  }),
});

const App = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <CrispChat />
          <div className="main-wrapper">
            <>
              <Suspense fallback={null}>
                <Router>
                  <Header />
                  <main>
                    <div className="main_content">
                      <Switch>
                        <LoginRoute
                          exact
                          path="/"
                          component={() => <Login />}
                        />
                        <LoginRoute
                          exact
                          path="/company"
                          component={() => <Company />}
                        />
                        <LoginRoute
                          exact
                          path="/register"
                          component={() => <Register />}
                        />
                        <PrivateRoute
                          exact
                          path="/sidenav"
                          component={() => <SideNav />}
                        />

                        <PrivateRoute
                          exact
                          path="/addproperty"
                          component={() => <AddProperty />}
                          // feature={feature.properties}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/properties"
                          component={() => <PropertyList />}
                        />

                        <PrivateRoute
                          exact
                          path="/unittype"
                          component={() => <UnitType />}
                        />

                        <PrivateRoute
                          exact
                          path="/groups"
                          component={() => <Groups />}
                        />
                        <PrivateRoute
                          exact
                          path="/task"
                          component={() => <Task />}
                        />

                        <PrivateRoute
                          exact
                          path="/createbookingpopup"
                          component={() => <CreateBookingPopup />}
                        />

                        <PrivateRoute
                          exact
                          path="/guestpopup"
                          component={() => <GuestPopup />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/booking"
                          component={() => <Booking />}
                        />

                        <PrivateRoute
                          exact
                          path="/filter"
                          component={() => <BookingFilter />}
                        />

                        <PrivateRoute
                          exact
                          path="/deletepopup"
                          component={() => <DeletePopup />}
                        />

                        <PrivateRoute
                          exact
                          path="/addunittype"
                          component={() => <AddUnitType />}
                        />

                        <LoginRoute
                          exact
                          path="/forget"
                          component={() => <Forget />}
                        />
                        <LoginRoute
                          exact
                          path="/reset"
                          component={() => <Reset />}
                        />
                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/properties/:unitTypeId/services"
                          component={() => <Services />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/calendar"
                          component={() => <CalendarNew />}
                        />

                        <PrivateRoute
                          exact
                          path="/owner"
                          component={() => <Owner />}
                        />

                        {/* Additional Work */}

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/team"
                          component={() => <Team />}
                        />

                        <PrivateRoute
                          exact
                          path="/profile"
                          component={() => <Profile />}
                        />
                        <PrivateRoute
                          exact
                          path="/billing-information"
                          component={() => <BillingInformation />}
                        />
                        <PrivateRoute
                          exact
                          path="/contact-admin"
                          component={() => <ContactAdmin />}
                        />

                        <PrivateRoute
                          exact
                          path="/stats"
                          component={() => <Stats />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/invoice"
                          component={() => <Invoice />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/properties/:unitTypeId/rates"
                          component={() => <Rates />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/properties/:unitTypeId/season-rates"
                          component={() => <SeasonRates />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/properties/:unitTypeId/create-season-rates"
                          component={() => <CreateSeasonRates />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/guests"
                          component={() => <GuestList />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/companies"
                          component={() => <CompanyList />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/properties/:unitTypeId/overview"
                          component={() => <Overview />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/properties/:unitTypeId/location"
                          component={() => <Location />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations/:organizationId/properties/:unitTypeId/photos"
                          component={() => <Photos />}
                        />

                        <PrivateRoute
                          exact
                          path="/channel"
                          component={() => <Channel />}
                        />

                        <PrivateRoute
                          exact
                          path="/channelbooking"
                          component={() => <ChannelBooking />}
                        />

                        <PrivateRoute
                          exact
                          path="/channelexpedia"
                          component={() => <ChannelExpedia />}
                        />

                        <PrivateRoute
                          exact
                          path="/channelairbnb"
                          component={() => <ChannelAirbnb />}
                        />

                        <PrivateRoute
                          exact
                          path="/organizations"
                          component={() => <OrganizationList />}
                        />

                        <Route
                          exact
                          path="/notauthorize"
                          component={NotAuthorize}
                        />

                        <Route component={PageNotFound} />
                      </Switch>
                    </div>
                  </main>
                  <Footer />
                  <ToastContainer
                    containerId="B"
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </Router>
              </Suspense>
            </>
          </div>
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
