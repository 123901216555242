import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import airbnb from "../../assets/images/channelmanager/airbnb.png";
import booking from "../../assets/images/channelmanager/booking.png";
import expedia from "../../assets/images/channelmanager/expedia.png";
import loader from "../../assets/images/cliploader.gif";
import { channelInstance, userInstance } from "../../axios/axiosConfig";
import Meta from "../meta";
import UserLock from "../userlock/userlock";
import Wrapper from "../wrapper";
import "./channel.css";

const Channel = () => {
  const [subscribed, setSubscribed] = useState();
  const [onTrial, setOnTrial] = useState(true);
  const [daysLeft, setDaysLeft] = useState();
  const [loading, setLoading] = useState(true);
  const [airbnbActive, setAirbnb] = useState(false);
  const [bookingActive, setBooking] = useState(false);
  const [expediaActive, setExpedia] = useState(false);

  const userCred = JSON.parse(localStorage.getItem("subUserCred"));
  const [{ userId }] = userCred || [{}];

  useEffect(() => {
    const getData = async () => {
      const response = await channelInstance.get("/channelStatus");
      if (response.data.code === 200) {
        const { airbnb, booking, expedia } = response.data;
        setAirbnb(airbnb);
        setBooking(booking);
        setExpedia(expedia);
      }
      const res = await userInstance.post("/getUserSubscriptionStatus", {
        affiliateId: userId,
      });
      if (res.data.code === 200) {
        const [{ days, isOnTrial, isSubscribed }] = res.data.userSubsDetails;
        setDaysLeft(parseInt(days, 10));
        setSubscribed(JSON.parse(isSubscribed));
        setOnTrial(JSON.parse(isOnTrial));
        setLoading(false);
      }
    };
    getData();
  }, [userId]);

  if (loading) {
    return (
      // <Wrapper>
      <>
        <Meta>
          <body className="calendar-page-view" />
        </Meta>

        <div className="loader">
          <div className="loader-box">
            <img src={loader} alt="loader" />
          </div>
        </div>
      </>
      // </Wrapper>
    );
  }
  const hasAccess = onTrial && daysLeft !== 0 ? 1 : subscribed;
  if (!hasAccess) {
    return (
      <Wrapper>
        <UserLock />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Meta>
        <body className="channel-page-view" />
      </Meta>

      <div className="channel-manager">
        <div className="channel-manager-content">
          <div className="channel-manager-box">
            <Link to="/channelairbnb">
              <img src={airbnb} alt="Airbnb" />
            </Link>
            {airbnbActive ? (
              <p className="active">Active</p>
            ) : (
              <p className="disabled">Disabled</p>
            )}
          </div>

          <div className="channel-manager-box">
            <Link to="/channelbooking">
              <img src={booking} alt="Booking" />
            </Link>
            {bookingActive ? (
              <p className="active">Active</p>
            ) : (
              <p className="disabled">Disabled</p>
            )}
          </div>

          <div className="channel-manager-box">
            <Link to="/channelexpedia">
              <img src={expedia} alt="Expedia" />
            </Link>
            {expediaActive ? (
              <p className="active">Active</p>
            ) : (
              <p className="disabled">Disabled</p>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Channel;
