import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getUserPermissions } from "../api/organizations";
import { StoreKeys } from "../storeKeys";

export const useMemberPermissions = (organizationId) => {
  const [permissions, setPermissions] = useState({});

  const { isLoading } = useQuery({
    queryKey: [StoreKeys.GET_USER_PERMISSIONS, organizationId],
    queryFn: () => getUserPermissions(organizationId),
    onSuccess: (d) => {
      if (d && d.code === 200) {
        setPermissions(d.permissions);
      } else {
        setPermissions({});
      }
    },
    onError: (err) => {
      console.log(err);
      setPermissions({});
    },
  });

  const getPermissions = () => {
    return permissions;
  };

  return { getPermissions, isLoading };
};
