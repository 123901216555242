import {
  CloseCircleOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Col, Collapse, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import loader from "../../assets/images/cliploader.gif";
import payment from "../../assets/images/payment.png";
import { userInstance } from "../../axios/axiosConfig";
import config from "../../config/config.json";
import { STRIPE_APP_KEY } from "../../config/keys";
import Wrapper from "../wrapper";
import BillingHistory from "./BillingHistory";
import CheckoutForm from "./CheckoutForm";
import "./profile.css";
// import loader from '../../assets/images/loader.svg';
import Meta from "../meta";

const stripePromise = loadStripe(STRIPE_APP_KEY);
const { Panel } = Collapse;
const { basicPrice, advancePrice, discount } = config.development.Billing;
const BillingInformation = () => {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [unitPrice, setUnitPrice] = useState(basicPrice);
  const [unitsSelected, setUnitsSelected] = useState();
  const [planType, setPlanType] = useState("basic");
  const [subscriptionType, setSubscriptionType] = useState("month");
  // const [exchangeRate, setExchangeRate] = useState([]);
  const [currency, setCurrency] = useState("EUR");
  const [data, addData] = useState();
  const [invoiceList, setInvoiceList] = useState([]);
  const [end, setEnd] = useState("");
  const [currentCurrency, setCurrentCurrency] = useState("");
  const [hideBilling, setHideBilling] = useState(true);
  const [disablePlanType, setDisablePlanType] = useState(false);
  const [disableCurrency, setDisableCurrency] = useState({
    eur: false,
    chf: false,
    pln: false,
    gbp: false,
  });
  const [showCancelCheckout, setShowCancelCheckout] = useState(false);
  // const [canDowngrade, setCanDowngrade] = useState();
  const [disableBtn, setDisableBtn] = useState(false);
  const [onFreePlan, setOnFreePlan] = useState(false);
  const [freePlanEnd, setFreePlanEnd] = useState();
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(true);
  const [hidePlanInfo, setHidePlanInfo] = useState();

  const getUser = async () => {
    const response = await userInstance.get("/transactions");
    if (response.data.code === 200 && response.data.onFreePlan) {
      setLoading(false);
      setHideBilling(true);
      setOnFreePlan(true);
      const renewDate = moment(response.data.createdAt)
        .add(1, "year")
        .format("DD/MM/YYYY");
      setFreePlanEnd(renewDate);
    }
    const { code, transactions, endDate, status } = response.data;
    if (code === 200 && transactions != null) {
      setLoading(false);
      const [{ interval }] = transactions;
      if (interval === "month") {
        const renewDate = moment(endDate);
        setEnd(renewDate._d.toDateString());
      } else {
        const renewDate = moment(endDate);
        setEnd(renewDate._d.toDateString());
      }
      transactions.forEach((element) => {
        addData(element);
        setCurrentCurrency(element.currency);
      });
      if (status === "canceled") {
        setDisableBtn(true);
      }
    } else {
      setLoading(false);
      addData("");
      setHideBilling(false);
    }
  };

  // function for getting invoices
  const getInvoice = async () => {
    const response = await userInstance.post("/getBillingInvoice");
    if (response.data.code === 200) {
      setLoading(false);
      setInvoiceList(response.data.invoicesList);
    }
  };

  useEffect(() => {
    getUser();
    getInvoice();
  }, []);

  /* ------------------------------- All input handling functions here -------------------------- */

  const handlePlanSelect = (e) => {
    setPlanType(e);
    if (e === "advance") {
      setUnitPrice(advancePrice);
      if (total) {
        if (subscriptionType === "month") {
          setTotal(advancePrice * unitsSelected);
        } else {
          const amount =
            unitsSelected * advancePrice * 12 -
            (unitsSelected * advancePrice * 12 * discount) / 100;
          setTotal(amount);
        }
      }
    } else {
      setUnitPrice(basicPrice);
      if (total) {
        if (subscriptionType === "month") {
          setTotal(basicPrice * unitsSelected);
        } else {
          const amount =
            unitsSelected * basicPrice * 12 -
            (unitsSelected * basicPrice * 12 * discount) / 100;
          setTotal(amount);
        }
      }
    }
  };
  const handleUnitChange = (event) => {
    const e = event.target.value;
    setUnitsSelected(e);
    setTotal(e * unitPrice);
  };

  const handlePlanType = (e) => {
    setSubscriptionType(e);
    if (e === "month") {
      setTotal(unitsSelected * unitPrice);
    } else {
      const amount = unitsSelected * unitPrice * 12;
      setTotal(amount);
    }
  };

  const handleCurrencyChange = (e) => {
    if (e === "EUR") {
      setCurrency(e);
      if (planType === "basic" && subscriptionType === "month") {
        setUnitPrice(basicPrice);
        setTotal(basicPrice * unitsSelected);
      }
      if (planType === "basic" && subscriptionType === "year") {
        setUnitPrice(basicPrice);
        const amount =
          basicPrice * unitsSelected * 12 -
          (basicPrice * unitsSelected * 12 * discount) / 100;
        setTotal(amount);
      }
      if (planType === "advance" && subscriptionType === "month") {
        setUnitPrice(advancePrice);
        setTotal(advancePrice * unitsSelected);
      }
      if (planType === "advance" && subscriptionType === "year") {
        setUnitPrice(advancePrice);
        const amount =
          advancePrice * unitsSelected * 12 -
          (advancePrice * unitsSelected * 12 * discount) / 100;
        setTotal(amount);
      }
    }
  };

  const handleCouponCode = (e) => {
    setCoupon(e.target.value);
  };

  const checkCoupon = (rule, value) => {
    if (value !== "YEARLY20") {
      return Promise.reject(new Error("Invalid coupon code"));
    }
    return true;
  };

  // const disablebtn = () => {
  //   if (!unitsSelected && !subscriptionType && !currency && !planType) {
  //     setDisablePayNow(false);
  //     console.log('shgsgs');
  //   }
  // };
  /* ----------------------------- All payment handling functions here -------------------------- */

  const handleChangeSubscription = async () => {
    setHidePlanInfo(true);
    setHideBilling(false);
    if (data.interval === "year") setDisablePlanType(true);
    setShowCancelCheckout(true);
    setCurrency(data.currency);
    setUnitsSelected(data.units);
    if (data.currency === "EUR") {
      setDisableCurrency({
        ...disableCurrency,
        chf: true,
        pln: true,
        gbp: true,
      });
    }
    if (data.currency === "CHF") {
      setDisableCurrency({
        ...disableCurrency,
        eur: true,
        pln: true,
        gbp: true,
      });
    }
    if (data.currency === "GBP") {
      setDisableCurrency({
        ...disableCurrency,
        chf: true,
        pln: true,
        eur: true,
      });
    }
    if (data.currency === "PLN") {
      setDisableCurrency({
        ...disableCurrency,
        chf: true,
        eur: true,
        gbp: true,
      });
    }
  };

  const submitChangesubscription = async () => {
    if (!unitsSelected && !subscriptionType && !currency) {
      toast.error("Please select everything properly", { containerId: "B" });
    } else {
      const payload = {
        subscriptionId: data.subscriptionId,
        planId: data.planId,
        productId: data.productId,
        amount: total,
        interval: subscriptionType,
        noOfUnits: unitsSelected,
        currency,
        planType,
        coupon,
      };
      const res = await userInstance.post("/changeSubscription", payload);
      if (res.data.code === 200) {
        toast.success("subscription changed successfully", {
          containerId: "B",
        });
        getUser();
        getInvoice();
        setHideBilling(true);
      } else {
        toast.error("server error please try again", { containerId: "B" });
      }
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await userInstance.post("/cancelSubscription", {
        subscriptionId: data.subscriptionId,
      });
      if (response.data.code === 200) {
        getUser();
        toast.success("subscription cancelled", { containerId: "B" });
      }
    } catch (error) {
      toast.error("server error please try again", { containerId: "B" });
    }
  };

  // const ShowLoader = (
  //   <div className="loader">
  //     <div className="loader-box">
  //       <img src={loader} alt="loader" />
  //     </div>
  //   </div>
  // );

  if (loading) {
    return (
      <Wrapper>
        <div className="loader">
          <div className="loader-box">
            <img src={loader} alt="loader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Meta />

      {/* <Suspense fallback={<ShowLoader />}> */}
      <div className="billing-information">
        <div className="page-header">
          <h1>
            <UserOutlined />
            {t("billinginformation.heading1")}
          </h1>
        </div>

        <div className="billing-container">
          <Row gutter={[16, 0]}>
            {!onFreePlan ? (
              <Col span={12} hidden={hideBilling}>
                <Collapse defaultActiveKey={["1"]} accordion>
                  <Panel header={t("billinginformation.label23")} key="1">
                    <div className="billing-info-form">
                      <Row gutter={[16, 0]}>
                        <Col span={24}>
                          <div className="subscription-plan-list">
                            <div className="invoice-warning" hidden>
                              <WarningOutlined />
                              {t("billinginformation.label2")} 4.07.2020.
                            </div>
                            <div className="invoice-error" hidden>
                              <CloseCircleOutlined hidden />
                              {t("billinginformation.label3")}
                            </div>
                            <Form>
                              <Row gutter={[16, 0]}>
                                <Col span={6}>
                                  <Form.Item label="Subscription Type">
                                    <Select
                                      defaultValue="basic"
                                      placeholder="basic"
                                      onSelect={handlePlanSelect}
                                    >
                                      <Select.Option value="advance" hidden>
                                        {t("billinginformation.label4")}
                                      </Select.Option>
                                      <Select.Option value="basic">
                                        {t("billinginformation.label5")}
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>
                                </Col>

                                <Col span={5}>
                                  <Form.Item
                                    label={t("billinginformation.label7")}
                                    name="pricePerUnit"
                                  >
                                    <div className="amount-field">
                                      <p>{unitPrice}</p>
                                    </div>
                                  </Form.Item>
                                </Col>
                                <Col span={1}>
                                  <div className="into">X</div>
                                </Col>
                                <Col span={5}>
                                  <Form.Item
                                    label={t("billinginformation.label8")}
                                    rules={[
                                      {
                                        required: true,
                                        message: t("billinginformation.label9"),
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={t(
                                        "billinginformation.label8"
                                      )}
                                      onChange={handleUnitChange}
                                    />
                                    {/* <Select
                                        placeholder={t('billinginformation.label8')}
                                        onSelect={handleUnitSelect}
                                      >
                                        {unitDropDown.map((el) => (
                                          <Select.Option value={el} key={el}>
                                            {el}
                                          </Select.Option>
                                        ))}
                                      </Select> */}
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    label={t("billinginformation.label10")}
                                  >
                                    <Select
                                      defaultValue="Monthly"
                                      placeholder="Monthly"
                                      onSelect={handlePlanType}
                                    >
                                      <Select.Option
                                        value="month"
                                        disabled={disablePlanType}
                                      >
                                        {t("billinginformation.label11")}
                                      </Select.Option>
                                      <Select.Option value="year">
                                        {t("billinginformation.label12")}
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>
                                </Col>

                                <Col span={6}>
                                  <Form.Item
                                    name="currency"
                                    label={t("strings.currency")}
                                    rules={[
                                      {
                                        required: true,
                                        message: t(
                                          "billinginformation.label13"
                                        ),
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="EUR"
                                      onSelect={handleCurrencyChange}
                                    >
                                      <Select.Option
                                        value="EUR"
                                        disabled={disableCurrency.eur}
                                      >
                                        EUR
                                      </Select.Option>
                                      <Select.Option
                                        value="CHF"
                                        disabled={disableCurrency.chf}
                                        hidden
                                      >
                                        CHF
                                      </Select.Option>
                                      <Select.Option
                                        value="PLN"
                                        disabled={disableCurrency.pln}
                                        hidden
                                      >
                                        PLN
                                      </Select.Option>
                                      <Select.Option
                                        value="GBP"
                                        disabled={disableCurrency.gbp}
                                        hidden
                                      >
                                        GBP
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>
                                </Col>

                                <Col span={9} className="total-billing-price">
                                  <Form.Item
                                    label={t("billinginformation.label22")}
                                  >
                                    <h2>
                                      ={" "}
                                      {Math.round(
                                        (total + Number.EPSILON) * 100
                                      ) / 100 || 0}
                                      <span>
                                        {" "}
                                        {data
                                          ? data.currency
                                          : currency || ""}{" "}
                                        {t("billinginformation.label15")}{" "}
                                        {subscriptionType}
                                      </span>
                                    </h2>
                                    <p>{t("billinginformation.label14")}</p>
                                  </Form.Item>
                                </Col>
                                <Form.Item
                                  name="coupon"
                                  rules={[{ validator: checkCoupon }]}
                                  style={{ maxWidth: "50%", marginTop: "20px" }}
                                >
                                  <Input
                                    placeholder="Enter coupon code here"
                                    onChange={handleCouponCode}
                                  />
                                </Form.Item>
                              </Row>
                              <Row gutter={[16, 0]}>
                                {/* <Col span={24}>
                                    <div>
                                      <Elements stripe={stripePromise}>
                                        {showCancelCheckout ? (
                                          <CheckoutForm
                                            total={(total + Number.EPSILON) * 100}
                                            currency={currency}
                                            unitsSelected={unitsSelected}
                                            subscriptionType={subscriptionType}
                                            planType={planType}
                                            submitChange={submitChangesubscription}
                                            showCancelCheckout={showCancelCheckout}
                                            coupon={coupon}
                                          />
                                        ) : (
                                          <CheckoutForm
                                            total={(total + Number.EPSILON) * 100}
                                            currency={currency}
                                            unitsSelected={unitsSelected}
                                            subscriptionType={subscriptionType}
                                            planType={planType}
                                            hideBilling={setHideBilling}
                                            getData={getUser}
                                            getInvoice={getInvoice}
                                            coupon={coupon}
                                          />
                                        )}
                                      </Elements>
                                    </div>
                                  </Col> */}
                              </Row>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                </Collapse>
              </Col>
            ) : (
              ""
            )}
            {data ? (
              <Col span={12} hidden={hidePlanInfo}>
                <Collapse defaultActiveKey={["1"]} accordion>
                  <Panel
                    header={`${data.interval}ly Subscription Plan`}
                    key="1"
                  >
                    <div className="billing-info-form">
                      <Row gutter={[16, 0]}>
                        <Col span={14}>
                          <div className="subscription-plan-list">
                            <ul>
                              <li>
                                {t("billinginformation.label16")}{" "}
                                <span>
                                  {data.amount} {data.currency}/ {data.interval}
                                </span>
                              </li>
                              {/* <li>
                                  Bonus Credit
                                  {' '}
                                  <span>305 EUR</span>
                                </li> */}
                              <li>
                                {t("billinginformation.label10")}{" "}
                                <span>{data.planType}</span>
                              </li>
                              <li>
                                {t("billinginformation.label17")}{" "}
                                <span>
                                  {data.planType === "basic"
                                    ? ""
                                    : "23% off Pay-As-You-Go"}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="billing-action-buttons">
                            <Button
                              onClick={handleChangeSubscription}
                              disabled={disableBtn}
                            >
                              {t("billinginformation.label18")}
                            </Button>
                            <Button
                              onClick={handleCancelSubscription}
                              disabled={disableBtn}
                            >
                              {t("strings.cancel")}
                            </Button>
                          </div>
                          {/* {canDowngrade ? (
                            <Button
                              style={{ margin: '50px 30px 20px 10px' }}
                              onClick={handleChangeSubscription}
                              disabled={disableBtn}
                            >
                              Downgrade
                            </Button>
                          ) : (
                            <Tooltip
                              title="You are utilising all your
                               subscribed units,to downgrade you have to delete some units"
                              color="gold"
                            >
                              <Button
                                style={{ margin: '50px 30px 20px 10px' }}
                                disabled
                              >
                                downgrade
                              </Button>
                            </Tooltip>
                          )} */}
                        </Col>

                        <Col span={10}>
                          <div className="subscription-plan-list">
                            <p>
                              {t("billinginformation.label19")}{" "}
                              <span>
                                {data.interval}
                                {t("billinginformation.label20")}
                              </span>
                            </p>
                            <p>
                              {t("billinginformation.label21")} {end}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                </Collapse>
              </Col>
            ) : onFreePlan ? (
              <Col span={12}>
                <Collapse defaultActiveKey={["1"]} accordion>
                  <Panel header="Free yearly Subscription Plan" key="1">
                    <div className="billing-info-form">
                      <Row gutter={[16, 0]}>
                        <Col span={14}>
                          <div className="subscription-plan-list">
                            <li>
                              <span>Advance Plan</span>
                            </li>
                          </div>
                        </Col>

                        <Col span={10}>
                          <div className="subscription-plan-list">
                            <p>
                              {t("billinginformation.label24")}{" "}
                              <span>
                                Year
                                {t("billinginformation.label20")}
                              </span>
                            </p>
                            <p>
                              {t("billinginformation.label21")} {freePlanEnd}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                </Collapse>
              </Col>
            ) : (
              ""
            )}
            <Col span={12}>
              <Collapse defaultActiveKey={["4"]} accordion>
                <Panel header="Choose Your Payment Method" key="4">
                  <div className="main-info-form">
                    <h4>
                      Visa Credit/Debit Card
                      <img
                        src={payment}
                        alt="payment"
                        className="payment-method-icon"
                      />
                    </h4>
                    <p>
                      If choosen this method $0.20 fee will be automatically
                      added to your total. Tha fee is nott refundable and works
                      as a prevention against ladybugs.
                    </p>

                    {/* <Form>
                      <Row gutter={[16, 0]}>

                        <Col span={9}>
                          <Form.Item label="Card Number">
                            <Input placeholder="**** **** **** 7117" />
                          </Form.Item>
                        </Col>

                        <Col span={10}>
                          <Form.Item label="Expiry Date">
                            <Row>
                              <Col span={12} style={{ marginRight: 10 }}>
                                <Select>
                                  <Select.Option value="demo">10</Select.Option>
                                </Select>
                              </Col>
                              <Col span={10}>
                                <Select>
                                  <Select.Option value="demo">2019</Select.Option>
                                </Select>
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>

                        <Col span={5}>
                          <Form.Item label="CVC Code">
                            <Input placeholder="234" />
                          </Form.Item>
                        </Col>

                      </Row>
                    </Form> */}

                    <Col span={24}>
                      <div>
                        <Elements stripe={stripePromise}>
                          {showCancelCheckout ? (
                            <CheckoutForm
                              total={(total + Number.EPSILON) * 100}
                              currency={currency}
                              unitsSelected={unitsSelected}
                              subscriptionType={subscriptionType}
                              planType={planType}
                              submitChange={submitChangesubscription}
                              showCancelCheckout={showCancelCheckout}
                              coupon={coupon}
                            />
                          ) : (
                            <CheckoutForm
                              total={(total + Number.EPSILON) * 100}
                              currency={currency}
                              unitsSelected={unitsSelected}
                              subscriptionType={subscriptionType}
                              planType={planType}
                              hideBilling={setHideBilling}
                              getData={getUser}
                              getInvoice={getInvoice}
                              coupon={coupon}
                            />
                          )}
                        </Elements>
                      </div>
                    </Col>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </div>
      </div>
      {data ? (
        <BillingHistory
          invoiceList={invoiceList}
          data={data}
          currency={currentCurrency}
        />
      ) : (
        ""
      )}

      {/* </Suspense> */}
    </Wrapper>
  );
};

export default BillingInformation;
