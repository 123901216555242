import "./profile.css";

import { DownOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Select,
  Upload,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { userInstance } from "../../axios/axiosConfig";
import { server } from "../../config/keys";
import { useMutateAuth } from "../../hooks/useMutateAuth";
import { StoreKeys } from "../../storeKeys";
import { TIME_ZONE_LIST } from "../../utils/timeZone";
import Meta from "../meta";
import Wrapper from "../wrapper";

const { Panel } = Collapse;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const Profile = () => {
  const { t, i18n } = useTranslation();
  const userData = useSelector((state) => state.auth.user) || {};
  const queryClient = useQueryClient();

  const changeLanguage = useCallback(
    (event) => {
      i18n.changeLanguage(event);
    },
    [i18n]
  );

  const {
    updateBasicInfoMutation,
    updateTimezoneMutation,
    changePasswordMutation,
  } = useMutateAuth();

  const [basicInfoForm] = Form.useForm();
  const [applicationSetsForm] = Form.useForm();
  const [passwordSetsForm] = Form.useForm();
  const userId = localStorage.getItem("userId");
  const [img, setImg] = useState("");
  const [userName, setUserName] = useState("");

  const personalInfoFinish = async (values) => {
    const copyValues = values;
    copyValues.fullName = values.fullName && values.fullName.trim();
    copyValues.email = values.email && values.email.trim();
    copyValues.phone = values.phone && values.phone.trim();
    copyValues.address = values.address && values.address.trim();
    updateBasicInfoMutation.mutate(copyValues);

    if (!updateBasicInfoMutation.isError) {
      toast.success(
        t(
          "billingprofile.update_profile_success",
          "Profile Updated Successfully"
        ),
        {
          containerId: "B",
          toastId: "B",
        }
      );
    } else {
      toast.error(t("strings.server_error", "Server error please try again"), {
        containerId: "B",
        toastId: "A",
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const passwordFinish = async (values) => {
    changePasswordMutation.mutate(values);

    if (!changePasswordMutation.isError) {
      toast.success(
        t(
          "billingprofile.update_password_success",
          "Password changed successfully"
        ),
        {
          containerId: "B",
          toastId: "E",
        }
      );
    } else {
      toast.error(t("strings.server_error", "Server error please try again"), {
        containerId: "B",
        toastId: "F",
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    passwordSetsForm.resetFields();
  };

  const uploadAvatarProps = {
    name: "file",
    multiple: true,
    action: `${server}/users/photo?userId=${userId}`,
    onChange(info) {
      if (info.file.status === "done") {
        toast.success(`${info.file.name} ${t("billingprofile.label26")}`, {
          containerId: "B",
        });
        queryClient.invalidateQueries(StoreKeys.GET_ME);
      } else if (info.file.status === "error") {
        toast.error(`${info.file.name}  ${t("billingprofile.label27")}`, {
          containerId: "B",
        });
      }
    },
  };

  const applicationFinish = async (values) => {
    updateTimezoneMutation.mutate(values);

    if (!updateBasicInfoMutation.isError) {
      toast.success(
        t("billingprofile.timezone_success", "Time Zone Saved Successfully"),
        {
          containerId: "B",
          toastId: "F",
        }
      );
    } else {
      toast.error(t("strings.serer_error", "Server error please try again"), {
        containerId: "B",
        toastId: "G",
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (userData) {
      const split = new Date().toString().match(/([A-Z]+[+-][0-9]+.*)/)[1];
      basicInfoForm.setFieldsValue({
        fullName: userData.fullName,
        address: userData.address,
        email: userData.email,
        phone: userData.phone,
      });
      applicationSetsForm.setFieldsValue({
        timeZone: userData.timeZone || split,
      });
    }
  }, [userData]);

  const Delete = async () => {
    const response = await userInstance.post("/removeProfile");
    if (response.data.code === 200) {
      toast.success("Profile Removed Successfully", { containerId: "B" });
    } else {
      toast.error("server error please try again", { containerId: "B" });
    }
  };

  const english = useCallback(() => {
    changeLanguage("en");
  }, [changeLanguage]);

  const vietnamese = useCallback(() => {
    changeLanguage("vi");
  }, [changeLanguage]);

  const language = (
    <Menu>
      <Menu.Item key="1" onClick={english}>
        English
      </Menu.Item>
      <Menu.Item key="2" onClick={vietnamese}>
        Vietnamese
      </Menu.Item>
    </Menu>
  );

  return (
    <Wrapper showMenuSideNav={false} userName={userName} imgState={img}>
      <Meta />

      <div className="personal-information">
        <div className="page-header">
          <h1>
            <UserOutlined /> {t("billingprofile.heading1")}
          </h1>
        </div>
        <div className="profile-container">
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Collapse defaultActiveKey={["1"]} accordion>
                <Panel
                  header={t("billingprofile.detail_title", "Details")}
                  key="1"
                >
                  <div className="main-info-form">
                    <h4>{t("billingprofile.label1")}</h4>
                    <p>{t("billingprofile.label2")}</p>
                    <Form
                      form={basicInfoForm}
                      name="basic"
                      onFinish={personalInfoFinish}
                    >
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item>
                            <Form.Item
                              name="dragger"
                              valuePropName="fileList"
                              getValueFromEvent={normFile}
                              noStyle
                            >
                              <Upload.Dragger {...uploadAvatarProps}>
                                {userData ? (
                                  <div className="user-pic-success">
                                    <img src={userData.image} alt="" />
                                    <span>
                                      {t("billingprofile.label3")}
                                      {t("billingprofile.label4")}
                                    </span>
                                  </div>
                                ) : (
                                  <>
                                    <p className="ant-upload-drag-icon">
                                      <UserOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                      {t("billingprofile.label3")}
                                    </p>
                                    <p className="ant-upload-hint">
                                      {t("billingprofile.label4")}
                                    </p>
                                  </>
                                )}
                              </Upload.Dragger>
                            </Form.Item>
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label={t("billingprofile.label5")}
                            name="fullName"
                            rules={[
                              {
                                required: true,
                                message: t("billingprofile.label6"),
                              },
                            ]}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                          {userData.image && (
                            <Button type="primary" onClick={Delete}>
                              {t("billingprofile.remove_avatar")}
                            </Button>
                          )}
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label={t("strings.email")}
                            name="email"
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message: t("billingprofile.label24"),
                              },
                            ]}
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label={t("strings.address")}
                            name="address"
                          >
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item label={t("strings.phone")} name="phone">
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={updateBasicInfoMutation.isLoading}
                            >
                              {t("strings.save")}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Panel>
              </Collapse>

              <Collapse defaultActiveKey={["3"]} accordion>
                <Panel
                  header={t(
                    "billingprofile.password_change",
                    "Password Change"
                  )}
                  key="3"
                >
                  <div className="main-info-form">
                    <h4>{t("billingprofile.label12")}</h4>
                    <p>{t("billingprofile.label2")}</p>

                    <Form form={passwordSetsForm} onFinish={passwordFinish}>
                      <Row gutter={[16, 0]}>
                        <Col span={24}>
                          <Form.Item
                            name="oldPassword"
                            label={t("billingprofile.label13")}
                            rules={[
                              {
                                required: true,
                                message: t("billingprofile.label14"),
                              },
                            ]}
                          >
                            <Input.Password placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="newPassword"
                            label={t("billingprofile.label15")}
                            rules={[
                              {
                                required: true,
                                message: t("billingprofile.label16"),
                              },
                            ]}
                          >
                            <Input.Password placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            name="confirm"
                            label={t("billingprofile.label17")}
                            dependencies={["newPassword"]}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: t("billingprofile.label18"),
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (
                                    !value ||
                                    getFieldValue("newPassword") === value
                                  ) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(
                                    new Error(
                                      "Password and confirm password are not match!"
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password placeholder="" />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={changePasswordMutation.isLoading}
                            >
                              {t("billingprofile.label19")}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Panel>
              </Collapse>
            </Col>

            <Col span={12}>
              <Collapse defaultActiveKey={["2"]} accordion>
                <Panel
                  header={t(
                    "billingprofile.application_settings",
                    "Application Settings"
                  )}
                  key="2"
                >
                  <div className="main-info-form">
                    <h4>{t("billingprofile.label9")}</h4>
                    <p>{t("billingprofile.label2")}</p>
                    <Form
                      form={applicationSetsForm}
                      onFinish={applicationFinish}
                    >
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item
                            className="lang-box"
                            label={t("billingprofile.label10")}
                          >
                            <Dropdown
                              overlay={language}
                              overlayClassName="language-dropdown"
                            >
                              <Button>
                                {localStorage.getItem("i18nextLng") === "vi"
                                  ? "VI"
                                  : "EN"}{" "}
                                <DownOutlined />
                              </Button>
                            </Dropdown>
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label={t("billingprofile.label11")}
                            name="timeZone"
                          >
                            <Select showSearch optionFilterProp="children">
                              {TIME_ZONE_LIST.map((item, idx) => {
                                return (
                                  <Select.Option key={idx} value={item.value}>
                                    {item.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item>
                            <Button type="primary" htmlType="submit">
                              {t("strings.save")}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </div>
      </div>
    </Wrapper>
  );
};

export default Profile;
