import { useMutation } from "@tanstack/react-query";
import { Button, Form, Input } from "antd";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login, verifyHexString } from "../../api/users";
import logo from "../../assets/images/logo.png";
import Meta from "../meta";
import "./login.css";

const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation();

  const tokenParser = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  const verifyMutation = useMutation(
    (hexString) => verifyHexString(hexString),
    {
      onSuccess: (data) => {
        if (data.code === 200) {
          toast.success(data.msg, { containerId: "B" });
        } else {
          toast.error(data.msg, { containerId: "B" });
        }
      },
    }
  );

  const loginMutation = useMutation((values) => login(values), {
    onSuccess: (data) => {
      const { code, msg } = data;
      if (code === 200) {
        localStorage.setItem("token", data.token);
        const payload = tokenParser(data.token);
        localStorage.setItem("userId", payload.userId);
        history.push("/organizations");
        window.location.reload();
      } else {
        toast.error(msg, { containerId: "B" });
      }
    },
  });

  const onFinish = async (values) => {
    loginMutation.mutate(values);
    form.resetFields();
  };

  useEffect(() => {
    async function fetchData() {
      const values = queryString.parse(window.location.search);
      const forgetHex = values.token;
      if (forgetHex !== undefined) {
        verifyMutation.mutate(forgetHex);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Meta />

      <div className="login">
        <div className="login-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-logo">
                  <img src={logo} alt="Logo" />
                </div>
                <div className="login-form">
                  <h1>{t("login.title", "Sign In")}</h1>
                  <p>
                    {t(
                      "login.description",
                      "We're happy to have you here again!"
                    )}
                  </p>
                  <div className="login-box">
                    <Form
                      form={form}
                      name="login-form"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                    >
                      <Form.Item
                        label="E-mail"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "login.email_empty",
                              "Please enter your E-mail Address!"
                            ),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label={t("login.password_label", "Password")}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "login.password_empty",
                              "Please input your password!"
                            ),
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item>
                        <Button className="login-btn" htmlType="submit">
                          {t("login.login_btn", "Sign In")}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>

                <div className="q-links">
                  <p>
                    {t("login.not_exist_account", "Don't have an account yet?")}{" "}
                    <Link to="/register">
                      {t("login.register_btn", "Register now")}
                    </Link>
                  </p>

                  <p>
                    {t("login.forgot_desc", "Forget your password?")}{" "}
                    <Link to="/forget">
                      {t("login.forgot_btn", "Get a new password")}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
