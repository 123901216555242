import "./booking.css";

import {
  ClockCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  ThunderboltOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Col, Input, Modal, Pagination, Row, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getBookings } from "../../api/bookings";
import { getProperties } from "../../api/properties";
import filterIcon from "../../assets/images/menu/filter-icon.png";
import noBooking from "../../assets/images/no-booking.png";
import { useMemberPermissions, useOrganizationSubscription } from "../../hooks";
import {
  setIsCreatingBooking,
  setIsShowingFilter,
  setSelectedBookingId,
} from "../../slices/bookings";
import { StoreKeys } from "../../storeKeys";
import Loading from "../Loading";
import Meta from "../meta";
import NoProperty from "../no-property/NoProperty";
import DeletePopup from "../property/deletepopup";
import UserLock from "../userlock/userlock";
import UserNotAuthorize from "../userlock/UserNotAuthorize";
import Wrapper from "../wrapper";
import BookingDetail from "./bookingdetail";
import CreateBookingPopup from "./createbookingpopup";
import EditBookingPopup from "./editbookingpopup";

const Booking = () => {
  const { organizationId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedBookingId = useSelector(
    (state) => state.booking.selectedBookingId
  );

  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const { hasAccess } = useOrganizationSubscription(organizationId);

  const [visibleOFDelete, setVisibleOFDelete] = useState(false);

  // Handle search
  const [searchQuery, setQuerySearch] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [isFiltering, setIsFiltering] = useState(false);

  const { getPermissions } = useMemberPermissions(organizationId);
  const {
    bookingRead: canRead,
    bookingWrite: canWrite,
    bookingDelete: canDelete,
  } = getPermissions();

  const { data: propertiesData, loading: propertiesLoading } = useQuery(
    [StoreKeys.GET_PROPERTIES, organizationId],
    () => {
      try {
        return getProperties(organizationId, 1000, 0);
      } catch (err) {
        console.log(err);
      }
    }
  );

  const {
    data: bookingsData,
    isFetching: bookingsLoading,
    isPreviousData,
    error,
  } = useQuery({
    queryKey: [
      StoreKeys.GET_BOOKINGS,
      organizationId,
      limit,
      offset,
      searchQuery,
      filterValues,
    ],
    queryFn: () => {
      let params = {};
      if (searchQuery) {
        params = { ...params, q: searchQuery };
      }
      if (filterValues.status) {
        params = { ...params, status: filterValues.status };
      }

      if (filterValues.startDate) {
        params = { ...params, startDate: filterValues.startDate };
      }
      if (filterValues.endDate) {
        params = { ...params, endDate: filterValues.endDate };
      }

      return getBookings(organizationId, limit, offset, params);
    },
    keepPreviousData: true,
  });

  const onChangePage = (pageNumber, pageSize) => {
    setOffset((pageNumber - 1) * pageSize);
  };

  useEffect(() => {
    if (searchQuery || (filterValues && Object.keys(filterValues).length > 0)) {
      setIsFiltering(true);
    } else {
      setIsFiltering(false);
    }
  }, [filterValues, searchQuery]);

  const selectBooking = (values) => {
    dispatch(setSelectedBookingId(values.id));
  };

  const handleCancelDelete = () => {
    setVisibleOFDelete(false);
  };

  const enableCreateBookingButton = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => {
        dispatch(setIsCreatingBooking(true));
      }}
    >
      {t("booking.title1")}
    </Button>
  );
  const disableCreateBookingButton = (
    <Tooltip title={t("booking.tooltip")} color="gold">
      <Button disabled="true" type="primary" icon={<PlusOutlined />}>
        {t("booking.title1")}
      </Button>
    </Tooltip>
  );

  const createBookingButton =
    canWrite === 1 ? enableCreateBookingButton : disableCreateBookingButton;

  const isAccess = hasAccess();

  const remove = async () => {
    console.log("Delete booking");
  };

  if (propertiesLoading) {
    return <Loading />;
  }

  if (!isAccess) {
    return (
      <Wrapper organizationId={organizationId}>
        <UserLock />
      </Wrapper>
    );
  }

  if (canRead !== 1) {
    return <UserNotAuthorize />;
  }

  if (propertiesData && propertiesData.properties.length < 1) {
    return <NoProperty organizationId={organizationId} />;
  }

  if (bookingsData && bookingsData.bookings.length < 1 && !isFiltering) {
    return (
      <Wrapper organizationId={organizationId}>
        <Meta>
          <body className="booking-page-view" />
        </Meta>
        <div className="add-team-page">
          <div className="add-subuser">
            <img src={noBooking} alt="subuser" />
            <h4>{t("booking.heading3", "Booking")}</h4>
            <p>
              {t(
                "booking.nobooking",
                "Currently, you do not have any booking created"
              )}
            </p>
            {createBookingButton}
          </div>
        </div>
        <CreateBookingPopup organizationId={organizationId} />
      </Wrapper>
    );
  }

  return (
    <Wrapper organizationId={organizationId}>
      <Meta>
        <body className="booking-page-view" />
      </Meta>

      <div className="booking">
        <div className="container">
          <Row>
            <Col span={10}>
              <div className="booking-list-container">
                <div className="booking-list-header">
                  <div className="booking-filter-box">
                    <div className="filter-section">
                      <div className="search-box" role="presentation">
                        <Input
                          onChange={(e) => setQuerySearch(e.target.value)}
                          placeholder={t("header.placeholder1")}
                          allowClear
                          prefix={<SearchOutlined />}
                        />
                      </div>

                      <Tooltip title="Filter" color="gold">
                        <div className="filter-icon">
                          <Button
                            onClick={() => dispatch(setIsShowingFilter(true))}
                          >
                            {" "}
                            <img src={filterIcon} alt="filter-icon" />
                          </Button>
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="booking-create-button">
                    {createBookingButton}
                  </div>
                </div>
                <div className="booking-list-content">
                  {bookingsLoading && <Loading />}
                  {!bookingsLoading &&
                    bookingsData.bookings.map((el, i) => (
                      <div
                        key={el.id}
                        role="presentation"
                        className={`booking-list ${el.statusColor} ${
                          selectedBookingId === el.id
                            ? "booking-list-selected"
                            : ""
                        }`}
                      >
                        <div
                          className="detail"
                          onClick={() => selectBooking(el, i)}
                          role="presentation"
                        >
                          <h3>{el.guest}</h3>
                          <p>{el.propertyName}</p>
                          <p>{el.unitName}</p>
                          <ul>
                            <li>
                              <ClockCircleOutlined />{" "}
                              {`${moment(new Date(el.createdAt)).format(
                                "DD MMM, YYYY"
                              )} ${moment(new Date(el.createdAt)).format(
                                "HH:mm"
                              )}`}
                            </li>
                            <li>
                              {el.nights} <ThunderboltOutlined />
                            </li>
                            <li>
                              {el.noOfGuest} <UserOutlined />
                            </li>
                          </ul>
                        </div>
                        <div className="detail-info">
                          <span className="in-out-date">
                            {`${moment(new Date(el.startDate)).format(
                              "DD MMM,YYYY"
                            )} - ${moment(new Date(el.endDate)).format(
                              "DD MMM,YYYY"
                            )}`}
                          </span>
                          <span className="green-label">
                            {" "}
                            {el.currency}
                            {el.totalAmount}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="booking-paging-footer">
                  <Pagination
                    total={total}
                    defaultCurrent={1}
                    showSizeChanger={false}
                    defaultPageSize={20}
                    onChange={onChangePage}
                  />
                </div>
              </div>
            </Col>

            <Col span={14}>
              <BookingDetail organizationId={organizationId} />
            </Col>
          </Row>
        </div>
        <Modal
          open={visibleOFDelete}
          onOk={handleCancelDelete}
          onCancel={handleCancelDelete}
          wrapClassName="delete-modal"
        >
          <DeletePopup
            dataObject={() => remove()}
            cancel={() => handleCancelDelete()}
          />
        </Modal>
      </div>

      <EditBookingPopup />
      <CreateBookingPopup organizationId={organizationId} />

      {/* <BookingFilter
        visible={visibleFilter}
        handleCancel={handleCancel}
        handleOk={() => {}}
        setFilterValues={setFilterValues}
        getData={() => {}}
      /> */}
    </Wrapper>
  );
};

export default Booking;
