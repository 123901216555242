import ReactGA from "react-ga4";
import { GOOGLE_TRACKING_ID } from "./config/keys";

ReactGA.initialize(GOOGLE_TRACKING_ID, {
  debug: process.env.REACT_APP_ENV === "development",
});

ReactGA.send("pageview");

export default ReactGA;
