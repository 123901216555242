import "./booking.css";

import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import getSymbolCurrency from "currency-symbol-map";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMutateBooking } from "../../hooks/useMutateBooking";
import { useQueryBooking } from "../../hooks/useQueryBooking";
import { useQueryProperty } from "../../hooks/useQueryProperty";
import { setViewingBookingId } from "../../slices/bookings";
import { channelsList } from "../../utils/channelUtils";
import { toFixedNumber } from "../../utils/numberUtils";
import { randomId } from "../../utils/strUtils";
import Loading from "../Loading";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const i = 1;

const EditBookingPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const editBookingId = useSelector((state) => state.booking.viewingBookingId);
  const { useBooking } = useQueryBooking();
  const { useGetServices, useGetUnitType } = useQueryProperty();
  const { updateBookingMutation } = useMutateBooking();

  const { data, isFetching } = useBooking(editBookingId);
  const unitTypeId = data ? data.booking.unitTypeId : undefined;

  const { data: servicesData, isFetching: isServicesFetching } =
    useGetServices(unitTypeId);
  const { data: unitTypeData, isFetching: isUnitTypeDataFetching } =
    useGetUnitType(unitTypeId);

  const [form] = Form.useForm();

  // States
  const [channel, setChannel] = useState("");
  const [adult, setAdult] = useState(0);
  const [children1, setChildren1] = useState(0);
  const [children2, setChildren2] = useState(0);
  const [channelCommission, setChannelCommission] = useState(5);
  const [price, setPrice] = useState(0);
  const [night, setNight] = useState(0);
  const [amt, setAmt] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState("%");
  const [accommodation, setAccommodation] = useState(0);

  const [deposit, setDeposit] = useState(0);
  const [depositType, setDepositType] = useState("%");
  const [depositAmount, setDepositAmount] = useState(null);

  const [unitName, setUnitName] = useState("");
  const [discountAmount, setDiscountAmount] = useState(null);
  const [discountTotal, setDiscountTotal] = useState(0);

  const [editServicePanel, setEditServicePanel] = useState([]);
  const [deleteGuestId, setDeleteGuestId] = useState(null);
  const [deleteServiceId, setDeleteServiceId] = useState(null);
  const [selectDate, setSelectDate] = useState({});
  const [currency, setCurrency] = useState("$");
  const [currencyCode, setCurrencyCode] = useState("USD");
  const [unitId, setUnitId] = useState(null);
  const [propertyName, setPropertyName] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [guests, setGuests] = useState([]);
  const [services, setServices] = useState([]);

  const handleClosePanel = () => {
    dispatch(setViewingBookingId(null));
  };

  // Init form data
  useEffect(() => {
    if (data && data.booking) {
      const booking = data.booking;
      const m1 = moment(booking.startDate);
      const m2 = moment(booking.endDate);
      const discountTotal =
        booking.discountType === "%"
          ? (booking.night * booking.perNight * booking.discount) / 100
          : booking.discount;

      form.setFieldsValue({
        groupname: [m1, m2],
        property: booking.propertyName,
        unit: booking.unitName,
        adult: booking.adult,
        children1: booking.children1,
        children2: booking.children2,
        perNight: booking.perNight,
        nights: booking.night,
        discount: booking.discount,
        discountType: booking.discountType,
        discountTotal: discountTotal,
        deposit: booking.deposit,
        depositType: booking.depositType,
        notes1: booking.notes1,
        notes2: booking.notes2,
      });

      // handle guest form data
      const guestsData = booking.guests;
      if (guestsData && guestsData.length) {
        guestsData.forEach((el, i) => {
          form.setFieldsValue({
            [`fullName${i}`]: el.fullName,
            [`email${i}`]: el.email,
            [`country${i}`]: el.country,
            [`phone${i}`]: el.phone,
          });
        });
      }

      // handle services form data
      const servicesData = booking.services;
      if (servicesData && servicesData.length) {
        servicesData.forEach((el, i) => {
          form.setFieldsValue({
            [`serviceName${i}`]: el.serviceName,
            [`servicePrice${i}`]: el.servicePrice,
            [`serviceQuantity${i}`]: el.quantity,
            [`serviceTax${i}`]: el.serviceTax,
            [`serviceAmount${i}`]: el.serviceAmount,
          });
        });
      }

      // change state
      setPropertyName(booking.propertyName);
      setUnitName(booking.unitName);
      setUnitId(booking.unitId);
      setChannel(booking.channel);
      setChannelCommission(parseFloat(booking.commission || 0));
      setAdult(booking.adult);
      setChildren1(booking.children1);
      setChildren2(booking.children2);
      setPrice(parseFloat(booking.perNight || 0));
      setNight(parseFloat(booking.night || 0));
      setDiscount(parseFloat(booking.discount || 0));
      setDiscountType(booking.discountType);
      setDiscountAmount(parseFloat(booking.discount || 0));
      setDeposit(parseFloat(booking.deposit || 0));
      setDepositType(booking.depositType);
      setAmt(booking.nights * booking.perNight);
      setAccommodation(parseFloat(booking.accommodation || 0));
      setCurrency(booking.currency);
      setCurrencyCode(booking.currencyCode);
      setUnitId(booking.unitTypeId);
      setGuests(booking.guests);
      setServices(booking.services);
    }
  }, [data]);

  // calculate accommodation
  useEffect(() => {
    setAccommodation(price * night - discountTotal);
  }, [price, night, discountTotal]);

  // calculate discount total
  useEffect(() => {
    if (discountType === "%") {
      setDiscountTotal((discount * night * price) / 100);
    } else {
      setDiscountTotal(discount);
    }
  }, [discountType, discount, night, price]);

  // calculate deposit
  useEffect(() => {
    if (depositType === currency) {
      setDepositAmount(deposit);
    } else {
      setDepositAmount((totalAmount * deposit) / 100);
    }
  }, [deposit, depositType, totalAmount, currency]);

  // calculate total amount
  useEffect(() => {
    const servicesAmount = services.map((service) =>
      parseFloat(service.serviceAmount || 0)
    );
    const totalServiceAmount = servicesAmount.reduce((a, b) => a + b, 0);

    setTotalAmount(accommodation + totalServiceAmount);
  }, [accommodation, services]);

  const addMoreService = async () => {
    setServices([...services, { id: randomId() }]);
  };

  const onFinish = async (values) => {
    values.id = editBookingId;
    values.perNight = price;
    values.nights = night;
    values.amt = amt;
    values.discountType = discountType;
    values.discount = discount;
    values.accommodation = accommodation;
    values.totalAmount =
      accommodation +
      (services || [])
        .map((service) => service.serviceAmount)
        .reduce((a, b) => a + (b || 0), 0);

    const guestsData = (guests || []).map((el, i) => {
      const f = "fullName";
      const e = "email";
      const c = "country";
      const p = "phone";
      return {
        bookingId: editBookingId,
        fullName: values[f + i],
        email: values[e + i],
        country: values[c + i],
        phone: values[p + i],
        createdAt: el.createdAt,
        id: el.id,
      };
    });

    values.guestData = guestsData;
    if (guests.length > 0) {
      values.guest = guests[0].fullName;
    } else {
      values.guest = "No Guest";
    }

    values.serviceData = services.map((el, i) => {
      const n = "serviceName";
      const p = "servicePrice";
      const q = "serviceQuantity";
      const a = "serviceAmount";
      const t = "serviceTax";

      return {
        createdAt: el.createdAt,
        bookingId: editBookingId,
        serviceName: values[n + i],
        servicePrice: values[p + i],
        serviceQuantity: values[q + i],
        serviceAmount: values[a + i],
        serviceTax: values[t + i],
        id: el.id,
      };
    });
    values.noOfServices = services.length;
    values.noOfGuest = guests.length;
    values.propertyName = propertyName;
    values.channel = channel;
    values.commission = channelCommission;
    values.unitName = unitName;
    values.unit = unitId;
    values.deleteGuestId = deleteGuestId;
    values.deleteServiceId = deleteServiceId;
    values.currency = getSymbolCurrency(currencyCode);
    values.currencyCode = currencyCode;
    values.deposit = deposit;
    values.depositType = depositType;

    const response = await updateBookingMutation.mutateAsync(values);
    if (response.code === 200) {
      handleClosePanel();
      toast.success("booking changed successfully", { containerId: "B" });
      form.resetFields();
    } else {
      toast.error("some error occurred!", { containerId: "B" });
    }
  };

  const onSelectUnit = (event) => {
    const [data] = (unitTypeData ? unitTypeData.unitType.units : [])
      .filter((el) => el.unitName !== event)
      .map((el) => el.id);

    setUnitId(data);
    const unitNameRes = event.children || event;
    setUnitName(unitNameRes);
  };

  const onChangeChannel = (value, event) => {
    setChannel(event.children);
  };

  const handleCommissionChange = (e) => {
    setChannelCommission(e.target.value);
  };

  const onChangeDate = (value) => {
    if (value) {
      setSelectDate(value);
      const d1 = new Date(value[0]._d);
      const d2 = new Date(value[1]._d);
      const diff = Math.abs(d1 - d2);
      const day = Math.floor(diff / (24 * 60 * 60 * 1000)) + 1;
      setNight(day);
    }
  };

  const addMoreGuest = () => {
    setGuests([...guests, { id: randomId() }]);
  };

  const removePanel = (panel) => {
    const newGuests = guests.filter((el) => el.id !== panel.id);
    setDeleteGuestId(panel.id);
    setGuests(newGuests);
    newGuests.map((el, i) => {
      form.setFieldsValue({
        [`fullName${i}`]: el.fullName,
        [`email${i}`]: el.email,
        [`country${i}`]: el.country,
        [`phone${i}`]: el.phone,
      });
    });
    const lastIdx = newGuests.length;
    form.setFieldsValue({
      [`fullName${lastIdx}`]: "",
      [`email${lastIdx}`]: "",
      [`country${lastIdx}`]: "",
      [`phone${lastIdx}`]: "",
    });
  };

  const handleRemoveServicePanel = (ele) => {
    const newServices = services.filter((el) => el.id !== ele.id);
    setDeleteServiceId(ele.id);
    setServices(newServices);

    newServices.map((el, i) => {
      form.setFieldsValue({
        [`serviceName${i}`]: el.serviceName,
        [`servicePrice${i}`]: el.servicePrice,
        [`serviceQuantity${i}`]: el.quantity,
        [`serviceTax${i}`]: el.serviceTax,
        [`serviceAmount${i}`]: el.serviceAmount,
      });
    });

    const lastIdx = newServices.length;
    form.setFieldsValue({
      [`serviceName${lastIdx}`]: "",
      [`servicePrice${lastIdx}`]: "",
      [`serviceQuantity${lastIdx}`]: "",
      [`serviceTax${lastIdx}`]: "",
      [`serviceAmount${lastIdx}`]: "",
    });
  };

  const handleAdult = (e) => setAdult(e);
  const handleChildren1 = (e) => setChildren1(e);
  const handleChildren2 = (e) => setChildren2(e);

  /**
   * New service handler functions because why not
   */
  const handleServiceName = (value, el) => {
    (servicesData ? servicesData.services : [])
      .filter((el) => el.serviceName === value)
      .map((filterService) => setEditServicePanel(filterService));
  };

  const handleServicePrice = (value, el, i) => {
    const newServices = (services || []).map((ele, _idx) => {
      if (ele.id === el.id) {
        const amount =
          value * (ele.quantity || 0) +
          (value * (ele.quantity || 0) * (ele.serviceTax || 0)) / 100;
        return { ...ele, servicePrice: value, serviceAmount: amount };
      }
      return ele;
    });

    setServices(newServices);
    form.setFieldsValue({
      [`serviceAmount${i}`]:
        value * (el.quantity || 0) +
        (value * (el.quantity || 0) * (el.serviceTax || 0)) / 100,
    });
  };

  const handleServiceQuantity = (e, el, i) => {
    const newServices = (services || []).map((ele, _idx) => {
      if (ele.id === el.id) {
        const amount =
          ele.servicePrice * e.target.value +
          (ele.servicePrice * e.target.value * (ele.serviceTax || 0)) / 100;

        form.setFieldsValue({
          [`serviceAmount${i}`]: amount,
        });
        return { ...ele, quantity: e.target.value, serviceAmount: amount };
      }
      return ele;
    });

    setServices(newServices);
  };

  const handleServiceTax = (e, el, i) => {
    const newServices = (services || []).map((ele, _idx) => {
      if (ele.id === el.id) {
        const amount =
          ele.servicePrice * ele.quantity +
          (ele.servicePrice * ele.quantity * e.target.value) / 100;
        form.setFieldsValue({
          [`serviceAmount${i}`]: amount,
        });
        return { ...ele, serviceTax: e.target.value, serviceAmount: amount };
      }
      return ele;
    });

    setServices(newServices);
  };

  return (
    <Modal
      title={t("editbookingpopup.heading1")}
      name="modal2"
      open={editBookingId !== null}
      // onOk={handleOk}
      onCancel={handleClosePanel}
      wrapClassName="right-modal-container"
    >
      <Helmet>
        <body className="ant-scrolling-effect" />
      </Helmet>
      <div className="cross-btn">
        <CloseOutlined onClick={handleClosePanel} />
      </div>
      {isFetching && <Loading />}
      {!isFetching && (
        <Form form={form} name="basic" onFinish={onFinish} initialValues={{}}>
          <Row
            style={{ alignItems: "center", padding: "0px 20px", marginTop: 10 }}
          >
            <Col span={12}>
              <Form.Item
                label={t("editbookingpopup.heading16")}
                name="groupname"
                style={{ paddingRight: 20 }}
                onChange={onChangeDate}
                rules={[
                  {
                    required: true,
                    message: t("editbookingpopup.heading17"),
                  },
                ]}
              >
                <RangePicker onChange={onChangeDate} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Radio.Group name="radiogroup" defaultValue={1}>
                <Radio value={1}>{t("strings.confirmed")}</Radio>
                <Radio value={2}>{t("strings.option")}</Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ alignItems: "center", padding: "0px 20px" }}>
            <Col span={8}>
              <Form.Item
                label={t("strings.property")}
                name="property"
                style={{ paddingRight: 20 }}
                rules={[
                  {
                    required: true,
                    message: t("editbookingpopup.heading2"),
                  },
                ]}
              >
                <Input value={propertyName} disabled={true} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t("strings.unit")}
                name="unit"
                style={{ paddingRight: 20 }}
                rules={[
                  {
                    required: true,
                    message: t("editbookingpopup.heading3"),
                  },
                ]}
              >
                <Select
                  placeholder={t("strings.select")}
                  onSelect={(value) => onSelectUnit(value)}
                  value={unitName}
                  loading={isUnitTypeDataFetching}
                >
                  {(unitTypeData ? unitTypeData.unitType.units : []).map(
                    (el) => (
                      <Select.Option value={el.id} key={el.id}>
                        {el.unitName}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                className="comision"
                label={t("editbookingpopup.heading4")}
                name="channel"
              >
                <Select
                  placeholder={t("strings.select")}
                  onSelect={(value, event) => onChangeChannel(value, event)}
                  value={channel}
                  style={{ width: "70%", display: "inline-block" }}
                >
                  {channelsList.map((item, _id) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>

                <Input
                  name="commission"
                  style={{
                    width: "26%",
                    display: "inline-block",
                    verticalAlign: "top",
                    marginLeft: "4%",
                  }}
                  value={channelCommission}
                  onChange={(e) => handleCommissionChange(e)}
                  rules={[
                    {
                      required: true,
                      message: t("editbookingpopup.heading5"),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ alignItems: "center", padding: "0px 20px" }}>
            <Col span={8}>
              <Form.Item
                label={t("strings.adults")}
                name="adult"
                style={{ paddingRight: 20 }}
                rules={[
                  {
                    required: true,
                    message: t("editbookingpopup.heading6"),
                  },
                ]}
              >
                <Select
                  placeholder={t("strings.select")}
                  value={adult}
                  onSelect={(e) => handleAdult(e)}
                >
                  <Select.Option value="0">0</Select.Option>
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                  <Select.Option value="4">4</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t("editbookingpopup.heading7")}
                name="children1"
                style={{ paddingRight: 20 }}
              >
                <Select
                  placeholder={t("strings.select")}
                  value={children1}
                  onSelect={(e) => handleChildren1(e)}
                >
                  <Select.Option value="0">0</Select.Option>
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                  <Select.Option value="4">4</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t("editbookingpopup.heading8")}
                name="children2"
              >
                <Select
                  placeholder={t("strings.select")}
                  value={children2}
                  onSelect={(e) => handleChildren2(e)}
                >
                  <Select.Option value="0">0</Select.Option>
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                  <Select.Option value="4">4</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col span={24}>
              {/* <Form.Item style={{ marginBottom: "0" }}> */}
              <Collapse accordion defaultActiveKey={["1"]}>
                <Panel
                  icon={<PlusSquareOutlined />}
                  header={t("editbookingpopup.heading9")}
                  key="1"
                >
                  <div className="additional-guest">
                    {guests.map((el, j) => (
                      <div className="addi-box" key={el.id} data-key={j}>
                        <Row style={{ alignItems: "center" }}>
                          <Col span={6}>
                            <Form.Item
                              id={el.id}
                              label={t("strings.full")}
                              name={`fullName${j}`}
                              style={{ paddingRight: 20 }}
                            >
                              <Input defaultValue={el.fullName} />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              id={el.id}
                              label={t("strings.email")}
                              name={`email${j}`}
                              style={{ paddingRight: 20 }}
                            >
                              <Input type="email" />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              id={el.id}
                              label={t("strings.country")}
                              name={`country${j}`}
                              style={{ paddingRight: 20 }}
                            >
                              <Select showSearch>
                                {countryList()
                                  .getData()
                                  .map((ele) => (
                                    <Select.Option
                                      value={ele.label}
                                      key={ele.label}
                                    >
                                      {ele.label}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              label={t("strings.phone")}
                              name={`phone${j}`}
                              style={{ paddingRight: 20 }}
                            >
                              <Input
                                type="number"
                                minLength="9"
                                maxLength="15"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <div className="delete-data" data-key={j}>
                          <DeleteOutlined onClick={() => removePanel(el)} />
                        </div>
                      </div>
                    ))}

                    <Row>
                      <Col span={24}>
                        <div
                          role="presentation"
                          className="additional-add"
                          onClick={addMoreGuest}
                        >
                          <PlusOutlined /> {t("editbookingpopup.heading11")}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Panel>

                <Panel
                  icon={<PlusSquareOutlined />}
                  header={t("editbookingpopup.heading12")}
                  key="2"
                >
                  <div className="add-notes">
                    <Form.Item name="notes1">
                      <Input.TextArea />
                    </Form.Item>
                  </div>
                </Panel>

                <Panel
                  icon={<PlusSquareOutlined />}
                  header={t("editbookingpopup.heading13")}
                  key="3"
                  name="notes"
                >
                  <div className="add-notes">
                    <Form.Item name="notes2">
                      <Input.TextArea />
                    </Form.Item>
                  </div>
                </Panel>
              </Collapse>
              {/* </Form.Item> */}
            </Col>
          </Row>

          <div className="accommodation">
            <Row style={{ alignItems: "center", padding: "0px 20px" }}>
              <Col span={8}>
                <Form.Item>
                  <p>{t("editbookingpopup.heading15")}</p>
                </Form.Item>
              </Col>

              <Col span={16}>
                <Form.Item>
                  <div className="inline-form">
                    <label htmlFor="price">
                      <input hidden />
                      {t("editbookingpopup.heading14")}
                    </label>
                    <Form.Item name="perNight">
                      <Input
                        type="number"
                        placeholder="0,00"
                        value={price}
                        disabled={true}
                        rules={[
                          {
                            required: true,
                            message: t("editbookingpopup.heading6"),
                          },
                        ]}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </Form.Item>
                    <label htmlFor="x">
                      <input hidden />X
                    </label>
                    <Input
                      type="number"
                      placeholder={t("editbookingpopup.heading27")}
                      name="nights"
                      value={night}
                      disabled={true}
                      onChange={(e) => setNight(e.target.value)}
                    />
                    <label htmlFor="equal">
                      <input hidden />=
                    </label>
                    <Input
                      name="totalAAmount"
                      type="number"
                      value={night * price}
                      onBlur={(e) => {
                        setAmt(e.target.value);
                      }}
                    />
                    <label htmlFor="eur">
                      <input hidden />
                      {currency}
                    </label>
                  </div>

                  <div className="inline-form">
                    <label htmlFor="dis">
                      <input hidden />
                      {t("editbookingpopup.heading18")}
                    </label>
                    <Form.Item
                      name="discount"
                      rules={[
                        { min: 0.0, message: "Please enter a positive number" },
                      ]}
                    >
                      <Input
                        value={discount}
                        type="number"
                        placeholder="0,00"
                        onChange={(e) => {
                          setDiscount(e.target.value);
                        }}
                      />
                    </Form.Item>
                    <label htmlFor="dis">
                      <input hidden />X
                    </label>
                    <Form.Item name="discountType">
                      <Select
                        placeholder={t("editbookingpopup.heading28")}
                        onSelect={(value) => setDiscountType(value)}
                        defaultValue={discountType}
                      >
                        <Select.Option value={currency}>
                          {currency}
                        </Select.Option>
                        <Select.Option value="%">%</Select.Option>
                      </Select>
                    </Form.Item>
                    <label htmlFor="dis">
                      <input hidden />
                    </label>
                    <Form.Item>
                      <Input
                        type="number"
                        value={discountTotal}
                        // onBlur={(e) => setAccommodation(e.target.value)}
                      />
                    </Form.Item>

                    <label htmlFor="dis">
                      <input hidden />
                      {currency}
                    </label>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ alignItems: "center" }}>
              <Col span={24}>
                <div className="per-night">
                  <span>{t("editbookingpopup.heading20")}:</span>
                  <span className="amnt">
                    {toFixedNumber(accommodation)} {currency}
                  </span>
                </div>
              </Col>

              <Col span={24}>
                <div
                  role="presentation"
                  className="srvice-heading"
                  onClick={addMoreService}
                >
                  <PlusOutlined /> {t("editbookingpopup.heading21")}
                </div>
              </Col>

              <Col span={24}>
                <Form.Item style={{ marginBottom: "0" }}>
                  <Collapse
                    defaultActiveKey={["1"]}
                    className="service-panel"
                    accordion
                  >
                    <Panel
                      icon={<PlusSquareOutlined />}
                      header="Services"
                      key="1"
                    >
                      <div className="service-form">
                        {services
                          ? services.map((el, j) => (
                              <div className="inline-form" key={el.id}>
                                <div className="delete-data">
                                  <DeleteOutlined
                                    onClick={() => handleRemoveServicePanel(el)}
                                  />
                                </div>
                                <Col span={4}>
                                  <Form.Item name={`serviceName${j}`}>
                                    <Select
                                      style={{ width: "100px" }}
                                      placeholder="Select Service"
                                      onSelect={(value) =>
                                        handleServiceName(value, el, j)
                                      }
                                      value={form.getFieldValue(
                                        `serviceName${j}`
                                      )}
                                    >
                                      {(servicesData
                                        ? servicesData.services
                                        : []
                                      ).map((ele) => (
                                        <Select.Option
                                          key={ele.id}
                                          value={ele.serviceName}
                                        >
                                          {ele.serviceName}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Form.Item name={`servicePrice${j}`}>
                                    <Select
                                      placeholder="Rate"
                                      onSelect={(value) =>
                                        handleServicePrice(value, el, j)
                                      }
                                    >
                                      <Select.Option
                                        value={editServicePanel.servicePrice}
                                      >
                                        {editServicePanel.servicePrice}
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>
                                </Col>

                                <label htmlFor="dis">
                                  <input hidden />X
                                </label>
                                <Col span={4}>
                                  <Form.Item name={`serviceQuantity${j}`}>
                                    <Input
                                      type="number"
                                      placeholder="Quantity"
                                      onChange={(e) =>
                                        handleServiceQuantity(e, el, j)
                                      }
                                    />
                                  </Form.Item>
                                </Col>

                                <label htmlFor="dis">
                                  <input hidden />+
                                </label>
                                <Col span={4}>
                                  <Form.Item name={`serviceTax${j}`}>
                                    <Input
                                      type="number"
                                      placeholder="Tax (%)"
                                      value={el.serviceTax}
                                      onChange={(e) =>
                                        handleServiceTax(e, el, j)
                                      }
                                    />
                                  </Form.Item>
                                </Col>

                                <label htmlFor="dis">
                                  <input hidden />=
                                </label>

                                <Col span={4}>
                                  <Form.Item name={`serviceAmount${j}`}>
                                    <Input
                                      readOnly
                                      type="number"
                                      placeholder="Total"
                                      // onBlur={calculateTotal}
                                      value={el.serviceAmount}
                                    />
                                  </Form.Item>
                                  {/* <label htmlFor="eur">
                                {serviceAmount}
                              </label> */}
                                </Col>
                                <label htmlFor="eur">
                                  <input hidden />
                                  {currency}
                                </label>
                              </div>
                            ))
                          : null}
                      </div>
                    </Panel>
                  </Collapse>
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className="amnt-total">
                  <h4>
                    {toFixedNumber(totalAmount)} {currency}
                  </h4>
                </div>

                <div className="deposit">
                  <label htmlFor="deposit">
                    <input hidden />
                    {t("editbookingpopup.heading23")}
                  </label>

                  <div className="inline-form">
                    <label htmlFor="depo">
                      <input hidden />
                      {t("editbookingpopup.heading29")}
                    </label>

                    <Input
                      name="deposit"
                      type="number"
                      value={deposit}
                      placeholder="0,00"
                      onChange={(e) => {
                        setDeposit(e.target.value);
                      }}
                    />
                    <Form.Item name="depositType">
                      <Select
                        placeholder={t("editbookingpopup.heading24")}
                        onSelect={(value) => setDepositType(value)}
                        defaultValue={currency}
                      >
                        <Select.Option value={currency}>
                          {currency}
                        </Select.Option>
                        <Select.Option value="%">%</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <div className="outstanding">
                  <label htmlFor="acco">
                    {t("editbookingpopup.heading29")}:{" "}
                    <span>
                      {depositType === "%"
                        ? `${toFixedNumber(
                            depositAmount
                          )}${currency} (${deposit}%)`
                        : `${deposit}${currency}`}
                    </span>
                  </label>
                  <label htmlFor="amou">
                    {t("editbookingpopup.heading25")}:{" "}
                    <span>
                      {toFixedNumber(totalAmount - depositAmount)}
                      {currency}
                    </span>
                  </label>
                </div>
              </Col>
            </Row>
          </div>

          <Row
            style={{
              alignItems: "center",
              background: "#fbfbfc",
              padding: "0px 20px",
              paddingTop: "20px",
            }}
          >
            <Col span={24}>
              <Form.Item style={{ textAlign: "right" }}>
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    handleClosePanel();
                    setDiscount(0);
                  }}
                >
                  {t("strings.cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("strings.save")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};
export default EditBookingPopup;
