import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const bookingsAdapter = createEntityAdapter({
  selectId: (booking) => booking.id,
});

const initialState = {
  viewingBookingId: null,
  selectedBookingId: null,
  isCreatingBooking: false,
  isShowingFilter: false,
  filterValues: {},
};

export const bookingsSlice = createSlice({
  name: "booking",
  initialState: bookingsAdapter.getInitialState({ ...initialState }),
  reducers: {
    setFilterValues: (state, action) => {
      state.filterValues = action.payload;
    },
    setIsShowingFilter: (state, action) => {
      state.isShowingFilter = action.payload;
    },
    setViewingBookingId: (state, action) => {
      state.viewingBookingId = action.payload;
    },
    setIsCreatingBooking: (state, action) => {
      state.isCreatingBooking = action.payload;
    },
    setSelectedBookingId: (state, action) => {
      state.selectedBookingId = action.payload;
    },
    bookingsReceived(state, action) {
      bookingsAdapter.addMany(state, action.payload.bookings);
    },
  },
});

export const {
  setViewingBookingId,
  bookingsReceived,
  setIsCreatingBooking,
  setSelectedBookingId,
  setIsShowingFilter,
} = bookingsSlice.actions;

export default bookingsSlice.reducer;
